exports = module.exports = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    cong_dist_max: 14,
    st_up_hous_max: 53,
    st_lo_hous_max: 151,
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    cong_dist_max: 1,
    st_up_hous_max: 20,
    st_lo_hous_max: 40,
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    cong_dist_max: 9,
    st_up_hous_max: 30,
    st_lo_hous_max: 30,
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    cong_dist_max: 8,
    st_up_hous_max: 35,
    st_lo_hous_max: 159,
  },
  {
    name: 'California',
    abbreviation: 'CA',
    cong_dist_max: 53,
    st_up_hous_max: 40,
    st_lo_hous_max: 80,
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    cong_dist_max: 7,
    st_up_hous_max: 35,
    st_lo_hous_max: 67,
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    cong_dist_max: 18,
    st_up_hous_max: 40,
    st_lo_hous_max: 151,
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    cong_dist_max: 6,
    st_up_hous_max: 38,
    st_lo_hous_max: 158,
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
    cong_dist_max: 10,
    st_up_hous_max: 33,
    st_lo_hous_max: 296,
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    cong_dist_max: 27,
    st_up_hous_max: 40,
    st_lo_hous_max: 175,
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    cong_dist_max: 14,
    st_up_hous_max: 56,
    st_lo_hous_max: 180,
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    cong_dist_max: 2,
    st_up_hous_max: 25,
    st_lo_hous_max: 51,
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    cong_dist_max: 5,
    st_up_hous_max: 35,
    st_lo_hous_max: 35,
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    cong_dist_max: 18,
    st_up_hous_max: 59,
    st_lo_hous_max: 118,
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    cong_dist_max: 15,
    st_up_hous_max: 51,
    st_lo_hous_max: 102,
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    cong_dist_max: 6,
    st_up_hous_max: 50,
    st_lo_hous_max: 100,
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    cong_dist_max: 7,
    st_up_hous_max: 40,
    st_lo_hous_max: 160,
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    cong_dist_max: 9,
    st_up_hous_max: 38,
    st_lo_hous_max: 100,
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    cong_dist_max: 6,
    st_up_hous_max: 39,
    st_lo_hous_max: 105,
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    cong_dist_max: 2,
    st_up_hous_max: 35,
    st_lo_hous_max: 151,
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    cong_dist_max: 13,
    st_up_hous_max: 47,
    st_lo_hous_max: 100,
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    cong_dist_max: 9,
    st_up_hous_max: 40,
    st_lo_hous_max: 160,
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    cong_dist_max: 14,
    st_up_hous_max: 38,
    st_lo_hous_max: 110,
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    cong_dist_max: 8,
    st_up_hous_max: 67,
    st_lo_hous_max: 134,
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    cong_dist_max: 7,
    st_up_hous_max: 52,
    st_lo_hous_max: 122,
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    cong_dist_max: 13,
    st_up_hous_max: 58,
    st_lo_hous_max: 163,
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    cong_dist_max: 1,
    st_up_hous_max: 50,
    st_lo_hous_max: 100,
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    cong_dist_max: 4,
    st_up_hous_max: 49,
    st_lo_hous_max: null,
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    cong_dist_max: 4,
    st_up_hous_max: 21,
    st_lo_hous_max: 42,
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    cong_dist_max: 2,
    st_up_hous_max: 35,
    st_lo_hous_max: 161,
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    cong_dist_max: 12,
    st_up_hous_max: 40,
    st_lo_hous_max: 189,
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    cong_dist_max: 16,
    st_up_hous_max: 42,
    st_lo_hous_max: 78,
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    cong_dist_max: 27,
    st_up_hous_max: 63,
    st_lo_hous_max: 150,
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    cong_dist_max: 13,
    st_up_hous_max: 50,
    st_lo_hous_max: 120,
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    cong_dist_max: 7,
    st_up_hous_max: 47,
    st_lo_hous_max: 47,
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    cong_dist_max: 16,
    st_up_hous_max: 33,
    st_lo_hous_max: 99,
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    cong_dist_max: 7,
    st_up_hous_max: 48,
    st_lo_hous_max: 160,
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    cong_dist_max: 5,
    st_up_hous_max: 30,
    st_lo_hous_max: 60,
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    cong_dist_max: 23,
    st_up_hous_max: 58,
    st_lo_hous_max: 203,
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    cong_dist_max: 9,
    st_up_hous_max: 40,
    st_lo_hous_max: 150,
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    cong_dist_max: 9,
    st_up_hous_max: 46,
    st_lo_hous_max: 124,
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    cong_dist_max: 7,
    st_up_hous_max: 39,
    st_lo_hous_max: 43,
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    cong_dist_max: 14,
    st_up_hous_max: 53,
    st_lo_hous_max: 118,
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    cong_dist_max: 36,
    st_up_hous_max: 38,
    st_lo_hous_max: 150,
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    cong_dist_max: 4,
    st_up_hous_max: 29,
    st_lo_hous_max: 75,
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    cong_dist_max: 1,
    st_up_hous_max: 13,
    st_lo_hous_max: 104,
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    cong_dist_max: 11,
    st_up_hous_max: 45,
    st_lo_hous_max: 100,
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    cong_dist_max: 10,
    st_up_hous_max: 49,
    st_lo_hous_max: 59,
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    cong_dist_max: 14,
    st_up_hous_max: 46,
    st_lo_hous_max: 96,
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    cong_dist_max: 8,
    st_up_hous_max: 33,
    st_lo_hous_max: 99,
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    cong_dist_max: 4,
    st_up_hous_max: 47,
    st_lo_hous_max: 63,
  },
];
