<template>
  <div>
    <div v-if="geographicSelectedCount">
      <h3>Geographic</h3>
      <div v-if="selections.geographic.states.length">
        <h4>States</h4>
        <el-tag
          v-for="(state, index) of selections.geographic.states"
          :key="'state-' + index"
        >
          {{ state | label('states', 'abbreviation', 'name') }}
        </el-tag>
      </div>
      <div v-if="selections.geographic.msa.length">
        <h4>MSA</h4>
        <el-tag
          v-for="(msa, index) of selections.geographic.msa"
          :key="'msa-' + index"
        >
          {{ msa | label('msa') }}
        </el-tag>
      </div>
      <div v-if="selections.geographic.postal_routes && selections.geographic.postal_routes.length">
        <h4>Postal Carrier Routes</h4>
        <el-tag
          v-for="(pcr, index) of selections.geographic.postal_routes"
          :key="'pcr-' + index"
        >
          {{ pcr }}
        </el-tag>
      </div>
      <div v-if="selections.geographic.zipcodes.length">
        <h4>Zipcodes</h4>
        <el-tag
          v-for="(zip, index) of selections.geographic.zipcodes"
          :key="'zip-' + index"
        >
          {{ zip }}
        </el-tag>
      </div>
    </div>
    <div v-if="selections.demographic.length">
      <h3>Demographic</h3>
      <el-tag
        v-for="(demographic, index) of selections.demographic"
        :key="'demographic-' + index"
      >
        {{ demographic | label('demographicSegments') }}
      </el-tag>
    </div>
    <div v-if="selections.interest.length">
      <h3>Interests</h3>
      <el-tag
        v-for="(interest, index) of selections.interest"
        :key="'interest-' + index"
      >
        {{ interest | label('interests') }}
      </el-tag>
    </div>
    <div v-if="selections.financial.length">
      <h3>Financial</h3>
      <el-tag
        v-for="(financial, index) of selections.financial"
        :key="'financial-' + index"
      >
        {{ financial | label('financials', 'value', 'alt') }}
      </el-tag>
    </div>
    <div v-if="selections.ethnicities && selections.ethnicities.length">
      <h3>Ethnicity</h3>
      <el-tag
        v-for="(ethnicity, index) of selections.ethnicities"
        :key="'ethnicity-' + index"
      >
        {{ ethnicity | label('ethnicities') }}
      </el-tag>
    </div>
    <div v-if="selections.charity.length">
      <h3>Charity</h3>
      <el-tag
        v-for="(charity, index) of selections.charity"
        :key="'charity-' + index"
      >
        {{ charity | label('charities') }}
      </el-tag>
    </div>
    <div v-if="selections.political && selections.political.length">
      <h3>Political</h3>
      <el-tag
        v-for="(political, index) of selections.political"
        :key="'political-' + index"
      >
        {{ political | label('political', 'value', 'alt') }} ( {{ politicalRanges[political][0] }} - {{ politicalRanges[political][1] }} {{ political | label('political', 'value', 'sign') }} )
      </el-tag>
    </div>
    <div v-if="selections.politicalDistrict && selections.politicalDistrict.length">
      <h3>Political Districts</h3>
      <el-tag
        v-for="(district, index) of selections.politicalDistrict"
        :key="'political-district-' + index"
      >
        {{ district | districtLabel }}
      </el-tag>
    </div>
    <div v-if="selections.purchaseIntenders && selections.purchaseIntenders.length">
      <h3>Purchase Intenders</h3>
      <el-tag
        v-for="(purchaseIntender, index) of selections.purchaseIntenders"
        :key="'purchaseIntender-' + index"
      >
        {{ purchaseIntender | label('purchaseIntenders') }}
      </el-tag>
    </div>
    <div v-if="entertainmentSelectedCount">
      <h3>Entertainment</h3>
      <div v-if="selections?.entertainment.music?.artists.length || selections?.entertainment.music?.genres.length">
        <h4>Music</h4>
        <el-tag
          v-for="(artist, index) of selections.entertainment.music?.artists"
          :key="'musicArtist-' + index"
        >
          Artist: {{ artist | label('entertainment.music.artists') }}
        </el-tag>
        <el-tag
          v-for="(genre, index) of selections.entertainment.music?.genres"
          :key="'musicGenre-' + index"
        >
          Genre: {{ genre | label('entertainment.music.genres') }}
        </el-tag>
      </div>
    </div>
  </div>
</template>

<script>
import selectors from './data-mapping';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
export default {
  filters: {
    districtLabel(val) {
      const label = [];
      if (val.cong_dist) {
        label.push(`Congressional: ${val.cong_dist}`);
      }
      if (val.st_lo_hous) {
        label.push(`State Assembly: ${val.st_lo_hous}`);
      }
      if (val.st_up_hous) {
        label.push(`State Senate: ${val.st_up_hous}`);
      }

      return `${selectors.states.find(s => s.abbreviation === val.state).name} ` + label.join(' & ');
    },
    label(val, path, key, field) {
      key = key || 'value';
      field = field || 'label';
      const data = _get(selectors, path);
      let result = [];
      if (data[0] && data[0][key]) {
        result = data.filter(item => item[key] === val);
      } else {
        result = _flatten(data.map(item => item.children)).filter(item => item[key] === val);
      }
      if (result[0]) {
        return result[0][field];
      }
    },
  },
  props: {
    selections: {
      type: Object,
      default: () => {
        return {
          charity: [],
          demographic: [],
          ethnicities: [],
          financial: [],
          geographic: {
            states: [],
            msa: [],
            zipcodes: [],
          },
          interest: [],
          political: [],
          purchaseIntenders: [],
          entertainment: [],
        };
      },
    },
    politicalRanges: {
      type: Object,
      default: () => {
        return {
          mod_prty_aff_con_prb_mb: [0, 0],
          mod_prty_aff_dem_prb_mb: [0, 0],
          mod_prty_aff_grn_prb_mb: [0, 0],
          mod_prty_aff_ind_prb_mb: [0, 0],
          mod_prty_aff_lbt_prb_mb: [0, 0],
          mod_prty_aff_lib_prb_mb: [0, 0],
          mod_prty_aff_rep_prb_mb: [0, 0],
          mod_state_don_prb_mb: [0, 100],
          mod_state_don_amt_pct_mb: [0, 100],
          mod_state_don_amt_pred_mb: [0, 10000],
          mod_fed_don_prb_mb: [0, 100],
          mod_fed_don_amt_pct_mb: [0, 100],
          mod_fed_don_amt_pred_mb: [0, 35500],
          mod_to_mtpri_prb_mb: [0, 100],
          mod_to_mtgen_prb_mb: [0, 100],
          mod_to_ppri_prb_mb: [0, 100],
          mod_to_pgen_prb_mb: [0, 100],
        };
      },
    },
  },

  computed: {
    geographicSelectedCount() {
      return this.selections.geographic.states.length +
             this.selections.geographic.msa.length +
             this.selections.geographic.zipcodes.length +
             (this.selections.geographic.postal_routes ? this.selections.geographic.postal_routes.length : 0);
    },
    entertainmentSelectedCount() {
      if (!this.selections.entertainment) {
        return 0;
      }
      return (
        (this.selections.entertainment.music?.genres.length ?? 0) +
        (this.selections.entertainment.music?.artists.length ?? 0)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 14px;
  text-decoration: underline;
  margin: 0 0 5px;
}

h4 {
  font-size: 11px;
  text-decoration: underline;
  margin: 0 0 5px;
}

.el-tag {
  font-size: 11px;
  padding: 3px;
  line-height: 12px;
  height: 20px;
  margin: 0 5px 5px 0;
}
</style>
