exports = module.exports = {
  music: {
    genres: [
      { value: '1950s', label: '1950s' },
      { value: '1960s', label: '1960s' },
      { value: '1970s', label: '1970s' },
      { value: '1980s', label: '1980s' },
      { value: '1990s', label: '1990s' },
      { value: '2000s', label: '2000s' },
      { value: '2010s', label: '2010s' },
      { value: '2020s', label: '2020s' },
      { value: 'a cappella', label: 'A Cappella' },
      { value: 'acoustic', label: 'Acoustic' },
      { value: 'afrobeats', label: 'Afrobeats' },
      { value: 'alternative', label: 'Alternative' },
      { value: 'alternative country', label: 'Alternative Country' },
      { value: 'alternative dance', label: 'Alternative Dance' },
      { value: 'alternative folk', label: 'Alternative Folk' },
      { value: 'alternative hardcore', label: 'Alternative Hardcore' },
      { value: 'alternative hip hop', label: 'Alternative Hip Hop' },
      { value: 'alternative metal', label: 'Alternative Metal' },
      { value: 'alternative r&b', label: 'Alternative R&B' },
      { value: 'alternative rap', label: 'Alternative Rap' },
      { value: 'ambient', label: 'Ambient' },
      { value: 'american', label: 'American' },
      { value: 'big band', label: 'Big Band' },
      { value: 'bluegrass', label: 'Bluegrass' },
      { value: 'blues', label: 'Blues' },
      { value: 'christian', label: 'Christian' },
      { value: 'christmas', label: 'Christmas' },
      { value: 'classic metal', label: 'Classic Metal' },
      { value: 'classic punk', label: 'Classic Punk' },
      { value: 'classic rock', label: 'Classic Rock' },
      { value: 'classical', label: 'Classical' },
      { value: 'college rock', label: 'College Rock' },
      { value: 'comedy', label: 'Comedy' },
      { value: 'contemporary', label: 'Contemporary' },
      { value: 'country', label: 'Country' },
      { value: 'dance', label: 'Dance' },
      { value: 'disco', label: 'Disco' },
      { value: 'easy listening', label: 'Easy Listening' },
      { value: 'electronica', label: 'Electronica' },
      { value: 'female', label: 'Female' },
      { value: 'film', label: 'Film' },
      { value: 'folk', label: 'Folk' },
      { value: 'funk', label: 'Funk' },
      { value: 'gospel', label: 'Gospel' },
      { value: 'grunge', label: 'Grunge' },
      { value: 'guitar', label: 'Guitar' },
      { value: 'hard rock', label: 'Hard Rock' },
      { value: 'hip-hop', label: 'Hip-Hop' },
      { value: 'house', label: 'House' },
      { value: 'indie', label: 'Indie' },
      { value: 'instrumental', label: 'Instrumental' },
      { value: 'international', label: 'International' },
      { value: 'jazz', label: 'Jazz' },
      { value: 'k-pop', label: 'K-Pop' },
      { value: 'latin', label: 'Latin' },
      { value: 'male', label: 'Male' },
      { value: 'metal', label: 'Metal' },
      { value: 'miscellaneous', label: 'Miscellaneous' },
      { value: 'musical', label: 'Musical' },
      { value: 'opera', label: 'Opera' },
      { value: 'orchestra', label: 'Orchestra' },
      { value: 'pop', label: 'Pop' },
      { value: 'progressive', label: 'Progressive' },
      { value: 'psychedelic', label: 'Psychedelic' },
      { value: 'punk', label: 'Punk' },
      { value: 'rap', label: 'Rap' },
      { value: 'reggae', label: 'Reggae' },
      { value: 'rhythm & blues', label: 'Rhythm & Blues' },
      { value: 'rock', label: 'Rock' },
      { value: 'soft rock', label: 'Soft Rock' },
      { value: 'songwriter', label: 'Songwriter' },
      { value: 'soul', label: 'Soul' },
      { value: 'southern hip hop', label: 'Southern Hip Hop' },
      { value: 'southern rap', label: 'Southern Rap' },
      { value: 'southern rock', label: 'Southern Rock' },
      { value: 'southern soul', label: 'Southern Soul' },
      { value: 'swing', label: 'Swing' },
      { value: 'techno', label: 'Techno' },
      { value: 'vocalists', label: 'Vocalists' },
    ],
    artists: [
      { label: '(G)I-Dle', value: '(G)I-Dle' },
      { label: '$Uicideboy$', value: '$Uicideboy$' },
      { label: '070 Shake', value: '070 Shake' },
      { label: '187 Strassenbande', value: '187 Strassenbande' },
      { label: '2 Chainz', value: '2 Chainz' },
      { label: '2 Live Crew', value: '2 Live Crew' },
      { label: '2 Pac', value: '2 Pac' },
      { label: '21 Savage', value: '21 Savage' },
      { label: '24Kgoldn', value: '24Kgoldn' },
      { label: '2Pac', value: '2Pac' },
      { label: '3 Doors Down', value: '3 Doors Down' },
      { label: '3Lw', value: '3Lw' },
      { label: '3Oh!3', value: '3Oh!3' },
      { label: '3Rd Bass', value: '3Rd Bass' },
      { label: '4 Non Blondes', value: '4 Non Blondes' },
      { label: '42 Dugg', value: '42 Dugg' },
      { label: '5 Seconds Of Summer', value: '5 Seconds Of Summer' },
      { label: '50 Cent', value: '50 Cent' },
      { label: '69 Boyz', value: '69 Boyz' },
      { label: '6Ix9Ine', value: '6Ix9Ine' },
      { label: '8 Ball & Mjg', value: '8 Ball & Mjg' },
      { label: '98 Degrees', value: '98 Degrees' },
      { label: 'A Boogie Wit Da Hoodie', value: 'A Boogie Wit Da Hoodie' },
      { label: 'A Day To Remember', value: 'A Day To Remember' },
      { label: 'A Great Big World', value: 'A Great Big World' },
      { label: 'A Perfect Circle', value: 'A Perfect Circle' },
      { label: 'A Taste Of Honey', value: 'A Taste Of Honey' },
      { label: 'A Touch Of Class', value: 'A Touch Of Class' },
      { label: 'A Tribe Called Quest', value: 'A Tribe Called Quest' },
      { label: 'A-Ha', value: 'A-Ha' },
      { label: 'A-Trak', value: 'A-Trak' },
      {
        label: 'A.B. Y Los Kumbia Kings Quintanilla Iii',
        value: 'A.B. Y Los Kumbia Kings Quintanilla Iii',
      },
      { label: 'A.R. Rahman', value: 'A.R. Rahman' },
      { label: 'A$Ap Ferg', value: 'A$Ap Ferg' },
      { label: 'A$Ap Mob', value: 'A$Ap Mob' },
      { label: 'A$Ap Rocky', value: 'A$Ap Rocky' },
      { label: 'Aaliyah', value: 'Aaliyah' },
      { label: 'Aaron Carter', value: 'Aaron Carter' },
      { label: 'Aaron Hall', value: 'Aaron Hall' },
      { label: 'Aaron May', value: 'Aaron May' },
      { label: 'Aaron Neville', value: 'Aaron Neville' },
      { label: 'Aaron Smith', value: 'Aaron Smith' },
      { label: 'Aaron Tippin', value: 'Aaron Tippin' },
      { label: 'Abba', value: 'Abba' },
      { label: 'Abel Pintos', value: 'Abel Pintos' },
      { label: 'Above & Beyond', value: 'Above & Beyond' },
      { label: 'Abraham Mateo', value: 'Abraham Mateo' },
      { label: 'Abson', value: 'Abson' },
      { label: 'Ace Frehley', value: 'Ace Frehley' },
      { label: 'Ace Hood', value: 'Ace Hood' },
      { label: 'Ace Of Base', value: 'Ace Of Base' },
      { label: 'Action Bronson', value: 'Action Bronson' },
      { label: 'Adam Lambert', value: 'Adam Lambert' },
      { label: 'Adam Sandler', value: 'Adam Sandler' },
      { label: 'Adele', value: 'Adele' },
      { label: 'Ado', value: 'Ado' },
      { label: 'Adolescents Orquesta', value: 'Adolescents Orquesta' },
      { label: 'Aerosmith', value: 'Aerosmith' },
      { label: 'Aespa', value: 'Aespa' },
      { label: 'Afi', value: 'Afi' },
      { label: 'Afrojack', value: 'Afrojack' },
      { label: 'Against The Current', value: 'Against The Current' },
      { label: 'Agnes Obel', value: 'Agnes Obel' },
      { label: 'Aimer', value: 'Aimer' },
      { label: 'Aimyon', value: 'Aimyon' },
      { label: 'Air Supply', value: 'Air Supply' },
      { label: 'Aj Mitchell', value: 'Aj Mitchell' },
      { label: 'Aj Tracey', value: 'Aj Tracey' },
      { label: 'Ajr', value: 'Ajr' },
      { label: 'Ak Ausserkontrolle', value: 'Ak Ausserkontrolle' },
      { label: 'Akon', value: 'Akon' },
      { label: 'Al B. Sure!', value: 'Al B. Sure!' },
      { label: 'Al Green', value: 'Al Green' },
      { label: 'Al Hirt', value: 'Al Hirt' },
      { label: 'Al Jarreau', value: 'Al Jarreau' },
      { label: 'Al Stewart', value: 'Al Stewart' },
      { label: 'Alabama', value: 'Alabama' },
      { label: 'Alabama Shakes', value: 'Alabama Shakes' },
      { label: 'Alan Gomez', value: 'Alan Gomez' },
      { label: 'Alan Jackson', value: 'Alan Jackson' },
      { label: 'Alan Menken', value: 'Alan Menken' },
      { label: 'Alan Parsons Project', value: 'Alan Parsons Project' },
      { label: 'Alan Walker', value: 'Alan Walker' },
      { label: 'Alanis Morissette', value: 'Alanis Morissette' },
      { label: 'Alannah Myles', value: 'Alannah Myles' },
      { label: 'Aldo Nova', value: 'Aldo Nova' },
      { label: 'Aldo Trujillo', value: 'Aldo Trujillo' },
      { label: 'Alec Benjamin', value: 'Alec Benjamin' },
      { label: 'Alejandra Guzman', value: 'Alejandra Guzman' },
      { label: 'Alejandro Fernandez', value: 'Alejandro Fernandez' },
      { label: 'Alejandro Sanz', value: 'Alejandro Sanz' },
      { label: 'Alessia Cara', value: 'Alessia Cara' },
      { label: 'Alesso', value: 'Alesso' },
      { label: 'Alex & Sierra', value: 'Alex & Sierra' },
      { label: 'Alex Rose', value: 'Alex Rose' },
      { label: 'Alex Ubago', value: 'Alex Ubago' },
      { label: 'Alexander 23', value: 'Alexander 23' },
      { label: "Alexander O'Neal", value: "Alexander O'Neal" },
      { label: 'Alexandre Desplat', value: 'Alexandre Desplat' },
      { label: 'Alexis Y Fido', value: 'Alexis Y Fido' },
      { label: 'Ali Gatie', value: 'Ali Gatie' },
      { label: 'Alice Cooper', value: 'Alice Cooper' },
      { label: 'Alice In Chains', value: 'Alice In Chains' },
      { label: 'Alicia Keys', value: 'Alicia Keys' },
      { label: 'Alien Ant Farm', value: 'Alien Ant Farm' },
      { label: 'Alina Baraz', value: 'Alina Baraz' },
      { label: 'Alison Krauss', value: 'Alison Krauss' },
      { label: 'Alka Yagnik', value: 'Alka Yagnik' },
      { label: 'All Saints', value: 'All Saints' },
      { label: 'All Time Low', value: 'All Time Low' },
      { label: 'All-4-One', value: 'All-4-One' },
      { label: 'Alle Farben', value: 'Alle Farben' },
      { label: 'Allman Brothers Band', value: 'Allman Brothers Band' },
      { label: 'Alma', value: 'Alma' },
      { label: 'Almighty', value: 'Almighty' },
      { label: 'Aloe Blacc', value: 'Aloe Blacc' },
      { label: 'Alok', value: 'Alok' },
      { label: 'Alonzo', value: 'Alonzo' },
      { label: 'Alt-J', value: 'Alt-J' },
      { label: 'Alter Bridge', value: 'Alter Bridge' },
      { label: 'Aluna', value: 'Aluna' },
      { label: 'Alunageorge', value: 'Alunageorge' },
      { label: 'Alvaro Diaz', value: 'Alvaro Diaz' },
      { label: 'Amaranthe', value: 'Amaranthe' },
      { label: 'America', value: 'America' },
      { label: 'American Authors', value: 'American Authors' },
      { label: 'American Idols', value: 'American Idols' },
      { label: 'Amerie', value: 'Amerie' },
      { label: 'Amine', value: 'Amine' },
      { label: 'Amitabh Bhattacharya', value: 'Amitabh Bhattacharya' },
      { label: 'Amon Amarth', value: 'Amon Amarth' },
      { label: 'Amy Grant', value: 'Amy Grant' },
      { label: 'Amy Winehouse', value: 'Amy Winehouse' },
      { label: 'Ana Castela', value: 'Ana Castela' },
      { label: 'Ana Gabriel', value: 'Ana Gabriel' },
      { label: 'Ana Mena', value: 'Ana Mena' },
      { label: 'Anahi', value: 'Anahi' },
      { label: 'Anderson .Paak', value: 'Anderson .Paak' },
      { label: 'Andre 3000', value: 'Andre 3000' },
      { label: 'Andre Hazes', value: 'Andre Hazes' },
      { label: 'Andrea Bocelli', value: 'Andrea Bocelli' },
      { label: 'Andreas Vollenweider', value: 'Andreas Vollenweider' },
      { label: 'Andres Calamaro', value: 'Andres Calamaro' },
      { label: 'Andrew Lloyd Webber', value: 'Andrew Lloyd Webber' },
      { label: 'Andy Gibb', value: 'Andy Gibb' },
      { label: 'Andy Grammer', value: 'Andy Grammer' },
      { label: 'Andy Griffith', value: 'Andy Griffith' },
      { label: 'Andy Mineo', value: 'Andy Mineo' },
      { label: 'Andy Rivera', value: 'Andy Rivera' },
      { label: 'Andy Williams', value: 'Andy Williams' },
      { label: 'Angela Aguilar', value: 'Angela Aguilar' },
      { label: 'Angele', value: 'Angele' },
      { label: 'Angie Stone', value: 'Angie Stone' },
      { label: 'Angus & Julia Stone', value: 'Angus & Julia Stone' },
      { label: 'Anirudh Ravichander', value: 'Anirudh Ravichander' },
      { label: 'Anita Baker', value: 'Anita Baker' },
      { label: 'Anitta', value: 'Anitta' },
      { label: 'Anna Kendrick', value: 'Anna Kendrick' },
      { label: 'Annalisa', value: 'Annalisa' },
      { label: 'Anne Murray', value: 'Anne Murray' },
      { label: 'Anne-Marie', value: 'Anne-Marie' },
      { label: 'Annenmaykantereit', value: 'Annenmaykantereit' },
      { label: 'Annie Lennox', value: 'Annie Lennox' },
      { label: 'Anson Seabra', value: 'Anson Seabra' },
      { label: 'Anthony Hamilton', value: 'Anthony Hamilton' },
      { label: 'Anthony Ramos', value: 'Anthony Ramos' },
      { label: 'Anthrax', value: 'Anthrax' },
      { label: 'Antonio Aguilar', value: 'Antonio Aguilar' },
      { label: 'Antonio Carlos Jobim', value: 'Antonio Carlos Jobim' },
      { label: 'Antonio Orozco', value: 'Antonio Orozco' },
      { label: 'Antonio Vivaldi', value: 'Antonio Vivaldi' },
      { label: 'Anuel Aa', value: 'Anuel Aa' },
      { label: 'Aoa', value: 'Aoa' },
      { label: 'Apache 207', value: 'Apache 207' },
      { label: 'Aphex Twin', value: 'Aphex Twin' },
      { label: 'April Wine', value: 'April Wine' },
      { label: 'Aqua', value: 'Aqua' },
      { label: 'Arashi', value: 'Arashi' },
      { label: 'Arcade Fire', value: 'Arcade Fire' },
      { label: 'Arcane', value: 'Arcane' },
      { label: 'Arcangel', value: 'Arcangel' },
      { label: 'Arctic Monkeys', value: 'Arctic Monkeys' },
      { label: 'Aretha Franklin', value: 'Aretha Franklin' },
      { label: 'Ari Lennox', value: 'Ari Lennox' },
      { label: 'Ariana Grande', value: 'Ariana Grande' },
      { label: 'Arijit Singh', value: 'Arijit Singh' },
      { label: 'Arlo Guthrie', value: 'Arlo Guthrie' },
      { label: 'Armaan Malik', value: 'Armaan Malik' },
      { label: 'Armin Van Buuren', value: 'Armin Van Buuren' },
      { label: 'Arrested Development', value: 'Arrested Development' },
      { label: 'Art Garfunkel', value: 'Art Garfunkel' },
      { label: 'Arthur Nery', value: 'Arthur Nery' },
      { label: 'Asake', value: 'Asake' },
      { label: 'Asees Kaur', value: 'Asees Kaur' },
      { label: 'Asha Bhosle', value: 'Asha Bhosle' },
      { label: 'Ashanti', value: 'Ashanti' },
      { label: 'Ashe', value: 'Ashe' },
      { label: 'Ashford & Simpson', value: 'Ashford & Simpson' },
      { label: 'Ashlee Simpson', value: 'Ashlee Simpson' },
      { label: 'Ashnikko', value: 'Ashnikko' },
      { label: 'Asia', value: 'Asia' },
      { label: 'Asking Alexandria', value: 'Asking Alexandria' },
      { label: 'Astrid S', value: 'Astrid S' },
      { label: 'Atb', value: 'Atb' },
      { label: 'Ateez', value: 'Ateez' },
      { label: 'Atif Aslam', value: 'Atif Aslam' },
      { label: 'Atitude 67', value: 'Atitude 67' },
      { label: 'Atlanta Rhythm Section', value: 'Atlanta Rhythm Section' },
      { label: 'Atlantic Starr', value: 'Atlantic Starr' },
      { label: 'Atreyu', value: 'Atreyu' },
      { label: 'Audioslave', value: 'Audioslave' },
      { label: 'August Alsina', value: 'August Alsina' },
      { label: 'Aurora', value: 'Aurora' },
      { label: 'Ava Max', value: 'Ava Max' },
      { label: 'Avalon', value: 'Avalon' },
      { label: 'Avenged Sevenfold', value: 'Avenged Sevenfold' },
      { label: 'Aventura', value: 'Aventura' },
      { label: 'Average White Band', value: 'Average White Band' },
      { label: 'Avicii', value: 'Avicii' },
      { label: 'Avril Lavigne', value: 'Avril Lavigne' },
      { label: 'Awolnation', value: 'Awolnation' },
      { label: 'Axwell', value: 'Axwell' },
      { label: 'Axwell / Ingrosso', value: 'Axwell / Ingrosso' },
      { label: 'Aya Nakamura', value: 'Aya Nakamura' },
      { label: 'Ayo & Teo', value: 'Ayo & Teo' },
      { label: 'Ayra Starr', value: 'Ayra Starr' },
      { label: 'Az Yet', value: 'Az Yet' },
      { label: 'Azealia Banks', value: 'Azealia Banks' },
      { label: "B-52's", value: "B-52's" },
      { label: 'B.B. King', value: 'B.B. King' },
      { label: 'B2K', value: 'B2K' },
      { label: 'Babasonicos', value: 'Babasonicos' },
      { label: 'Baby Bash', value: 'Baby Bash' },
      {
        label: 'Baby Einstein Music Box Orchestra',
        value: 'Baby Einstein Music Box Orchestra',
      },
      { label: 'Baby Gang', value: 'Baby Gang' },
      { label: 'Baby Rasta & Gringo', value: 'Baby Rasta & Gringo' },
      { label: 'Baby Tate', value: 'Baby Tate' },
      { label: 'Babyface', value: 'Babyface' },
      { label: 'Babymetal', value: 'Babymetal' },
      { label: 'Bachman-Turner Overdrive', value: 'Bachman-Turner Overdrive' },
      { label: 'Bacilos', value: 'Bacilos' },
      { label: 'Back Number', value: 'Back Number' },
      { label: 'Backstreet Boys', value: 'Backstreet Boys' },
      { label: 'Baco Exu Do Blues', value: 'Baco Exu Do Blues' },
      { label: 'Bad Bunny', value: 'Bad Bunny' },
      { label: 'Bad Company', value: 'Bad Company' },
      { label: 'Bad English', value: 'Bad English' },
      { label: 'Bad Gyal', value: 'Bad Gyal' },
      { label: 'Bad Religion', value: 'Bad Religion' },
      { label: 'Bad Wolves', value: 'Bad Wolves' },
      { label: 'Bagua Records', value: 'Bagua Records' },
      { label: 'Bahamas', value: 'Bahamas' },
      { label: 'Bailey Zimmerman', value: 'Bailey Zimmerman' },
      { label: 'Band Of Horses', value: 'Band Of Horses' },
      { label: 'Banda Carnaval', value: 'Banda Carnaval' },
      { label: 'Banda El Recodo', value: 'Banda El Recodo' },
      { label: 'Banda Los Recoditos', value: 'Banda Los Recoditos' },
      {
        label: 'Banda Ms De Sergio Lizarraga',
        value: 'Banda Ms De Sergio Lizarraga',
      },
      { label: 'Banda Pequenos Musical', value: 'Banda Pequenos Musical' },
      { label: 'Bankrol Hayden', value: 'Bankrol Hayden' },
      { label: 'Banks', value: 'Banks' },
      { label: 'Banners', value: 'Banners' },
      { label: 'Bar-Kays', value: 'Bar-Kays' },
      { label: 'Barbara Mandrell', value: 'Barbara Mandrell' },
      { label: 'Barbra Streisand', value: 'Barbra Streisand' },
      { label: 'Barenaked Ladies', value: 'Barenaked Ladies' },
      { label: 'Barney', value: 'Barney' },
      { label: 'Barry Manilow', value: 'Barry Manilow' },
      { label: 'Barry White', value: 'Barry White' },
      { label: 'Bas', value: 'Bas' },
      { label: 'Basia', value: 'Basia' },
      { label: 'Bastille', value: 'Bastille' },
      { label: 'Bay City Rollers', value: 'Bay City Rollers' },
      { label: 'Bazzi', value: 'Bazzi' },
      { label: 'Bbno$', value: 'Bbno$' },
      { label: 'Beach Bunny', value: 'Beach Bunny' },
      { label: 'Beach House', value: 'Beach House' },
      { label: 'Beach Weather', value: 'Beach Weather' },
      { label: 'Beastie Boys', value: 'Beastie Boys' },
      { label: 'Beavis & Butt-Head', value: 'Beavis & Butt-Head' },
      { label: 'Bebe Rexha', value: 'Bebe Rexha' },
      { label: 'Beck', value: 'Beck' },
      { label: 'Becky G', value: 'Becky G' },
      { label: 'Becky Hill', value: 'Becky Hill' },
      { label: 'Bedoes 2115', value: 'Bedoes 2115' },
      { label: 'Bee Gees', value: 'Bee Gees' },
      { label: 'Belinda', value: 'Belinda' },
      { label: 'Belinda Carlisle', value: 'Belinda Carlisle' },
      { label: 'Bell Biv Devoe', value: 'Bell Biv Devoe' },
      { label: 'Bella Poarch', value: 'Bella Poarch' },
      { label: 'Belly', value: 'Belly' },
      { label: 'Ben E. King', value: 'Ben E. King' },
      { label: 'Ben Folds Five', value: 'Ben Folds Five' },
      { label: 'Ben Harper', value: 'Ben Harper' },
      { label: 'Ben Howard', value: 'Ben Howard' },
      { label: 'Ben Platt', value: 'Ben Platt' },
      { label: 'Ben Rector', value: 'Ben Rector' },
      {
        label: 'Benedictine Monks-Santo De Sil',
        value: 'Benedictine Monks-Santo De Sil',
      },
      { label: 'Benjamin Ingrosso', value: 'Benjamin Ingrosso' },
      { label: 'Benny Benassi', value: 'Benny Benassi' },
      { label: 'Benny Blanco', value: 'Benny Blanco' },
      { label: 'Benny Dayal', value: 'Benny Dayal' },
      { label: 'Benson Boone', value: 'Benson Boone' },
      { label: 'Beret', value: 'Beret' },
      { label: 'Berlin', value: 'Berlin' },
      { label: 'Bert Kaempfert', value: 'Bert Kaempfert' },
      { label: 'Bethel Music', value: 'Bethel Music' },
      { label: 'Bette Midler', value: 'Bette Midler' },
      { label: 'Better Than Ezra', value: 'Better Than Ezra' },
      { label: 'Beyonce', value: 'Beyonce' },
      { label: 'Bhad Bhabie', value: 'Bhad Bhabie' },
      { label: 'Bia', value: 'Bia' },
      { label: 'Bibi Und Tina', value: 'Bibi Und Tina' },
      { label: 'Biffy Clyro', value: 'Biffy Clyro' },
      { label: 'Big & Rich', value: 'Big & Rich' },
      { label: 'Big Bad Voodoo Daddy', value: 'Big Bad Voodoo Daddy' },
      { label: 'Big Daddy Kane', value: 'Big Daddy Kane' },
      {
        label: 'Big Head Todd & The Monsters',
        value: 'Big Head Todd & The Monsters',
      },
      { label: 'Big Punisher', value: 'Big Punisher' },
      { label: 'Big Sean', value: 'Big Sean' },
      { label: 'Big Soto', value: 'Big Soto' },
      { label: 'Big Time Rush', value: 'Big Time Rush' },
      { label: 'Big Tymers', value: 'Big Tymers' },
      { label: 'Bigbang', value: 'Bigbang' },
      { label: 'Bill Cosby', value: 'Bill Cosby' },
      { label: 'Bill Engvall', value: 'Bill Engvall' },
      { label: 'Bill Evans', value: 'Bill Evans' },
      { label: 'Bill Whelan', value: 'Bill Whelan' },
      { label: 'Bill Withers', value: 'Bill Withers' },
      { label: 'Billie Eilish', value: 'Billie Eilish' },
      { label: 'Billie Holiday', value: 'Billie Holiday' },
      { label: 'Billy Currington', value: 'Billy Currington' },
      { label: 'Billy Dean', value: 'Billy Dean' },
      { label: 'Billy Gilman', value: 'Billy Gilman' },
      { label: 'Billy Idol', value: 'Billy Idol' },
      { label: 'Billy Joel', value: 'Billy Joel' },
      { label: 'Billy Ocean', value: 'Billy Ocean' },
      { label: 'Billy Ray Cyrus', value: 'Billy Ray Cyrus' },
      { label: 'Billy Squier', value: 'Billy Squier' },
      { label: 'Billy Talent', value: 'Billy Talent' },
      { label: 'Billy Vaughn', value: 'Billy Vaughn' },
      { label: 'Bin', value: 'Bin' },
      { label: 'Bing Crosby', value: 'Bing Crosby' },
      {
        label: 'Binomio De Oro De America',
        value: 'Binomio De Oro De America',
      },
      { label: 'Bipolar Sunshine', value: 'Bipolar Sunshine' },
      { label: 'Birdman', value: 'Birdman' },
      { label: 'Birdy', value: 'Birdy' },
      { label: 'Bishop Briggs', value: 'Bishop Briggs' },
      { label: 'Bj The Chicago Kid', value: 'Bj The Chicago Kid' },
      { label: 'Bjork', value: 'Bjork' },
      { label: 'Bk', value: 'Bk' },
      { label: 'Black Eyed Peas', value: 'Black Eyed Peas' },
      { label: 'Black Oak Arkansas', value: 'Black Oak Arkansas' },
      { label: 'Black Rob', value: 'Black Rob' },
      { label: 'Black Sabbath', value: 'Black Sabbath' },
      { label: 'Black Veil Brides', value: 'Black Veil Brides' },
      { label: 'Blackbear', value: 'Blackbear' },
      { label: 'Blackbyrds', value: 'Blackbyrds' },
      { label: 'Blackfoot', value: 'Blackfoot' },
      { label: 'Blackhawk', value: 'Blackhawk' },
      { label: 'Blackpink', value: 'Blackpink' },
      { label: 'Blake Shelton', value: 'Blake Shelton' },
      { label: 'Blanco', value: 'Blanco' },
      { label: 'Bleachers', value: 'Bleachers' },
      { label: 'Blind Faith', value: 'Blind Faith' },
      { label: 'Blind Melon', value: 'Blind Melon' },
      { label: 'Blink-182', value: 'Blink-182' },
      { label: 'Bloc Party', value: 'Bloc Party' },
      { label: 'Blocboy Jb', value: 'Blocboy Jb' },
      { label: 'Blondie', value: 'Blondie' },
      { label: 'Blood Orange', value: 'Blood Orange' },
      { label: 'Bloodhound Gang', value: 'Bloodhound Gang' },
      { label: 'Blue October', value: 'Blue October' },
      { label: 'Blue Oyster Cult', value: 'Blue Oyster Cult' },
      { label: 'Blues Brothers', value: 'Blues Brothers' },
      { label: 'Blues Traveler', value: 'Blues Traveler' },
      { label: 'Blur', value: 'Blur' },
      { label: 'Bm', value: 'Bm' },
      { label: 'Bo Burnham', value: 'Bo Burnham' },
      { label: 'Bob Carlisle', value: 'Bob Carlisle' },
      { label: 'Bob Dylan', value: 'Bob Dylan' },
      { label: 'Bob Marley & The Wailers', value: 'Bob Marley & The Wailers' },
      { label: 'Bob Newhart', value: 'Bob Newhart' },
      { label: 'Bob Seger', value: 'Bob Seger' },
      {
        label: 'Bob Seger & The Silver Bullet Band',
        value: 'Bob Seger & The Silver Bullet Band',
      },
      { label: 'Bob Sinclar', value: 'Bob Sinclar' },
      { label: 'Bob Welch', value: 'Bob Welch' },
      { label: 'Bobby Brown', value: 'Bobby Brown' },
      { label: 'Bobby Helms', value: 'Bobby Helms' },
      { label: 'Bobby Mc Ferrin', value: 'Bobby Mc Ferrin' },
      { label: 'Bobby Sherman', value: 'Bobby Sherman' },
      { label: 'Bobby Shmurda', value: 'Bobby Shmurda' },
      { label: 'Bobby Vinton', value: 'Bobby Vinton' },
      { label: 'Bomba Estereo', value: 'Bomba Estereo' },
      { label: 'Bon Iver', value: 'Bon Iver' },
      { label: 'Bon Jovi', value: 'Bon Jovi' },
      { label: 'Bone Thugs-N-Harmony', value: 'Bone Thugs-N-Harmony' },
      { label: 'Bones', value: 'Bones' },
      { label: 'Boney James', value: 'Boney James' },
      { label: 'Boney M.', value: 'Boney M.' },
      { label: 'Bonez Mc', value: 'Bonez Mc' },
      { label: 'Bonnie Raitt', value: 'Bonnie Raitt' },
      { label: 'Bonnie Tyler', value: 'Bonnie Tyler' },
      { label: 'Bonobo', value: 'Bonobo' },
      { label: 'Booba', value: 'Booba' },
      { label: 'Boogie Down Productions', value: 'Boogie Down Productions' },
      { label: 'Boomdabash', value: 'Boomdabash' },
      { label: 'Boosie Badazz', value: 'Boosie Badazz' },
      { label: 'Boots Randolph', value: 'Boots Randolph' },
      { label: 'Bootsy Collins', value: 'Bootsy Collins' },
      { label: 'Borges', value: 'Borges' },
      { label: 'Borns', value: 'Borns' },
      { label: 'Boston', value: 'Boston' },
      { label: 'Bow Wow', value: 'Bow Wow' },
      { label: 'Bowling For Soup', value: 'Bowling For Soup' },
      { label: 'Boy Pablo', value: 'Boy Pablo' },
      { label: 'Boyz Ii Men', value: 'Boyz Ii Men' },
      { label: 'Boz Scaggs', value: 'Boz Scaggs' },
      { label: 'Brad Paisley', value: 'Brad Paisley' },
      { label: 'Brand New', value: 'Brand New' },
      { label: 'Brandi Carlile', value: 'Brandi Carlile' },
      { label: 'Brandon Lake', value: 'Brandon Lake' },
      { label: 'Brandy', value: 'Brandy' },
      { label: 'Brantley Gilbert', value: 'Brantley Gilbert' },
      { label: 'Brass Construction', value: 'Brass Construction' },
      { label: 'Bread', value: 'Bread' },
      { label: 'Breaking Benjamin', value: 'Breaking Benjamin' },
      { label: 'Brenda Lee', value: 'Brenda Lee' },
      { label: 'Brennan Heart', value: 'Brennan Heart' },
      { label: 'Brent Faiyaz', value: 'Brent Faiyaz' },
      { label: 'Brett Eldredge', value: 'Brett Eldredge' },
      { label: 'Brett Young', value: 'Brett Young' },
      { label: 'Brian Eno', value: 'Brian Eno' },
      { label: 'Brian Mc Knight', value: 'Brian Mc Knight' },
      { label: 'Brian Setzer Orchestra', value: 'Brian Setzer Orchestra' },
      { label: 'Bring Me The Horizon', value: 'Bring Me The Horizon' },
      { label: 'Britney Spears', value: 'Britney Spears' },
      { label: 'Brockhampton', value: 'Brockhampton' },
      { label: 'Broederliefde', value: 'Broederliefde' },
      { label: 'Bronco', value: 'Bronco' },
      { label: 'Brooks', value: 'Brooks' },
      { label: 'Brooks & Dunn', value: 'Brooks & Dunn' },
      { label: 'Brothers Johnson', value: 'Brothers Johnson' },
      { label: 'Brothers Osborne', value: 'Brothers Osborne' },
      { label: 'Brownstone', value: 'Brownstone' },
      {
        label: 'Bruce Hornsby & The Range',
        value: 'Bruce Hornsby & The Range',
      },
      { label: 'Bruce Springsteen', value: 'Bruce Springsteen' },
      { label: 'Bruno & Marrone', value: 'Bruno & Marrone' },
      { label: 'Bruno Major', value: 'Bruno Major' },
      { label: 'Bruno Mars', value: 'Bruno Mars' },
      { label: 'Bruno Martini', value: 'Bruno Martini' },
      { label: 'Bryan Adams', value: 'Bryan Adams' },
      { label: 'Bryan White', value: 'Bryan White' },
      { label: 'Bryce Vine', value: 'Bryce Vine' },
      { label: 'Bryson Tiller', value: 'Bryson Tiller' },
      { label: 'Bts', value: 'Bts' },
      { label: 'Buckcherry', value: 'Buckcherry' },
      {
        label: 'Buddy Holly & The Crickets',
        value: 'Buddy Holly & The Crickets',
      },
      { label: 'Buena Vista Social Club', value: 'Buena Vista Social Club' },
      { label: 'Buffalo Springfield', value: 'Buffalo Springfield' },
      { label: 'Bullet For My Valentine', value: 'Bullet For My Valentine' },
      { label: 'Bunbury', value: 'Bunbury' },
      { label: 'Burna Boy', value: 'Burna Boy' },
      { label: 'Burt Bacharach', value: 'Burt Bacharach' },
      { label: 'Bush', value: 'Bush' },
      { label: 'Bushido', value: 'Bushido' },
      { label: 'Busta Rhymes', value: 'Busta Rhymes' },
      { label: 'C. Tangana', value: 'C. Tangana' },
      { label: 'C&C Music Factory', value: 'C&C Music Factory' },
      { label: 'Caetano Veloso', value: 'Caetano Veloso' },
      { label: 'Cafe Tacvba', value: 'Cafe Tacvba' },
      { label: 'Cage The Elephant', value: 'Cage The Elephant' },
      { label: 'Caifanes', value: 'Caifanes' },
      { label: 'Cake', value: 'Cake' },
      { label: 'Cali Y El Dandee', value: 'Cali Y El Dandee' },
      { label: 'Calibre 50', value: 'Calibre 50' },
      { label: 'Calle 13', value: 'Calle 13' },
      { label: 'Callejeros', value: 'Callejeros' },
      { label: 'Calvin Harris', value: 'Calvin Harris' },
      { label: 'Camelphat', value: 'Camelphat' },
      { label: 'Cameo', value: 'Cameo' },
      { label: 'Cami', value: 'Cami' },
      { label: 'Camila', value: 'Camila' },
      { label: 'Camila Cabello', value: 'Camila Cabello' },
      { label: 'Camilo', value: 'Camilo' },
      { label: 'Camilo Sesto', value: 'Camilo Sesto' },
      { label: 'Candle', value: 'Candle' },
      { label: 'Candlebox', value: 'Candlebox' },
      { label: 'Canserbero', value: 'Canserbero' },
      { label: 'Capital Bra', value: 'Capital Bra' },
      { label: 'Capital Cities', value: 'Capital Cities' },
      { label: 'Capo', value: 'Capo' },
      { label: 'Capo Plaza', value: 'Capo Plaza' },
      { label: 'Captain & Tennille', value: 'Captain & Tennille' },
      { label: 'Caravan Palace', value: 'Caravan Palace' },
      { label: 'Cardenales De Nuevo Leon', value: 'Cardenales De Nuevo Leon' },
      { label: 'Cardi B', value: 'Cardi B' },
      { label: 'Carin Leon', value: 'Carin Leon' },
      { label: 'Carl Brave', value: 'Carl Brave' },
      { label: 'Carl Doy', value: 'Carl Doy' },
      { label: 'Carl Thomas', value: 'Carl Thomas' },
      { label: 'Carla Morrison', value: 'Carla Morrison' },
      { label: 'Carlos Baute', value: 'Carlos Baute' },
      { label: 'Carlos Rivera', value: 'Carlos Rivera' },
      {
        label: 'Carlos Santana & Buddy Miles',
        value: 'Carlos Santana & Buddy Miles',
      },
      { label: 'Carlos Vives', value: 'Carlos Vives' },
      { label: 'Carly Rae Jepsen', value: 'Carly Rae Jepsen' },
      { label: 'Carly Simon', value: 'Carly Simon' },
      { label: 'Carnage', value: 'Carnage' },
      { label: 'Carole King', value: 'Carole King' },
      { label: 'Carrie Underwood', value: 'Carrie Underwood' },
      { label: 'Cartel De Santa', value: 'Cartel De Santa' },
      { label: 'Cascada', value: 'Cascada' },
      { label: 'Case', value: 'Case' },
      { label: 'Cash Cash', value: 'Cash Cash' },
      { label: 'Cashmere Cat', value: 'Cashmere Cat' },
      { label: 'Casper Magico', value: 'Casper Magico' },
      { label: 'Casting Crowns', value: 'Casting Crowns' },
      { label: 'Cat Stevens', value: 'Cat Stevens' },
      {
        label: 'Catfish And The Bottlemen',
        value: 'Catfish And The Bottlemen',
      },
      { label: 'Cazzu', value: 'Cazzu' },
      { label: 'Cece Winans', value: 'Cece Winans' },
      { label: 'Cedarmont Kids', value: 'Cedarmont Kids' },
      { label: 'Cedric Gervais', value: 'Cedric Gervais' },
      { label: 'Ceelo Green', value: 'Ceelo Green' },
      { label: 'Celine Dion', value: 'Celine Dion' },
      { label: 'Celtic Woman', value: 'Celtic Woman' },
      { label: 'Central Cee', value: 'Central Cee' },
      { label: 'Chaka Khan', value: 'Chaka Khan' },
      { label: 'Chalino Sanchez', value: 'Chalino Sanchez' },
      { label: 'Chamillionaire', value: 'Chamillionaire' },
      { label: 'Chance The Rapper', value: 'Chance The Rapper' },
      { label: 'Charley Pride', value: 'Charley Pride' },
      { label: 'Charli Xcx', value: 'Charli Xcx' },
      { label: 'Charlie Brown Jr.', value: 'Charlie Brown Jr.' },
      { label: 'Charlie Daniels Band', value: 'Charlie Daniels Band' },
      { label: 'Charlie Puth', value: 'Charlie Puth' },
      { label: 'Charlie Rich', value: 'Charlie Rich' },
      { label: 'Charlie Wilson', value: 'Charlie Wilson' },
      { label: 'Charlie Zaa', value: 'Charlie Zaa' },
      { label: 'Charlotte Church', value: 'Charlotte Church' },
      { label: 'Charlotte Lawrence', value: 'Charlotte Lawrence' },
      { label: 'Charly Garcia', value: 'Charly Garcia' },
      { label: 'Chase & Status', value: 'Chase & Status' },
      { label: 'Chase Atlantic', value: 'Chase Atlantic' },
      { label: 'Chase Rice', value: 'Chase Rice' },
      { label: 'Chayanne', value: 'Chayanne' },
      { label: 'Cheap Trick', value: 'Cheap Trick' },
      { label: 'Cheat Codes', value: 'Cheat Codes' },
      { label: 'Cheech & Chong', value: 'Cheech & Chong' },
      { label: 'Cheetah Girls', value: 'Cheetah Girls' },
      { label: 'Chelsea Cutler', value: 'Chelsea Cutler' },
      { label: 'Cher', value: 'Cher' },
      { label: 'Cher Lloyd', value: 'Cher Lloyd' },
      { label: 'Cherish', value: 'Cherish' },
      { label: "Cherry Poppin' Daddies", value: "Cherry Poppin' Daddies" },
      { label: 'Chet Faker', value: 'Chet Faker' },
      { label: 'Chevelle', value: 'Chevelle' },
      { label: 'Chic', value: 'Chic' },
      { label: 'Chicago', value: 'Chicago' },
      { label: 'Chief Keef', value: 'Chief Keef' },
      { label: 'Childish Gambino', value: 'Childish Gambino' },
      { label: 'Chingy', value: 'Chingy' },
      { label: 'Chino & Nacho', value: 'Chino & Nacho' },
      { label: 'Chipmunks', value: 'Chipmunks' },
      { label: 'Chris Botti', value: 'Chris Botti' },
      { label: 'Chris Brown', value: 'Chris Brown' },
      { label: 'Chris Cagle', value: 'Chris Cagle' },
      { label: 'Chris Isaak', value: 'Chris Isaak' },
      { label: 'Chris Lake', value: 'Chris Lake' },
      { label: 'Chris Lane', value: 'Chris Lane' },
      { label: 'Chris Ledoux', value: 'Chris Ledoux' },
      { label: 'Chris Rea', value: 'Chris Rea' },
      { label: 'Chris Stapleton', value: 'Chris Stapleton' },
      { label: 'Chris Tomlin', value: 'Chris Tomlin' },
      { label: 'Chris Young', value: 'Chris Young' },
      { label: 'Christian Chavez', value: 'Christian Chavez' },
      { label: 'Christian Nodal', value: 'Christian Nodal' },
      { label: 'Christina Aguilera', value: 'Christina Aguilera' },
      { label: 'Christina Perri', value: 'Christina Perri' },
      { label: 'Christopher', value: 'Christopher' },
      { label: 'Christopher Cross', value: 'Christopher Cross' },
      { label: 'Christopher Jackson', value: 'Christopher Jackson' },
      { label: 'Chuck Berry', value: 'Chuck Berry' },
      { label: 'Chuck Mangione', value: 'Chuck Mangione' },
      { label: 'Chumbawamba', value: 'Chumbawamba' },
      { label: 'Chvrches', value: 'Chvrches' },
      { label: 'Ciara', value: 'Ciara' },
      { label: 'Cigarettes After Sex', value: 'Cigarettes After Sex' },
      { label: 'Cinderella', value: 'Cinderella' },
      { label: 'City And Colour', value: 'City And Colour' },
      { label: 'City Girls', value: 'City Girls' },
      { label: 'Cj', value: 'Cj' },
      { label: 'Clairo', value: 'Clairo' },
      { label: 'Clannad', value: 'Clannad' },
      { label: 'Claude Debussy', value: 'Claude Debussy' },
      { label: 'Clay Walker', value: 'Clay Walker' },
      { label: 'Clean Bandit', value: 'Clean Bandit' },
      { label: 'Clint Black', value: 'Clint Black' },
      { label: 'Club Nouveau', value: 'Club Nouveau' },
      { label: 'Cnco', value: 'Cnco' },
      { label: 'Codigo Fn', value: 'Codigo Fn' },
      { label: 'Cody Johnson', value: 'Cody Johnson' },
      { label: 'Coheed & Cambria', value: 'Coheed & Cambria' },
      { label: 'Coin', value: 'Coin' },
      { label: 'Colbie Caillat', value: 'Colbie Caillat' },
      { label: 'Cold', value: 'Cold' },
      { label: 'Cold War Kids', value: 'Cold War Kids' },
      { label: 'Coldplay', value: 'Coldplay' },
      { label: 'Cole Swindell', value: 'Cole Swindell' },
      { label: 'Collective Soul', value: 'Collective Soul' },
      { label: 'Collin Raye', value: 'Collin Raye' },
      { label: 'Color Me Badd', value: 'Color Me Badd' },
      { label: 'Colt Ford', value: 'Colt Ford' },
      { label: 'Comethazine', value: 'Comethazine' },
      { label: 'Commodores', value: 'Commodores' },
      { label: 'Common', value: 'Common' },
      { label: 'Con Funk Shun', value: 'Con Funk Shun' },
      { label: 'Conan Gray', value: 'Conan Gray' },
      { label: 'Conjunto Primavera', value: 'Conjunto Primavera' },
      { label: 'Connor Price', value: 'Connor Price' },
      { label: 'Conor Maynard', value: 'Conor Maynard' },
      { label: 'Conway Twitty', value: 'Conway Twitty' },
      {
        label: 'Conway Twitty & Loretta Lynn',
        value: 'Conway Twitty & Loretta Lynn',
      },
      { label: 'Cookin On 3 Burners', value: 'Cookin On 3 Burners' },
      { label: 'Coolio', value: 'Coolio' },
      { label: 'Corey Hart', value: 'Corey Hart' },
      { label: 'Corinne Bailey Rae', value: 'Corinne Bailey Rae' },
      { label: 'Corpse', value: 'Corpse' },
      { label: 'Cosculluela', value: 'Cosculluela' },
      { label: 'Count Basie', value: 'Count Basie' },
      { label: 'Counting Crows', value: 'Counting Crows' },
      { label: 'Coverdale Page', value: 'Coverdale Page' },
      { label: 'Cowboy Junkies', value: 'Cowboy Junkies' },
      { label: 'Craig David', value: 'Craig David' },
      { label: 'Craig Morgan', value: 'Craig Morgan' },
      { label: 'Crash Test Dummies', value: 'Crash Test Dummies' },
      { label: 'Crazy Town', value: 'Crazy Town' },
      { label: 'Cream', value: 'Cream' },
      { label: 'Creed', value: 'Creed' },
      {
        label: 'Creedence Clearwater Revisited',
        value: 'Creedence Clearwater Revisited',
      },
      {
        label: 'Creedence Clearwater Revival',
        value: 'Creedence Clearwater Revival',
      },
      { label: 'Cristian Castro', value: 'Cristian Castro' },
      { label: 'Cro', value: 'Cro' },
      { label: 'Crossfade', value: 'Crossfade' },
      { label: 'Crowded House', value: 'Crowded House' },
      { label: 'Crusaders', value: 'Crusaders' },
      { label: 'Crush', value: 'Crush' },
      { label: 'Cruz Cafune', value: 'Cruz Cafune' },
      { label: 'Crystal Castles', value: 'Crystal Castles' },
      { label: 'Crystal Gayle', value: 'Crystal Gayle' },
      { label: 'Cuco', value: 'Cuco' },
      { label: 'Cults', value: 'Cults' },
      { label: 'Culture Club', value: 'Culture Club' },
      { label: 'Curren$Y', value: 'Curren$Y' },
      { label: 'Curtis Mayfield', value: 'Curtis Mayfield' },
      { label: 'Cyndi Lauper', value: 'Cyndi Lauper' },
      { label: 'Cypress Hill', value: 'Cypress Hill' },
      { label: 'D-Block Europe', value: 'D-Block Europe' },
      { label: "D'Angelo", value: "D'Angelo" },
      { label: 'D12', value: 'D12' },
      { label: 'Da Brat', value: 'Da Brat' },
      { label: 'Dababy', value: 'Dababy' },
      { label: 'Daddy Yankee', value: 'Daddy Yankee' },
      { label: 'Daft Punk', value: 'Daft Punk' },
      { label: 'Dakota', value: 'Dakota' },
      { label: 'Damian Marley', value: 'Damian Marley' },
      { label: 'Damien Rice', value: 'Damien Rice' },
      { label: 'Dan + Shay', value: 'Dan + Shay' },
      { label: 'Dan Caplen', value: 'Dan Caplen' },
      { label: 'Dan Fogelberg', value: 'Dan Fogelberg' },
      { label: 'Dan Seals', value: 'Dan Seals' },
      { label: 'Dane Cook', value: 'Dane Cook' },
      { label: 'Dangelo', value: 'Dangelo' },
      { label: 'Daniel Caesar', value: 'Daniel Caesar' },
      { label: 'Daniel Hope', value: 'Daniel Hope' },
      { label: 'Danileigh', value: 'Danileigh' },
      { label: 'Danity Kane', value: 'Danity Kane' },
      { label: 'Danna Paola', value: 'Danna Paola' },
      { label: 'Danny Brown', value: 'Danny Brown' },
      { label: 'Danny Ocean', value: 'Danny Ocean' },
      { label: 'Dannylux', value: 'Dannylux' },
      { label: 'Darius Rucker', value: 'Darius Rucker' },
      { label: 'Daryl Hall & John Oates', value: 'Daryl Hall & John Oates' },
      { label: 'Das Efx', value: 'Das Efx' },
      { label: 'Dashboard Confessional', value: 'Dashboard Confessional' },
      { label: 'Daughter', value: 'Daughter' },
      { label: 'Daughtry', value: 'Daughtry' },
      { label: 'Dave Brubeck', value: 'Dave Brubeck' },
      { label: 'Dave Clark Five', value: 'Dave Clark Five' },
      { label: 'Dave Koz', value: 'Dave Koz' },
      { label: 'Dave Mason', value: 'Dave Mason' },
      { label: 'Dave Matthews Band', value: 'Dave Matthews Band' },
      { label: 'David Allan Coe', value: 'David Allan Coe' },
      { label: 'David Ball', value: 'David Ball' },
      { label: 'David Bisbal', value: 'David Bisbal' },
      { label: 'David Bowie', value: 'David Bowie' },
      { label: 'David Cook', value: 'David Cook' },
      {
        label: 'David Crosby & Graham Nash',
        value: 'David Crosby & Graham Nash',
      },
      { label: 'David Gilmour', value: 'David Gilmour' },
      { label: 'David Gray', value: 'David Gray' },
      { label: 'David Guetta', value: 'David Guetta' },
      { label: 'David Kushner', value: 'David Kushner' },
      { label: 'David Lee Murphy', value: 'David Lee Murphy' },
      { label: 'David Lee Roth', value: 'David Lee Roth' },
      { label: 'David Sanborn', value: 'David Sanborn' },
      { label: 'Dawid Podsiad_O', value: 'Dawid Podsiad_O' },
      { label: 'Dax', value: 'Dax' },
      { label: 'Day6', value: 'Day6' },
      { label: 'Daya', value: 'Daya' },
      { label: 'Days Of The New', value: 'Days Of The New' },
      { label: 'Dazy', value: 'Dazy' },
      { label: 'Dc Talk', value: 'Dc Talk' },
      { label: 'De Barge', value: 'De Barge' },
      { label: 'De La Ghetto', value: 'De La Ghetto' },
      { label: 'De La Soul', value: 'De La Soul' },
      { label: 'Dead Kennedys', value: 'Dead Kennedys' },
      { label: 'Deadmau5', value: 'Deadmau5' },
      { label: 'Dean Lewis', value: 'Dean Lewis' },
      { label: 'Dean Martin', value: 'Dean Martin' },
      { label: 'Deana Carter', value: 'Deana Carter' },
      { label: 'Death Cab For Cutie', value: 'Death Cab For Cutie' },
      { label: 'Debbie Gibson', value: 'Debbie Gibson' },
      { label: 'Debby Boone', value: 'Debby Boone' },
      { label: 'Deborah Cox', value: 'Deborah Cox' },
      { label: 'December Avenue', value: 'December Avenue' },
      { label: 'Declan Mckenna', value: 'Declan Mckenna' },
      { label: 'Deep Purple', value: 'Deep Purple' },
      { label: 'Def Leppard', value: 'Def Leppard' },
      { label: 'Deftones', value: 'Deftones' },
      { label: 'Dej Loaf', value: 'Dej Loaf' },
      { label: 'Delacruz', value: 'Delacruz' },
      { label: 'Demi Lovato', value: 'Demi Lovato' },
      { label: 'Deniece Williams', value: 'Deniece Williams' },
      { label: 'Dennis', value: 'Dennis' },
      { label: 'Dennis Lloyd', value: 'Dennis Lloyd' },
      { label: 'Denzel Curry', value: 'Denzel Curry' },
      { label: 'Deorro', value: 'Deorro' },
      { label: 'Depeche Mode', value: 'Depeche Mode' },
      { label: 'Derek & The Dominos', value: 'Derek & The Dominos' },
      { label: 'Destinys Child', value: 'Destinys Child' },
      { label: 'Dev', value: 'Dev' },
      { label: 'Devi Sri Prasad', value: 'Devi Sri Prasad' },
      { label: 'Devo', value: 'Devo' },
      { label: 'Dewa 19', value: 'Dewa 19' },
      { label: 'Dhanush', value: 'Dhanush' },
      { label: 'Diamond Rio', value: 'Diamond Rio' },
      { label: 'Diana Krall', value: 'Diana Krall' },
      { label: 'Diana Ross', value: 'Diana Ross' },
      { label: 'Diddy', value: 'Diddy' },
      { label: 'Dido', value: 'Dido' },
      { label: 'Die Antwoord', value: 'Die Antwoord' },
      { label: 'Die Toten Hosen', value: 'Die Toten Hosen' },
      { label: 'Diego & Victor Hugo', value: 'Diego & Victor Hugo' },
      { label: 'Diego Torres', value: 'Diego Torres' },
      { label: 'Diego Verdaguer', value: 'Diego Verdaguer' },
      { label: 'Dierks Bentley', value: 'Dierks Bentley' },
      { label: 'Digital Farm Animals', value: 'Digital Farm Animals' },
      { label: 'Digital Underground', value: 'Digital Underground' },
      { label: 'Dillon Francis', value: 'Dillon Francis' },
      { label: 'Dimelo Flow', value: 'Dimelo Flow' },
      { label: 'Dimitri Vegas', value: 'Dimitri Vegas' },
      {
        label: 'Dimitri Vegas & Like Mike',
        value: 'Dimitri Vegas & Like Mike',
      },
      { label: 'Dinos', value: 'Dinos' },
      { label: 'Dio', value: 'Dio' },
      { label: 'Dionne Warwick', value: 'Dionne Warwick' },
      { label: 'Diplo', value: 'Diplo' },
      { label: 'Dire Straits', value: 'Dire Straits' },
      { label: 'Dirty Heads', value: 'Dirty Heads' },
      { label: 'Disciples', value: 'Disciples' },
      { label: 'Disclosure', value: 'Disclosure' },
      { label: 'Disney', value: 'Disney' },
      { label: 'Disturbed', value: 'Disturbed' },
      { label: 'Divine', value: 'Divine' },
      { label: 'Dizzee Rascal', value: 'Dizzee Rascal' },
      { label: 'DJ Antoine', value: 'DJ Antoine' },
      { label: 'DJ Clue', value: 'DJ Clue' },
      { label: 'DJ Guuga', value: 'DJ Guuga' },
      {
        label: 'DJ Jazzy Jeff & Fresh Prince',
        value: 'DJ Jazzy Jeff & Fresh Prince',
      },
      { label: 'DJ Khaled', value: 'DJ Khaled' },
      { label: 'DJ Luian', value: 'DJ Luian' },
      { label: 'DJ Magic Mike', value: 'DJ Magic Mike' },
      { label: 'DJ Nelson', value: 'DJ Nelson' },
      { label: 'DJ Quik', value: 'DJ Quik' },
      { label: 'DJ Snake', value: 'DJ Snake' },
      { label: 'Djadja & Dinaz', value: 'Djadja & Dinaz' },
      { label: 'Djavan', value: 'Djavan' },
      { label: 'Dmx', value: 'Dmx' },
      { label: 'Dnce', value: 'Dnce' },
      { label: 'Do Or Die', value: 'Do Or Die' },
      { label: 'Doja Cat', value: 'Doja Cat' },
      { label: 'Dokken', value: 'Dokken' },
      { label: 'Dolly Parton', value: 'Dolly Parton' },
      { label: 'Dominic Fike', value: 'Dominic Fike' },
      { label: 'Don Diablo', value: 'Don Diablo' },
      { label: 'Don Henley', value: 'Don Henley' },
      { label: 'Don McLean', value: 'Don McLean' },
      { label: 'Don Omar', value: 'Don Omar' },
      { label: 'Don Patricio', value: 'Don Patricio' },
      { label: 'Don Williams', value: 'Don Williams' },
      { label: 'Donald Fagen', value: 'Donald Fagen' },
      { label: 'Donell Jones', value: 'Donell Jones' },
      { label: 'Donna Lewis', value: 'Donna Lewis' },
      { label: 'Donna Summer', value: 'Donna Summer' },
      { label: 'Donny & Marie Osmond', value: 'Donny & Marie Osmond' },
      { label: 'Donny Osmond', value: 'Donny Osmond' },
      { label: 'Donovan', value: 'Donovan' },
      { label: 'Doug Stone', value: 'Doug Stone' },
      { label: 'Dr. Dog', value: 'Dr. Dog' },
      { label: 'Dr. Dre', value: 'Dr. Dre' },
      { label: 'Drake', value: 'Drake' },
      { label: 'Dram', value: 'Dram' },
      { label: 'Dread Mar I', value: 'Dread Mar I' },
      { label: 'Dropkick Murphys', value: 'Dropkick Murphys' },
      { label: 'Drowning Pool', value: 'Drowning Pool' },
      { label: 'Dru Hill', value: 'Dru Hill' },
      { label: 'Dua Lipa', value: 'Dua Lipa' },
      { label: 'Duke Dumont', value: 'Duke Dumont' },
      { label: 'Dulce Maria', value: 'Dulce Maria' },
      { label: 'Duman', value: 'Duman' },
      { label: 'Duncan Laurence', value: 'Duncan Laurence' },
      { label: 'Duran Duran', value: 'Duran Duran' },
      { label: 'Dustin Lynch', value: 'Dustin Lynch' },
      { label: 'Dusty Springfield', value: 'Dusty Springfield' },
      { label: 'Dvbbs', value: 'Dvbbs' },
      { label: 'Dwight Yoakam', value: 'Dwight Yoakam' },
      { label: 'Dylan Scott', value: 'Dylan Scott' },
      { label: 'E-40', value: 'E-40' },
      { label: 'Eagle Eye Cherry', value: 'Eagle Eye Cherry' },
      { label: 'Eagles', value: 'Eagles' },
      { label: 'Earl Sweatshirt', value: 'Earl Sweatshirt' },
      { label: 'Eason Chan', value: 'Eason Chan' },
      { label: 'Eazy-E', value: 'Eazy-E' },
      { label: 'Echosmith', value: 'Echosmith' },
      { label: 'Ed Ames', value: 'Ed Ames' },
      { label: 'Ed Maverick', value: 'Ed Maverick' },
      { label: 'Ed Sheeran', value: 'Ed Sheeran' },
      { label: 'Eddie Money', value: 'Eddie Money' },
      { label: 'Eddie Murphy', value: 'Eddie Murphy' },
      { label: 'Eddie Rabbitt', value: 'Eddie Rabbitt' },
      { label: 'Eddie Vedder', value: 'Eddie Vedder' },
      { label: 'Eddy Arnold', value: 'Eddy Arnold' },
      { label: 'Eden', value: 'Eden' },
      { label: 'Eden Munoz', value: 'Eden Munoz' },
      { label: 'Edgar Winter', value: 'Edgar Winter' },
      { label: 'Edgar Winter Group', value: 'Edgar Winter Group' },
      { label: 'Edgardo Nunez', value: 'Edgardo Nunez' },
      {
        label: 'Edie Brickell & New Bohemians',
        value: 'Edie Brickell & New Bohemians',
      },
      { label: 'Eduardo Costa', value: 'Eduardo Costa' },
      {
        label: 'Edward Sharpe & The Magnetic Zeros',
        value: 'Edward Sharpe & The Magnetic Zeros',
      },
      {
        label: 'Edwin Luna Y La Trakalosa De Monterrey',
        value: 'Edwin Luna Y La Trakalosa De Monterrey',
      },
      { label: 'Edwin Mccain', value: 'Edwin Mccain' },
      { label: 'Eiffel 65', value: 'Eiffel 65' },
      { label: 'El Alfa', value: 'El Alfa' },
      { label: 'El Canto Del Loco', value: 'El Canto Del Loco' },
      { label: 'El Cuarteto De Nos', value: 'El Cuarteto De Nos' },
      { label: 'El Fantasma', value: 'El Fantasma' },
      { label: 'El Guincho', value: 'El Guincho' },
      { label: 'El Komander', value: 'El Komander' },
      { label: 'El Makabelico', value: 'El Makabelico' },
      { label: 'El-P', value: 'El-P' },
      { label: 'Eladio Carrion', value: 'Eladio Carrion' },
      { label: 'Ele A El Dominio', value: 'Ele A El Dominio' },
      { label: 'Electric Light Orchestra', value: 'Electric Light Orchestra' },
      { label: 'Elefante', value: 'Elefante' },
      { label: 'Elevation Worship', value: 'Elevation Worship' },
      { label: 'Elisa', value: 'Elisa' },
      { label: 'Ella Eyre', value: 'Ella Eyre' },
      { label: 'Ella Fitzgerald', value: 'Ella Fitzgerald' },
      { label: 'Ella Henderson', value: 'Ella Henderson' },
      { label: 'Ella Mai', value: 'Ella Mai' },
      { label: 'Elle King', value: 'Elle King' },
      { label: 'Ellie Goulding', value: 'Ellie Goulding' },
      { label: 'Elodie', value: 'Elodie' },
      { label: 'Elton John', value: 'Elton John' },
      { label: 'Elvis Costello', value: 'Elvis Costello' },
      { label: 'Elvis Crespo', value: 'Elvis Crespo' },
      { label: 'Elvis Presley', value: 'Elvis Presley' },
      { label: 'Em Beihold', value: 'Em Beihold' },
      { label: 'Emeli Sande', value: 'Emeli Sande' },
      { label: 'Emf', value: 'Emf' },
      { label: 'Emilia', value: 'Emilia' },
      { label: 'Emily Warren', value: 'Emily Warren' },
      { label: 'Eminem', value: 'Eminem' },
      { label: 'Emir Can __Rek', value: 'Emir Can __Rek' },
      { label: 'Emmanuel', value: 'Emmanuel' },
      { label: 'Emmylou Harris', value: 'Emmylou Harris' },
      { label: 'Empire Of The Sun', value: 'Empire Of The Sun' },
      { label: 'En Vogue', value: 'En Vogue' },
      { label: 'Engelbert Humperdinck', value: 'Engelbert Humperdinck' },
      { label: 'Engenheiros Do Hawaii', value: 'Engenheiros Do Hawaii' },
      { label: 'Enhypen', value: 'Enhypen' },
      { label: 'Enigma', value: 'Enigma' },
      { label: 'Enigma Norteno', value: 'Enigma Norteno' },
      { label: 'Enjambre', value: 'Enjambre' },
      { label: 'Ennio Morricone', value: 'Ennio Morricone' },
      { label: 'Enrique Iglesias', value: 'Enrique Iglesias' },
      { label: 'Enya', value: 'Enya' },
      { label: 'Epmd', value: 'Epmd' },
      { label: 'Eraserheads', value: 'Eraserheads' },
      { label: 'Erasure', value: 'Erasure' },
      { label: 'Eric B. & Rakim', value: 'Eric B. & Rakim' },
      { label: 'Eric Bellinger', value: 'Eric Bellinger' },
      { label: 'Eric Chou', value: 'Eric Chou' },
      { label: 'Eric Church', value: 'Eric Church' },
      { label: 'Eric Clapton', value: 'Eric Clapton' },
      { label: 'Eric Johnson', value: 'Eric Johnson' },
      { label: 'Eric Prydz', value: 'Eric Prydz' },
      { label: 'Erik Satie', value: 'Erik Satie' },
      { label: 'Ernia', value: 'Ernia' },
      { label: 'Ernie Ford', value: 'Ernie Ford' },
      { label: 'Eros Ramazzotti', value: 'Eros Ramazzotti' },
      { label: 'Erykah Badu', value: 'Erykah Badu' },
      { label: 'Escape The Fate', value: 'Escape The Fate' },
      { label: 'Espinoza Paz', value: 'Espinoza Paz' },
      { label: 'Est Gee', value: 'Est Gee' },
      { label: 'Estelle', value: 'Estelle' },
      { label: 'Estopa', value: 'Estopa' },
      { label: 'Etta James', value: 'Etta James' },
      { label: 'Eugene Ormandy', value: 'Eugene Ormandy' },
      { label: 'Europe', value: 'Europe' },
      { label: 'Eurythmics', value: 'Eurythmics' },
      { label: 'Eva Cassidy', value: 'Eva Cassidy' },
      { label: 'Evanescence', value: 'Evanescence' },
      { label: 'Eve', value: 'Eve' },
      { label: 'Eve 6', value: 'Eve 6' },
      { label: 'Everclear', value: 'Everclear' },
      { label: 'Everlast', value: 'Everlast' },
      { label: 'Exile', value: 'Exile' },
      { label: 'Exo', value: 'Exo' },
      { label: 'Expose', value: 'Expose' },
      { label: 'Extreme Ii', value: 'Extreme Ii' },
      { label: 'Extremoduro', value: 'Extremoduro' },
      { label: 'Fabolous', value: 'Fabolous' },
      { label: 'Fabri Fibra', value: 'Fabri Fibra' },
      { label: 'Faith Evans', value: 'Faith Evans' },
      { label: 'Faith Hill', value: 'Faith Hill' },
      { label: 'Faith No More', value: 'Faith No More' },
      { label: 'Fall Out Boy', value: 'Fall Out Boy' },
      { label: 'Falling In Reverse', value: 'Falling In Reverse' },
      { label: 'Famous Dex', value: 'Famous Dex' },
      { label: 'Fantasia', value: 'Fantasia' },
      { label: 'Far East Movement', value: 'Far East Movement' },
      { label: 'Farid Bang', value: 'Farid Bang' },
      { label: 'Farruko', value: 'Farruko' },
      {
        label: 'Fast & Furious: The Fast Saga',
        value: 'Fast & Furious: The Fast Saga',
      },
      { label: 'Fat Boys', value: 'Fat Boys' },
      { label: 'Fat Joe', value: 'Fat Joe' },
      { label: 'Fatboy Slim', value: 'Fatboy Slim' },
      { label: 'Father John Misty', value: 'Father John Misty' },
      { label: 'Felipe Amorim', value: 'Felipe Amorim' },
      { label: 'Felipe Araujo', value: 'Felipe Araujo' },
      { label: 'Felix Jaehn', value: 'Felix Jaehn' },
      { label: 'Fergie', value: 'Fergie' },
      { label: 'Fernandinho', value: 'Fernandinho' },
      { label: 'Fetty Wap', value: 'Fetty Wap' },
      { label: 'Fifth Dimension', value: 'Fifth Dimension' },
      { label: 'Fifth Harmony', value: 'Fifth Harmony' },
      { label: 'Filipe Ret', value: 'Filipe Ret' },
      { label: 'Filter', value: 'Filter' },
      { label: 'Finch', value: 'Finch' },
      { label: 'Fine Young Cannibals', value: 'Fine Young Cannibals' },
      { label: 'Finger Eleven', value: 'Finger Eleven' },
      { label: 'Fiona Apple', value: 'Fiona Apple' },
      { label: 'Firefall', value: 'Firefall' },
      { label: 'Firehouse', value: 'Firehouse' },
      { label: 'First Aid Kit', value: 'First Aid Kit' },
      { label: 'Fisher', value: 'Fisher' },
      { label: 'Fito Y Fitipaldis', value: 'Fito Y Fitipaldis' },
      { label: 'Fitz And The Tantrums', value: 'Fitz And The Tantrums' },
      { label: 'Five', value: 'Five' },
      { label: 'Five Finger Death Punch', value: 'Five Finger Death Punch' },
      { label: 'Five For Fighting', value: 'Five For Fighting' },
      { label: 'Fivio Foreign', value: 'Fivio Foreign' },
      { label: 'Fka Twigs', value: 'Fka Twigs' },
      { label: 'Fkj', value: 'Fkj' },
      { label: 'Flatbush Zombies', value: 'Flatbush Zombies' },
      { label: 'Fleet Foxes', value: 'Fleet Foxes' },
      { label: 'Fleetwood Mac', value: 'Fleetwood Mac' },
      { label: 'Fletcher', value: 'Fletcher' },
      { label: 'Flo Milli', value: 'Flo Milli' },
      { label: 'Flo Rida', value: 'Flo Rida' },
      { label: 'Florence + The Machine', value: 'Florence + The Machine' },
      { label: 'Florida Georgia Line', value: 'Florida Georgia Line' },
      { label: 'Flume', value: 'Flume' },
      { label: 'Flux Pavilion', value: 'Flux Pavilion' },
      { label: 'Flyleaf', value: 'Flyleaf' },
      { label: 'Foals', value: 'Foals' },
      { label: 'Focus', value: 'Focus' },
      { label: 'Foghat', value: 'Foghat' },
      { label: 'Foo Fighters', value: 'Foo Fighters' },
      { label: 'For King & Country', value: 'For King & Country' },
      { label: 'Foreigner', value: 'Foreigner' },
      { label: 'Fort Minor', value: 'Fort Minor' },
      { label: 'Foster The People', value: 'Foster The People' },
      { label: 'Four Tops', value: 'Four Tops' },
      { label: 'Fourplay', value: 'Fourplay' },
      { label: 'Foxes', value: 'Foxes' },
      { label: 'Foxy Brown', value: 'Foxy Brown' },
      { label: 'Frances Lai', value: 'Frances Lai' },
      { label: 'Francesca Battistelli', value: 'Francesca Battistelli' },
      { label: 'Franco De Vita', value: 'Franco De Vita' },
      { label: 'Frank Ocean', value: 'Frank Ocean' },
      { label: 'Frank Sinatra', value: 'Frank Sinatra' },
      { label: 'Frankie J', value: 'Frankie J' },
      { label: 'Frankie Ruiz', value: 'Frankie Ruiz' },
      {
        label: 'Frankie Valli & The Four Seasons',
        value: 'Frankie Valli & The Four Seasons',
      },
      { label: 'Franz Ferdinand', value: 'Franz Ferdinand' },
      { label: 'Fred Again..', value: 'Fred Again..' },
      { label: 'Fred De Palma', value: 'Fred De Palma' },
      { label: 'Fred Hammond', value: 'Fred Hammond' },
      { label: 'Freddie Dredd', value: 'Freddie Dredd' },
      { label: 'Freddie Gibbs', value: 'Freddie Gibbs' },
      { label: 'Freddie Jackson', value: 'Freddie Jackson' },
      { label: 'Frederic Chopin', value: 'Frederic Chopin' },
      { label: 'French Montana', value: 'French Montana' },
      { label: 'Freya Ridings', value: 'Freya Ridings' },
      { label: 'Fuego', value: 'Fuego' },
      { label: 'Fuel', value: 'Fuel' },
      { label: 'Fugees', value: 'Fugees' },
      { label: 'Fujii Kaze', value: 'Fujii Kaze' },
      { label: 'Fun.', value: 'Fun.' },
      { label: 'Funkadelic', value: 'Funkadelic' },
      { label: 'Funkmaster Flex', value: 'Funkmaster Flex' },
      { label: 'Fuse Odg', value: 'Fuse Odg' },
      { label: 'Future', value: 'Future' },
      { label: 'Future Islands', value: 'Future Islands' },
      { label: 'G Herbo', value: 'G Herbo' },
      { label: 'G-Eazy', value: 'G-Eazy' },
      { label: 'G-Unit', value: 'G-Unit' },
      { label: 'G. V. Prakash', value: 'G. V. Prakash' },
      { label: 'G.E.M.', value: 'G.E.M.' },
      { label: 'Gabby Barrett', value: 'Gabby Barrett' },
      { label: 'Gabriela Rocha', value: 'Gabriela Rocha' },
      { label: 'Gabrielle Aplin', value: 'Gabrielle Aplin' },
      { label: 'Gabry Ponte', value: 'Gabry Ponte' },
      { label: 'Galantis', value: 'Galantis' },
      { label: 'Gang Starr', value: 'Gang Starr' },
      { label: 'Gap Band', value: 'Gap Band' },
      { label: 'Garbage', value: 'Garbage' },
      { label: 'Garth Brooks', value: 'Garth Brooks' },
      { label: 'Gary Allan', value: 'Gary Allan' },
      {
        label: 'Gary Puckett & The Union Gap',
        value: 'Gary Puckett & The Union Gap',
      },
      { label: 'Gary Wright', value: 'Gary Wright' },
      { label: 'Gashi', value: 'Gashi' },
      { label: 'Gavin Degraw', value: 'Gavin Degraw' },
      { label: 'Gavin James', value: 'Gavin James' },
      { label: 'Gayle', value: 'Gayle' },
      { label: 'Gazo', value: 'Gazo' },
      { label: 'Gene Simmons', value: 'Gene Simmons' },
      { label: 'Genesis', value: 'Genesis' },
      { label: 'Gente De Zona', value: 'Gente De Zona' },
      { label: 'Geolier', value: 'Geolier' },
      { label: 'George Benson', value: 'George Benson' },
      { label: 'George Carlin', value: 'George Carlin' },
      { label: 'George Ezra', value: 'George Ezra' },
      { label: 'George Harrison', value: 'George Harrison' },
      { label: 'George Jones', value: 'George Jones' },
      { label: 'George Michael', value: 'George Michael' },
      { label: 'George Strait', value: 'George Strait' },
      {
        label: 'George Thorogood & The Destroyers',
        value: 'George Thorogood & The Destroyers',
      },
      { label: 'George Winston', value: 'George Winston' },
      { label: 'Georgia Ku', value: 'Georgia Ku' },
      { label: 'Georgia Satellites', value: 'Georgia Satellites' },
      { label: 'Gera Mx', value: 'Gera Mx' },
      { label: 'Gerald Levert', value: 'Gerald Levert' },
      { label: 'Gerardo Ortiz', value: 'Gerardo Ortiz' },
      { label: 'Gerry Rafferty', value: 'Gerry Rafferty' },
      { label: 'Gesaffelstein', value: 'Gesaffelstein' },
      { label: 'Geto Boys', value: 'Geto Boys' },
      { label: 'Ghost', value: 'Ghost' },
      { label: 'Ghostface Killah', value: 'Ghostface Killah' },
      { label: 'Gibbs', value: 'Gibbs' },
      { label: 'Giggs', value: 'Giggs' },
      { label: 'Gigi Dagostino', value: 'Gigi Dagostino' },
      { label: 'Gilberto Gil', value: 'Gilberto Gil' },
      { label: 'Gilberto Santa Rosa', value: 'Gilberto Santa Rosa' },
      { label: 'Gilli', value: 'Gilli' },
      { label: 'Gin Blossoms', value: 'Gin Blossoms' },
      { label: 'Gino Vannelli', value: 'Gino Vannelli' },
      { label: 'Ginuwine', value: 'Ginuwine' },
      { label: 'Gipsy Kings', value: 'Gipsy Kings' },
      { label: 'Girl In Red', value: 'Girl In Red' },
      { label: 'Girls Generation', value: 'Girls Generation' },
      { label: 'Giulia Be', value: 'Giulia Be' },
      { label: 'Gladys Knight & The Pips', value: 'Gladys Knight & The Pips' },
      { label: 'Glass Animals', value: 'Glass Animals' },
      { label: 'Glee Cast', value: 'Glee Cast' },
      { label: 'Glen Campbell', value: 'Glen Campbell' },
      { label: 'Glenn Fredly', value: 'Glenn Fredly' },
      { label: 'Glenn Frey', value: 'Glenn Frey' },
      { label: 'Glenn Miller Orchestra', value: 'Glenn Miller Orchestra' },
      { label: 'Gloria Estefan', value: 'Gloria Estefan' },
      { label: 'Gloria Gaynor', value: 'Gloria Gaynor' },
      { label: 'Gloria Trevi', value: 'Gloria Trevi' },
      { label: 'Gnarls Barkley', value: 'Gnarls Barkley' },
      { label: 'Gnash', value: 'Gnash' },
      { label: 'Godsmack', value: 'Godsmack' },
      { label: 'Gojira', value: 'Gojira' },
      { label: 'Goldlink', value: 'Goldlink' },
      { label: 'Good Charlotte', value: 'Good Charlotte' },
      { label: 'Goodboys', value: 'Goodboys' },
      { label: 'Goodie Mob', value: 'Goodie Mob' },
      { label: 'Gordon Lightfoot', value: 'Gordon Lightfoot' },
      { label: 'Gordon Macrae', value: 'Gordon Macrae' },
      { label: 'Gorgon City', value: 'Gorgon City' },
      { label: 'Gorillaz', value: 'Gorillaz' },
      { label: 'Got7', value: 'Got7' },
      { label: 'Gotye', value: 'Gotye' },
      { label: 'Gracie Abrams', value: 'Gracie Abrams' },
      { label: 'Grand Funk Railroad', value: 'Grand Funk Railroad' },
      { label: 'Grateful Dead', value: 'Grateful Dead' },
      { label: 'Great White', value: 'Great White' },
      { label: 'Green Day', value: 'Green Day' },
      { label: 'Gregory Alan Isakov', value: 'Gregory Alan Isakov' },
      { label: 'Gregory Porter', value: 'Gregory Porter' },
      { label: 'Greta Van Fleet', value: 'Greta Van Fleet' },
      { label: 'Gretchen Wilson', value: 'Gretchen Wilson' },
      { label: 'Grey', value: 'Grey' },
      { label: 'Grimes', value: 'Grimes' },
      { label: 'Grouplove', value: 'Grouplove' },
      { label: 'Grupo Codiciado', value: 'Grupo Codiciado' },
      { label: 'Grupo Frontera', value: 'Grupo Frontera' },
      { label: 'Grupo Montez De Durango', value: 'Grupo Montez De Durango' },
      { label: 'Grupo Niche', value: 'Grupo Niche' },
      { label: 'Grupo Revelacao', value: 'Grupo Revelacao' },
      { label: 'Gucci Mane', value: 'Gucci Mane' },
      { label: 'Gue', value: 'Gue' },
      { label: 'Guess Who', value: 'Guess Who' },
      { label: "Guns N' Roses", value: "Guns N' Roses" },
      { label: 'Guru Randhawa', value: 'Guru Randhawa' },
      { label: 'Gus Dapperton', value: 'Gus Dapperton' },
      { label: 'Gustavo Cerati', value: 'Gustavo Cerati' },
      { label: 'Gustavo Mioto', value: 'Gustavo Mioto' },
      { label: 'Gusttavo Lima', value: 'Gusttavo Lima' },
      { label: 'Guy', value: 'Guy' },
      { label: 'Guy Sebastian', value: 'Guy Sebastian' },
      { label: 'Gwen Stefani', value: 'Gwen Stefani' },
      { label: 'Gym Class Heroes', value: 'Gym Class Heroes' },
      { label: 'Gza', value: 'Gza' },
      { label: 'Gzuz', value: 'Gzuz' },
      {
        label: 'H. Alpert & The Tijuana Brass',
        value: 'H. Alpert & The Tijuana Brass',
      },
      { label: 'H.E.R.', value: 'H.E.R.' },
      { label: 'Haddaway', value: 'Haddaway' },
      { label: 'Hailee Steinfeld', value: 'Hailee Steinfeld' },
      { label: 'Haim', value: 'Haim' },
      { label: 'Hakan Hellstrom', value: 'Hakan Hellstrom' },
      { label: 'Halestorm', value: 'Halestorm' },
      { label: 'Halsey', value: 'Halsey' },
      { label: 'Hammer', value: 'Hammer' },
      { label: 'Hamza', value: 'Hamza' },
      { label: 'Hank Williams', value: 'Hank Williams' },
      { label: 'Hannah Montana', value: 'Hannah Montana' },
      { label: 'Hans Zimmer', value: 'Hans Zimmer' },
      { label: 'Hanson', value: 'Hanson' },
      { label: 'Hardwell', value: 'Hardwell' },
      { label: 'Hardy', value: 'Hardy' },
      {
        label: 'Harold Melvin & The Blue Notes',
        value: 'Harold Melvin & The Blue Notes',
      },
      { label: 'Harris & Ford', value: 'Harris & Ford' },
      { label: 'Harris Jayaraj', value: 'Harris Jayaraj' },
      { label: 'Harry Belafonte', value: 'Harry Belafonte' },
      { label: 'Harry Chapin', value: 'Harry Chapin' },
      { label: 'Harry Styles', value: 'Harry Styles' },
      { label: 'Hawthorne Heights', value: 'Hawthorne Heights' },
      { label: 'Hayden James', value: 'Hayden James' },
      { label: 'Hayley Williams', value: 'Hayley Williams' },
      { label: 'Haze', value: 'Haze' },
      { label: 'Headie One', value: 'Headie One' },
      { label: 'Heart', value: 'Heart' },
      { label: 'Heather Headley', value: 'Heather Headley' },
      { label: 'Heatwave', value: 'Heatwave' },
      { label: 'Heavy D & The Boyz', value: 'Heavy D & The Boyz' },
      { label: 'Hector El Father', value: 'Hector El Father' },
      { label: 'Hector Lavoe', value: 'Hector Lavoe' },
      { label: 'Helen Reddy', value: 'Helen Reddy' },
      { label: 'Helene Fischer', value: 'Helene Fischer' },
      { label: 'Henrique & Diego', value: 'Henrique & Diego' },
      { label: 'Henrique & Juliano', value: 'Henrique & Juliano' },
      { label: 'Henry Mancini', value: 'Henry Mancini' },
      { label: 'Herb Alpert', value: 'Herb Alpert' },
      { label: 'Herbie Hancock', value: 'Herbie Hancock' },
      { label: 'Herencia De Patrones', value: 'Herencia De Patrones' },
      { label: "Herman's Hermits", value: "Herman's Hermits" },
      { label: 'Heroes Del Silencio', value: 'Heroes Del Silencio' },
      { label: 'Hi-Five', value: 'Hi-Five' },
      { label: 'High School Musical Cast', value: 'High School Musical Cast' },
      { label: 'Hikaru Utada', value: 'Hikaru Utada' },
      { label: 'Hilary Duff', value: 'Hilary Duff' },
      { label: 'Hillsong United', value: 'Hillsong United' },
      { label: 'Hillsong Worship', value: 'Hillsong Worship' },
      { label: 'Hillsong Young & Free', value: 'Hillsong Young & Free' },
      { label: 'Hilltop Hoods', value: 'Hilltop Hoods' },
      { label: 'Hinder', value: 'Hinder' },
      { label: 'Hippie Sabotage', value: 'Hippie Sabotage' },
      { label: 'Hiroshima', value: 'Hiroshima' },
      { label: 'Hiroyuki Sawano', value: 'Hiroyuki Sawano' },
      { label: 'Hole', value: 'Hole' },
      { label: 'Hollywood Undead', value: 'Hollywood Undead' },
      { label: 'Hombres G', value: 'Hombres G' },
      { label: 'Honne', value: 'Honne' },
      { label: 'Hoobastank', value: 'Hoobastank' },
      { label: 'Hootie & The Blowfish', value: 'Hootie & The Blowfish' },
      { label: 'Hopsin', value: 'Hopsin' },
      { label: 'Hornet La Frappe', value: 'Hornet La Frappe' },
      { label: 'Hot Boys', value: 'Hot Boys' },
      { label: 'Hotel Ugly', value: 'Hotel Ugly' },
      { label: 'House Of Pain', value: 'House Of Pain' },
      { label: 'Howard Jones', value: 'Howard Jones' },
      { label: 'Howie Day', value: 'Howie Day' },
      { label: 'Hozier', value: 'Hozier' },
      { label: 'Huey Lewis & The News', value: 'Huey Lewis & The News' },
      { label: 'Hugo & Guilherme', value: 'Hugo & Guilherme' },
      { label: 'Humble Pie', value: 'Humble Pie' },
      { label: 'Hunter Hayes', value: 'Hunter Hayes' },
      { label: 'Hurts', value: 'Hurts' },
      { label: 'I Prevail', value: 'I Prevail' },
      { label: 'Iann Dior', value: 'Iann Dior' },
      { label: 'Ice Cube', value: 'Ice Cube' },
      { label: 'Ice Nine Kills', value: 'Ice Nine Kills' },
      { label: 'Ice Spice', value: 'Ice Spice' },
      { label: 'Ice-T', value: 'Ice-T' },
      { label: 'Icona Pop', value: 'Icona Pop' },
      { label: 'Idina Menzel', value: 'Idina Menzel' },
      { label: 'Iggy Azalea', value: 'Iggy Azalea' },
      { label: 'Iggy Pop', value: 'Iggy Pop' },
      { label: 'Ikon', value: 'Ikon' },
      { label: 'Il Divo', value: 'Il Divo' },
      { label: 'Illenium', value: 'Illenium' },
      { label: 'Ilovemakonnen', value: 'Ilovemakonnen' },
      { label: 'Imagine Dragons', value: 'Imagine Dragons' },
      { label: 'In Flames', value: 'In Flames' },
      { label: 'In This Moment', value: 'In This Moment' },
      { label: 'Ina Wroldsen', value: 'Ina Wroldsen' },
      { label: 'Incubus', value: 'Incubus' },
      { label: 'India Martinez', value: 'India Martinez' },
      { label: 'Indigo Girls', value: 'Indigo Girls' },
      { label: 'Indila', value: 'Indila' },
      { label: 'Ingrid Michaelson', value: 'Ingrid Michaelson' },
      { label: 'Inna', value: 'Inna' },
      { label: 'Inner Circle', value: 'Inner Circle' },
      { label: 'Insane Clown Posse', value: 'Insane Clown Posse' },
      { label: 'Inspector', value: 'Inspector' },
      { label: 'Internet Money', value: 'Internet Money' },
      { label: 'Interpol', value: 'Interpol' },
      { label: 'Intocable', value: 'Intocable' },
      { label: 'Inxs', value: 'Inxs' },
      { label: 'Iron & Wine', value: 'Iron & Wine' },
      { label: 'Iron Butterfly', value: 'Iron Butterfly' },
      { label: 'Iron Maiden', value: 'Iron Maiden' },
      { label: 'Isaac Hayes', value: 'Isaac Hayes' },
      { label: 'Isabel Larosa', value: 'Isabel Larosa' },
      { label: 'Isadora Pompeo', value: 'Isadora Pompeo' },
      { label: 'Isaiah Rashad', value: 'Isaiah Rashad' },
      { label: 'Israel & New Breed', value: 'Israel & New Breed' },
      { label: 'Israel & Rodolffo', value: 'Israel & Rodolffo' },
      { label: "Israel Kamakawiwo'Ole", value: "Israel Kamakawiwo'Ole" },
      { label: 'Itzy', value: 'Itzy' },
      { label: 'Iu', value: 'Iu' },
      { label: 'Ivan Cornejo', value: 'Ivan Cornejo' },
      { label: 'Ive', value: 'Ive' },
      { label: 'Ivete Sangalo', value: 'Ivete Sangalo' },
      { label: 'Iyaz', value: 'Iyaz' },
      { label: 'Izi', value: 'Izi' },
      { label: 'J Alvarez', value: 'J Alvarez' },
      { label: 'J Balvin', value: 'J Balvin' },
      { label: 'J-Hope', value: 'J-Hope' },
      { label: 'J. Cole', value: 'J. Cole' },
      { label: 'J. Geils Band', value: 'J. Geils Band' },
      { label: 'Ja Rule', value: 'Ja Rule' },
      { label: 'Jaci Velasquez', value: 'Jaci Velasquez' },
      { label: 'Jack & Jack', value: 'Jack & Jack' },
      { label: 'Jack Harlow', value: 'Jack Harlow' },
      { label: 'Jack Johnson', value: 'Jack Johnson' },
      { label: 'Jack Stauber', value: 'Jack Stauber' },
      { label: 'Jack Staubers Micropop', value: 'Jack Staubers Micropop' },
      { label: 'Jack U', value: 'Jack U' },
      { label: 'Jackie Evancho', value: 'Jackie Evancho' },
      { label: 'Jackie Gleason', value: 'Jackie Gleason' },
      { label: 'Jackson Browne', value: 'Jackson Browne' },
      { label: 'Jackson Wang', value: 'Jackson Wang' },
      { label: 'Jackyl', value: 'Jackyl' },
      { label: 'Jadakiss', value: 'Jadakiss' },
      { label: 'Jade', value: 'Jade' },
      { label: 'Jaden', value: 'Jaden' },
      { label: 'Jagged Edge', value: 'Jagged Edge' },
      { label: 'Jaheim', value: 'Jaheim' },
      { label: 'Jain', value: 'Jain' },
      { label: 'Jake Owen', value: 'Jake Owen' },
      { label: 'James Arthur', value: 'James Arthur' },
      { label: 'James Bay', value: 'James Bay' },
      { label: 'James Blake', value: 'James Blake' },
      { label: 'James Blunt', value: 'James Blunt' },
      { label: 'James Brown', value: 'James Brown' },
      { label: 'James Carter', value: 'James Carter' },
      { label: 'James Gang', value: 'James Gang' },
      { label: 'James Ingram', value: 'James Ingram' },
      { label: 'James Morrison', value: 'James Morrison' },
      { label: 'James Newton Howard', value: 'James Newton Howard' },
      { label: 'James Taylor', value: 'James Taylor' },
      { label: 'James Tw', value: 'James Tw' },
      { label: 'Jamey Johnson', value: 'Jamey Johnson' },
      { label: 'Jamie Foxx', value: 'Jamie Foxx' },
      { label: 'Jamie Xx', value: 'Jamie Xx' },
      { label: 'Jamiroquai', value: 'Jamiroquai' },
      { label: 'Jane Fonda', value: 'Jane Fonda' },
      { label: "Jane's Addiction", value: "Jane's Addiction" },
      { label: 'Janelle Monae', value: 'Janelle Monae' },
      { label: 'Janet Jackson', value: 'Janet Jackson' },
      { label: 'Janieck', value: 'Janieck' },
      { label: 'Janis Ian', value: 'Janis Ian' },
      { label: 'Janis Joplin', value: 'Janis Joplin' },
      { label: 'Jarabe De Palo', value: 'Jarabe De Palo' },
      { label: 'Jars Of Clay', value: 'Jars Of Clay' },
      { label: 'Jasmine Thompson', value: 'Jasmine Thompson' },
      { label: 'Jason Aldean', value: 'Jason Aldean' },
      { label: 'Jason Derulo', value: 'Jason Derulo' },
      { label: 'Jason Mraz', value: 'Jason Mraz' },
      { label: 'Javed Ali', value: 'Javed Ali' },
      {
        label: 'Javier Rosas Y Su Artilleria Pesada',
        value: 'Javier Rosas Y Su Artilleria Pesada',
      },
      { label: 'Jax Jones', value: 'Jax Jones' },
      { label: 'Jay Chou', value: 'Jay Chou' },
      { label: 'Jay Park', value: 'Jay Park' },
      { label: 'Jay Rock', value: 'Jay Rock' },
      { label: 'Jay Sean', value: 'Jay Sean' },
      { label: 'Jay Wheeler', value: 'Jay Wheeler' },
      { label: 'Jay-Z', value: 'Jay-Z' },
      { label: 'Jay-Z & Kanye West', value: 'Jay-Z & Kanye West' },
      { label: 'Jaymes Young', value: 'Jaymes Young' },
      { label: 'Jazmine Sullivan', value: 'Jazmine Sullivan' },
      { label: 'Jeezy', value: 'Jeezy' },
      { label: 'Jeff Beck', value: 'Jeff Beck' },
      { label: 'Jeff Buckley', value: 'Jeff Buckley' },
      { label: 'Jeff Foxworthy', value: 'Jeff Foxworthy' },
      { label: 'Jeff Healey Band', value: 'Jeff Healey Band' },
      { label: 'Jefferson Airplane', value: 'Jefferson Airplane' },
      { label: 'Jefferson Starship', value: 'Jefferson Starship' },
      { label: 'Jeffrey Osborne', value: 'Jeffrey Osborne' },
      { label: 'Jelly Roll', value: 'Jelly Roll' },
      { label: 'Jenni Rivera', value: 'Jenni Rivera' },
      { label: 'Jennifer Hudson', value: 'Jennifer Hudson' },
      { label: 'Jennifer Lopez', value: 'Jennifer Lopez' },
      { label: 'Jeremih', value: 'Jeremih' },
      { label: 'Jeremy Camp', value: 'Jeremy Camp' },
      { label: 'Jermaine Dupri', value: 'Jermaine Dupri' },
      { label: 'Jermaine Jackson', value: 'Jermaine Jackson' },
      { label: 'Jerry Clower', value: 'Jerry Clower' },
      { label: 'Jerry Rivera', value: 'Jerry Rivera' },
      { label: 'Jerry Seinfeld', value: 'Jerry Seinfeld' },
      { label: 'Jess Glynne', value: 'Jess Glynne' },
      { label: 'Jesse & Joy', value: 'Jesse & Joy' },
      { label: 'Jesse Mccartney', value: 'Jesse Mccartney' },
      { label: 'Jessica Simpson', value: 'Jessica Simpson' },
      { label: 'Jessie J', value: 'Jessie J' },
      { label: 'Jessie Ware', value: 'Jessie Ware' },
      { label: 'Jesus Adrian Romero', value: 'Jesus Adrian Romero' },
      { label: 'Jesus Jones', value: 'Jesus Jones' },
      { label: 'Jet', value: 'Jet' },
      { label: 'Jethro Tull', value: 'Jethro Tull' },
      { label: 'Jewel', value: 'Jewel' },
      { label: 'Jhene Aiko', value: 'Jhene Aiko' },
      { label: 'Jid', value: 'Jid' },
      { label: 'Jill Scott', value: 'Jill Scott' },
      { label: 'Jim Brickman', value: 'Jim Brickman' },
      { label: 'Jim Croce', value: 'Jim Croce' },
      { label: 'Jim Johnston', value: 'Jim Johnston' },
      { label: 'Jim Nabors', value: 'Jim Nabors' },
      { label: 'Jim Reeves', value: 'Jim Reeves' },
      { label: 'Jimi Hendrix', value: 'Jimi Hendrix' },
      { label: 'Jimmy Buffett', value: 'Jimmy Buffett' },
      { label: 'Jimmy Eat World', value: 'Jimmy Eat World' },
      {
        label: 'Jimmy Page & Robert Plant',
        value: 'Jimmy Page & Robert Plant',
      },
      { label: 'Jin', value: 'Jin' },
      { label: 'Jj Lin', value: 'Jj Lin' },
      { label: 'Jo Dee Messina', value: 'Jo Dee Messina' },
      { label: 'Joan Baez', value: 'Joan Baez' },
      {
        label: 'Joan Jett & The Blackhearts',
        value: 'Joan Jett & The Blackhearts',
      },
      { label: 'Joan Osborne', value: 'Joan Osborne' },
      { label: 'Joan Sebastian', value: 'Joan Sebastian' },
      { label: 'Joao Bosco & Vinicius', value: 'Joao Bosco & Vinicius' },
      { label: 'Joao Gilberto', value: 'Joao Gilberto' },
      { label: 'Joao Gomes', value: 'Joao Gomes' },
      { label: 'Joaquin Sabina', value: 'Joaquin Sabina' },
      { label: 'Jodeci', value: 'Jodeci' },
      { label: 'Jody Watley', value: 'Jody Watley' },
      { label: 'Joe', value: 'Joe' },
      { label: 'Joe Cocker', value: 'Joe Cocker' },
      { label: 'Joe Diffie', value: 'Joe Diffie' },
      { label: 'Joe Jackson', value: 'Joe Jackson' },
      { label: 'Joe Nichols', value: 'Joe Nichols' },
      { label: 'Joe Satriani', value: 'Joe Satriani' },
      { label: 'Joe Walsh', value: 'Joe Walsh' },
      { label: 'Joey Bada$$', value: 'Joey Bada$$' },
      { label: 'Joey Montana', value: 'Joey Montana' },
      { label: 'Johann Sebastian Bach', value: 'Johann Sebastian Bach' },
      { label: 'John Anderson', value: 'John Anderson' },
      { label: 'John Barry', value: 'John Barry' },
      { label: 'John Berry', value: 'John Berry' },
      {
        label: 'John Cafferty & Beaver Brown',
        value: 'John Cafferty & Beaver Brown',
      },
      { label: 'John Coltrane', value: 'John Coltrane' },
      { label: 'John Denver', value: 'John Denver' },
      { label: 'John Fogerty', value: 'John Fogerty' },
      { label: 'John Legend', value: 'John Legend' },
      { label: 'John Lennon', value: 'John Lennon' },
      { label: 'John Lennon & Yoko Ono', value: 'John Lennon & Yoko Ono' },
      { label: 'John Martin', value: 'John Martin' },
      { label: 'John Mayer', value: 'John Mayer' },
      { label: 'John Mellencamp', value: 'John Mellencamp' },
      { label: 'John Michael Montgomery', value: 'John Michael Montgomery' },
      { label: 'John Newman', value: 'John Newman' },
      { label: 'John Tesh', value: 'John Tesh' },
      { label: 'John Travolta', value: 'John Travolta' },
      { label: 'John Williams', value: 'John Williams' },
      { label: 'Johnnie Taylor', value: 'Johnnie Taylor' },
      { label: "Johnny 'Guitar' Watson", value: "Johnny 'Guitar' Watson" },
      { label: 'Johnny Cash', value: 'Johnny Cash' },
      { label: 'Johnny Gill', value: 'Johnny Gill' },
      { label: 'Johnny Horton', value: 'Johnny Horton' },
      { label: 'Johnny Mathis', value: 'Johnny Mathis' },
      { label: 'Johnny Paycheck', value: 'Johnny Paycheck' },
      { label: 'Johnny Rivers', value: 'Johnny Rivers' },
      { label: 'Joji', value: 'Joji' },
      { label: 'Jojo', value: 'Jojo' },
      { label: 'Jon B.', value: 'Jon B.' },
      { label: 'Jon Bellion', value: 'Jon Bellion' },
      { label: 'Jon Bon Jovi', value: 'Jon Bon Jovi' },
      { label: 'Jon Pardi', value: 'Jon Pardi' },
      { label: 'Jon Secada', value: 'Jon Secada' },
      { label: 'Jon Z', value: 'Jon Z' },
      { label: 'Jonas Blue', value: 'Jonas Blue' },
      { label: 'Jonas Brothers', value: 'Jonas Brothers' },
      { label: 'Joni Mitchell', value: 'Joni Mitchell' },
      { label: 'Jonna Fraser', value: 'Jonna Fraser' },
      { label: 'Jonny Lang', value: 'Jonny Lang' },
      { label: 'Jordan Davis', value: 'Jordan Davis' },
      { label: 'Jordin Sparks', value: 'Jordin Sparks' },
      { label: 'Jorge Drexler', value: 'Jorge Drexler' },
      { label: 'Jorja Smith', value: 'Jorja Smith' },
      { label: 'Jory Boy', value: 'Jory Boy' },
      { label: 'Jose Alfredo Jimenez', value: 'Jose Alfredo Jimenez' },
      { label: 'Jose Feliciano', value: 'Jose Feliciano' },
      { label: 'Jose Jose', value: 'Jose Jose' },
      { label: 'Jose Luis Perales', value: 'Jose Luis Perales' },
      { label: 'Josh A', value: 'Josh A' },
      { label: 'Josh Groban', value: 'Josh Groban' },
      { label: 'Josh Turner', value: 'Josh Turner' },
      { label: 'Joshua Kadison', value: 'Joshua Kadison' },
      { label: 'Joss Stone', value: 'Joss Stone' },
      { label: 'Jota Quest', value: 'Jota Quest' },
      { label: 'Journey', value: 'Journey' },
      { label: 'Jovanotti', value: 'Jovanotti' },
      { label: 'Jowell & Randy', value: 'Jowell & Randy' },
      { label: 'Joy Division', value: 'Joy Division' },
      { label: 'Joyner Lucas', value: 'Joyner Lucas' },
      { label: 'Jp Cooper', value: 'Jp Cooper' },
      { label: 'Juan Gabriel', value: 'Juan Gabriel' },
      { label: 'Juan Luis Guerra', value: 'Juan Luis Guerra' },
      { label: 'Juanes', value: 'Juanes' },
      { label: 'Judas Priest', value: 'Judas Priest' },
      { label: 'Judy Collins', value: 'Judy Collins' },
      { label: 'Juelz Santana', value: 'Juelz Santana' },
      { label: 'Juice Newton', value: 'Juice Newton' },
      { label: 'Juice WRLD', value: 'Juice WRLD' },
      { label: 'Juicy J', value: 'Juicy J' },
      { label: 'Juju', value: 'Juju' },
      { label: 'Jul', value: 'Jul' },
      { label: 'Julia Michaels', value: 'Julia Michaels' },
      { label: 'Julian Lennon', value: 'Julian Lennon' },
      { label: 'Julie Bergan', value: 'Julie Bergan' },
      { label: 'Julien Baker', value: 'Julien Baker' },
      { label: 'Julieta Venegas', value: 'Julieta Venegas' },
      { label: 'Julio Iglesias', value: 'Julio Iglesias' },
      {
        label: 'Julion Alvarez Y Su Norteno Banda',
        value: 'Julion Alvarez Y Su Norteno Banda',
      },
      { label: 'Jung Kook', value: 'Jung Kook' },
      { label: 'Jungle', value: 'Jungle' },
      { label: 'Junior H', value: 'Junior H' },
      { label: 'Justice', value: 'Justice' },
      { label: 'Justin Bieber', value: 'Justin Bieber' },
      { label: 'Justin Hurwitz', value: 'Justin Hurwitz' },
      { label: 'Justin Moore', value: 'Justin Moore' },
      { label: 'Justin Timberlake', value: 'Justin Timberlake' },
      { label: 'Justine Skye', value: 'Justine Skye' },
      { label: 'Juvenile', value: 'Juvenile' },
      { label: 'K Camp', value: 'K Camp' },
      { label: 'K-391', value: 'K-391' },
      { label: 'K-Ci & Jojo', value: 'K-Ci & Jojo' },
      { label: 'K. S. Chithra', value: 'K. S. Chithra' },
      { label: 'K.D. Lang', value: 'K.D. Lang' },
      { label: 'K.Flay', value: 'K.Flay' },
      { label: 'Kaaris', value: 'Kaaris' },
      { label: 'Kacey Musgraves', value: 'Kacey Musgraves' },
      { label: 'Kaleo', value: 'Kaleo' },
      { label: 'Kali Uchis', value: 'Kali Uchis' },
      { label: 'Kane Brown', value: 'Kane Brown' },
      { label: 'Kansas', value: 'Kansas' },
      { label: 'Kany Garcia', value: 'Kany Garcia' },
      { label: 'Kanye West', value: 'Kanye West' },
      { label: 'Karol G', value: 'Karol G' },
      { label: 'Karsten Gluck', value: 'Karsten Gluck' },
      { label: 'Karthik', value: 'Karthik' },
      { label: 'Karyn White', value: 'Karyn White' },
      { label: 'Kasabian', value: 'Kasabian' },
      { label: 'Kaskade', value: 'Kaskade' },
      { label: 'Kate Bush', value: 'Kate Bush' },
      { label: 'Kathy Mattea', value: 'Kathy Mattea' },
      { label: 'Katy Perry', value: 'Katy Perry' },
      { label: 'Kaytranada', value: 'Kaytranada' },
      { label: 'Kc & The Sunshine Band', value: 'Kc & The Sunshine Band' },
      { label: 'Kc Rebell', value: 'Kc Rebell' },
      { label: 'Keane', value: 'Keane' },
      { label: 'Kehlani', value: 'Kehlani' },
      { label: 'Keith Sweat', value: 'Keith Sweat' },
      { label: 'Keith Urban', value: 'Keith Urban' },
      { label: 'Keith Whitley', value: 'Keith Whitley' },
      { label: 'Kelis', value: 'Kelis' },
      { label: 'Kelly Clarkson', value: 'Kelly Clarkson' },
      { label: 'Kelly Price', value: 'Kelly Price' },
      { label: 'Kelly Rowland', value: 'Kelly Rowland' },
      { label: 'Kelsea Ballerini', value: 'Kelsea Ballerini' },
      { label: 'Kem', value: 'Kem' },
      { label: 'Ken Carson', value: 'Ken Carson' },
      { label: 'Kendo Kaponi', value: 'Kendo Kaponi' },
      { label: 'Kendrick Lamar', value: 'Kendrick Lamar' },
      { label: 'Kenia Os', value: 'Kenia Os' },
      { label: 'Kenny Beats', value: 'Kenny Beats' },
      { label: 'Kenny Chesney', value: 'Kenny Chesney' },
      { label: 'Kenny G', value: 'Kenny G' },
      { label: 'Kenny Loggins', value: 'Kenny Loggins' },
      { label: 'Kenny Rogers', value: 'Kenny Rogers' },
      {
        label: 'Kenny Rogers & The First Edition',
        value: 'Kenny Rogers & The First Edition',
      },
      { label: 'Kenny Wayne Shepherd', value: 'Kenny Wayne Shepherd' },
      { label: 'Kenshi Yonezu', value: 'Kenshi Yonezu' },
      { label: 'Kent', value: 'Kent' },
      { label: 'Kentucky Headhunters', value: 'Kentucky Headhunters' },
      { label: 'Keri Hilson', value: 'Keri Hilson' },
      { label: 'Kesha', value: 'Kesha' },
      { label: 'Kevin Gates', value: 'Kevin Gates' },
      { label: 'Kevin Kaarl', value: 'Kevin Kaarl' },
      { label: 'Kevin Roldan', value: 'Kevin Roldan' },
      { label: 'Key Glock', value: 'Key Glock' },
      { label: 'Keyshia Cole', value: 'Keyshia Cole' },
      { label: 'Khalid', value: 'Khalid' },
      { label: 'Khea', value: 'Khea' },
      { label: 'Khruangbin', value: 'Khruangbin' },
      { label: "Kid 'N Play", value: "Kid 'N Play" },
      { label: 'Kid Cudi', value: 'Kid Cudi' },
      { label: 'Kid Ink', value: 'Kid Ink' },
      { label: 'Kid Rock', value: 'Kid Rock' },
      { label: 'Kids See Ghosts', value: 'Kids See Ghosts' },
      { label: 'Kidz Bop Kids', value: 'Kidz Bop Kids' },
      { label: 'Kiiara', value: 'Kiiara' },
      { label: 'Killer Mike', value: 'Killer Mike' },
      { label: 'Killswitch Engage', value: 'Killswitch Engage' },
      { label: 'Kim Carnes', value: 'Kim Carnes' },
      { label: 'Kim Petras', value: 'Kim Petras' },
      { label: 'Kimbra', value: 'Kimbra' },
      { label: 'King', value: 'King' },
      { label: 'King Gnu', value: 'King Gnu' },
      { label: 'King Princess', value: 'King Princess' },
      { label: 'King Von', value: 'King Von' },
      { label: 'Kings Of Leon', value: 'Kings Of Leon' },
      { label: 'Kingston Trio', value: 'Kingston Trio' },
      { label: 'Kip Moore', value: 'Kip Moore' },
      { label: 'Kirk Franklin', value: 'Kirk Franklin' },
      { label: 'Kiss', value: 'Kiss' },
      { label: 'Kix', value: 'Kix' },
      { label: 'Kk', value: 'Kk' },
      { label: 'Klangkarussell', value: 'Klangkarussell' },
      { label: 'Klingande', value: 'Klingande' },
      { label: 'Kodak Black', value: 'Kodak Black' },
      { label: 'Kodaline', value: 'Kodaline' },
      { label: 'Kollegah', value: 'Kollegah' },
      { label: 'Kool & The Gang', value: 'Kool & The Gang' },
      { label: 'Kool Moe Dee', value: 'Kool Moe Dee' },
      { label: 'Kool Savas', value: 'Kool Savas' },
      { label: 'Korn', value: 'Korn' },
      { label: 'Krayzie Bone', value: 'Krayzie Bone' },
      { label: 'Krewella', value: 'Krewella' },
      { label: 'Kris Kristofferson', value: 'Kris Kristofferson' },
      { label: 'Kris Kross', value: 'Kris Kross' },
      { label: 'Kris Kross Amsterdam', value: 'Kris Kross Amsterdam' },
      { label: 'Krokus', value: 'Krokus' },
      { label: 'Kshmr', value: 'Kshmr' },
      { label: 'Ksi', value: 'Ksi' },
      { label: 'Kt Tunstall', value: 'Kt Tunstall' },
      { label: 'Kubi Producent', value: 'Kubi Producent' },
      { label: 'Kungs', value: 'Kungs' },
      { label: 'Kurupt', value: 'Kurupt' },
      { label: 'Kygo', value: 'Kygo' },
      { label: 'Kyle', value: 'Kyle' },
      { label: 'Kylie Minogue', value: 'Kylie Minogue' },
      {
        label: 'L. Elgart / Manhattan Swing Orc',
        value: 'L. Elgart / Manhattan Swing Orc',
      },
      { label: 'L.A. Guns', value: 'L.A. Guns' },
      { label: 'L.T.D.', value: 'L.T.D.' },
      { label: 'La Adictiva', value: 'La Adictiva' },
      {
        label: 'La Arrolladora Banda El Limon De Rene Camacho',
        value: 'La Arrolladora Banda El Limon De Rene Camacho',
      },
      { label: 'La Bouche', value: 'La Bouche' },
      { label: 'La Factoria', value: 'La Factoria' },
      { label: 'La Konga', value: 'La Konga' },
      { label: 'La Oreja De Van Gogh', value: 'La Oreja De Van Gogh' },
      { label: 'La Quinta Estacion', value: 'La Quinta Estacion' },
      { label: 'La Roux', value: 'La Roux' },
      { label: 'La Santa Grifa', value: 'La Santa Grifa' },
      { label: 'La Sonora Dinamita', value: 'La Sonora Dinamita' },
      { label: 'Labrinth', value: 'Labrinth' },
      { label: 'Lacrim', value: 'Lacrim' },
      { label: 'Lady A', value: 'Lady A' },
      { label: 'Lady Gaga', value: 'Lady Gaga' },
      { label: 'Lainey Wilson', value: 'Lainey Wilson' },
      { label: 'Lake Emerson & Palmer', value: 'Lake Emerson & Palmer' },
      { label: 'Lamb Of God', value: 'Lamb Of God' },
      { label: 'Lana Del Rey', value: 'Lana Del Rey' },
      { label: 'Lany', value: 'Lany' },
      { label: 'Larry Gatlin', value: 'Larry Gatlin' },
      { label: 'Larry The Cable Guy', value: 'Larry The Cable Guy' },
      { label: 'Lars Winnerback', value: 'Lars Winnerback' },
      { label: 'Lasso', value: 'Lasso' },
      { label: 'Lauana Prado', value: 'Lauana Prado' },
      { label: 'Laufey', value: 'Laufey' },
      { label: 'Laura Branigan', value: 'Laura Branigan' },
      { label: 'Laura Pausini', value: 'Laura Pausini' },
      { label: 'Lauren Alaina', value: 'Lauren Alaina' },
      { label: 'Lauren Daigle', value: 'Lauren Daigle' },
      { label: 'Lauren Spencer Smith', value: 'Lauren Spencer Smith' },
      { label: 'Lauryn Hill', value: 'Lauryn Hill' },
      { label: 'Lauv', value: 'Lauv' },
      { label: 'Lawrence Welk', value: 'Lawrence Welk' },
      { label: 'Lcd Soundsystem', value: 'Lcd Soundsystem' },
      { label: 'Le Sserafim', value: 'Le Sserafim' },
      { label: 'Lea', value: 'Lea' },
      { label: 'League Of Legends', value: 'League Of Legends' },
      { label: 'Leann Rimes', value: 'Leann Rimes' },
      { label: 'Led Zeppelin', value: 'Led Zeppelin' },
      { label: 'Lee Ann Womack', value: 'Lee Ann Womack' },
      { label: 'Lee Brice', value: 'Lee Brice' },
      { label: 'Lee Greenwood', value: 'Lee Greenwood' },
      { label: 'Legiao Urbana', value: 'Legiao Urbana' },
      { label: 'Lenin Ramirez', value: 'Lenin Ramirez' },
      { label: 'Lenny Kravitz', value: 'Lenny Kravitz' },
      { label: 'Leo Dan', value: 'Leo Dan' },
      { label: 'Leo Santana', value: 'Leo Santana' },
      { label: 'Leo Sayer', value: 'Leo Sayer' },
      { label: 'Leon', value: 'Leon' },
      { label: 'Leon Bridges', value: 'Leon Bridges' },
      { label: 'Leon Larregui', value: 'Leon Larregui' },
      { label: 'Leon Russell', value: 'Leon Russell' },
      { label: 'Leona Lewis', value: 'Leona Lewis' },
      { label: 'Leonard Cohen', value: 'Leonard Cohen' },
      { label: 'Leonardo', value: 'Leonardo' },
      { label: 'Leony', value: 'Leony' },
      { label: 'Leslie Grace', value: 'Leslie Grace' },
      { label: 'Leto', value: 'Leto' },
      { label: 'Levert', value: 'Levert' },
      { label: 'Lewis Capaldi', value: 'Lewis Capaldi' },
      { label: 'Liam Payne', value: 'Liam Payne' },
      { label: 'Liberacion', value: 'Liberacion' },
      { label: 'Lifehouse', value: 'Lifehouse' },
      { label: 'Lil Baby', value: 'Lil Baby' },
      { label: 'Lil Dicky', value: 'Lil Dicky' },
      { label: 'Lil Durk', value: 'Lil Durk' },
      { label: 'Lil Jon', value: 'Lil Jon' },
      {
        label: 'Lil Jon & The East Side Boyz',
        value: 'Lil Jon & The East Side Boyz',
      },
      { label: 'Lil Kleine', value: 'Lil Kleine' },
      { label: 'Lil Mosey', value: 'Lil Mosey' },
      { label: 'Lil Nas X', value: 'Lil Nas X' },
      { label: 'Lil Peep', value: 'Lil Peep' },
      { label: 'Lil Pump', value: 'Lil Pump' },
      { label: 'Lil Skies', value: 'Lil Skies' },
      { label: 'Lil Tecca', value: 'Lil Tecca' },
      { label: 'Lil Tjay', value: 'Lil Tjay' },
      { label: 'Lil Tracy', value: 'Lil Tracy' },
      { label: 'Lil Uzi Vert', value: 'Lil Uzi Vert' },
      { label: 'Lil Wayne', value: 'Lil Wayne' },
      { label: 'Lil Xan', value: 'Lil Xan' },
      { label: 'Lil Yachty', value: 'Lil Yachty' },
      { label: "Lil' Flip", value: "Lil' Flip" },
      { label: "Lil' Kim", value: "Lil' Kim" },
      { label: "Lil' Troy", value: "Lil' Troy" },
      { label: 'Lila Mc Cann', value: 'Lila Mc Cann' },
      { label: 'Lilly Wood And The Prick', value: 'Lilly Wood And The Prick' },
      { label: 'Lily Allen', value: 'Lily Allen' },
      { label: 'Limp Bizkit', value: 'Limp Bizkit' },
      { label: 'Lin-Manuel Miranda', value: 'Lin-Manuel Miranda' },
      { label: 'Linda Ronstadt', value: 'Linda Ronstadt' },
      { label: 'Lindsay Lohan', value: 'Lindsay Lohan' },
      { label: 'Lindsey Stirling', value: 'Lindsey Stirling' },
      { label: 'Linkin Park', value: 'Linkin Park' },
      { label: 'Lionel Richie', value: 'Lionel Richie' },
      { label: 'Lisa', value: 'Lisa' },
      { label: 'Lisa Lisa & Cult Jam', value: 'Lisa Lisa & Cult Jam' },
      { label: 'Lisa Loeb', value: 'Lisa Loeb' },
      { label: 'Lisa Stansfield', value: 'Lisa Stansfield' },
      { label: 'Lit', value: 'Lit' },
      { label: 'Lita Ford', value: 'Lita Ford' },
      { label: 'Little Big Town', value: 'Little Big Town' },
      { label: 'Little Dragon', value: 'Little Dragon' },
      { label: 'Little Feat', value: 'Little Feat' },
      { label: 'Little Mix', value: 'Little Mix' },
      { label: 'Little River Band', value: 'Little River Band' },
      { label: 'Little Sis Nora', value: 'Little Sis Nora' },
      { label: 'Little Texas', value: 'Little Texas' },
      { label: 'Live', value: 'Live' },
      { label: 'Living Colour', value: 'Living Colour' },
      { label: 'Liz Phair', value: 'Liz Phair' },
      { label: 'Liza Minnelli', value: 'Liza Minnelli' },
      { label: 'Lizzo', value: 'Lizzo' },
      { label: 'Lizzy Mcalpine', value: 'Lizzy Mcalpine' },
      { label: 'LL Cool J', value: 'LL Cool J' },
      { label: 'Lloyd', value: 'Lloyd' },
      { label: 'Lmfao', value: 'Lmfao' },
      { label: 'Local Natives', value: 'Local Natives' },
      { label: 'Lofi Fruits Music', value: 'Lofi Fruits Music' },
      { label: 'Loggins & Messina', value: 'Loggins & Messina' },
      { label: 'Logic', value: 'Logic' },
      { label: 'Lola Indigo', value: 'Lola Indigo' },
      { label: 'London Grammar', value: 'London Grammar' },
      {
        label: 'London Symphony Orchestra',
        value: 'London Symphony Orchestra',
      },
      { label: 'Lonestar', value: 'Lonestar' },
      { label: 'Loona', value: 'Loona' },
      { label: 'Lord Huron', value: 'Lord Huron' },
      { label: 'Lorde', value: 'Lorde' },
      { label: 'Loredana', value: 'Loredana' },
      { label: 'Loreen', value: 'Loreen' },
      { label: 'Loreena Mc Kennitt', value: 'Loreena Mc Kennitt' },
      { label: 'Loretta Lynn', value: 'Loretta Lynn' },
      { label: 'Lorrie Morgan', value: 'Lorrie Morgan' },
      { label: 'Los Acosta', value: 'Los Acosta' },
      { label: 'Los Angeles Azules', value: 'Los Angeles Azules' },
      { label: 'Los Angeles De Charly', value: 'Los Angeles De Charly' },
      {
        label: 'Los Autenticos Decadentes',
        value: 'Los Autenticos Decadentes',
      },
      { label: 'Los Bukis', value: 'Los Bukis' },
      { label: 'Los Bunkers', value: 'Los Bunkers' },
      { label: 'Los Cadetes De Linares', value: 'Los Cadetes De Linares' },
      { label: 'Los Cafres', value: 'Los Cafres' },
      { label: 'Los Caligaris', value: 'Los Caligaris' },
      { label: 'Los Enanitos Verdes', value: 'Los Enanitos Verdes' },
      { label: 'Los Fabulosos Cadillacs', value: 'Los Fabulosos Cadillacs' },
      { label: 'Los Huracanes Del Norte', value: 'Los Huracanes Del Norte' },
      { label: 'Los Inquietos Del Norte', value: 'Los Inquietos Del Norte' },
      {
        label: 'Los Invasores De Nuevo Leon',
        value: 'Los Invasores De Nuevo Leon',
      },
      { label: 'Los Legendarios', value: 'Los Legendarios' },
      { label: 'Los Lobos', value: 'Los Lobos' },
      { label: 'Los Lonely Boys', value: 'Los Lonely Boys' },
      { label: 'Los Palmeras', value: 'Los Palmeras' },
      { label: 'Los Panchos', value: 'Los Panchos' },
      { label: 'Los Pericos', value: 'Los Pericos' },
      {
        label: 'Los Plebes Del Rancho De Ariel Camacho',
        value: 'Los Plebes Del Rancho De Ariel Camacho',
      },
      { label: 'Los Prisioneros', value: 'Los Prisioneros' },
      { label: 'Los Temerarios', value: 'Los Temerarios' },
      { label: 'Los Tigres Del Norte', value: 'Los Tigres Del Norte' },
      { label: 'Los Tucanes De Tijuana', value: 'Los Tucanes De Tijuana' },
      { label: 'Lost Boyz', value: 'Lost Boyz' },
      { label: 'Lost Frequencies', value: 'Lost Frequencies' },
      { label: 'Lost Kings', value: 'Lost Kings' },
      { label: 'Lou Bega', value: 'Lou Bega' },
      { label: 'Lou Rawls', value: 'Lou Rawls' },
      { label: 'Lou Reed', value: 'Lou Reed' },
      { label: 'Loud Luxury', value: 'Loud Luxury' },
      { label: 'Louis Armstrong', value: 'Louis Armstrong' },
      { label: 'Louis The Child', value: 'Louis The Child' },
      { label: 'Louis Tomlinson', value: 'Louis Tomlinson' },
      { label: 'Love Unlimited', value: 'Love Unlimited' },
      { label: 'Loverboy', value: 'Loverboy' },
      { label: 'Loyle Carner', value: 'Loyle Carner' },
      { label: 'Lp', value: 'Lp' },
      { label: 'Lsd', value: 'Lsd' },
      { label: 'Lsg', value: 'Lsg' },
      { label: 'Luan Santana', value: 'Luan Santana' },
      { label: 'Luar La L', value: 'Luar La L' },
      { label: 'Lucas & Steve', value: 'Lucas & Steve' },
      { label: 'Lucas Lucco', value: 'Lucas Lucco' },
      { label: 'Luciano', value: 'Luciano' },
      { label: 'Luciano Pavarotti', value: 'Luciano Pavarotti' },
      { label: 'Lucky Daye', value: 'Lucky Daye' },
      { label: 'Lucy Dacus', value: 'Lucy Dacus' },
      { label: 'Ludacris', value: 'Ludacris' },
      { label: 'Ludovico Einaudi', value: 'Ludovico Einaudi' },
      { label: 'Ludwig Van Beethoven', value: 'Ludwig Van Beethoven' },
      { label: 'Luis Angel El Flaco', value: 'Luis Angel El Flaco' },
      { label: 'Luis Fonsi', value: 'Luis Fonsi' },
      { label: 'Luis Miguel', value: 'Luis Miguel' },
      { label: 'Luisa Sonza', value: 'Luisa Sonza' },
      { label: 'Lukas Graham', value: 'Lukas Graham' },
      { label: 'Luke Bryan', value: 'Luke Bryan' },
      { label: 'Luke Combs', value: 'Luke Combs' },
      { label: 'Lulu Santos', value: 'Lulu Santos' },
      { label: 'Lunay', value: 'Lunay' },
      { label: 'Luniz', value: 'Luniz' },
      { label: 'Lupe Fiasco', value: 'Lupe Fiasco' },
      { label: 'Luther Vandross', value: 'Luther Vandross' },
      { label: 'Lx', value: 'Lx' },
      { label: 'Lykke Li', value: 'Lykke Li' },
      { label: 'Lyle Lovett', value: 'Lyle Lovett' },
      { label: 'Lynn Anderson', value: 'Lynn Anderson' },
      { label: 'Lynyrd Skynyrd', value: 'Lynyrd Skynyrd' },
      { label: 'M.I.A.', value: 'M.I.A.' },
      { label: 'M83', value: 'M83' },
      { label: 'Mabel', value: 'Mabel' },
      { label: 'Mabel Matiz', value: 'Mabel Matiz' },
      { label: 'Mac Davis', value: 'Mac Davis' },
      { label: 'Mac Demarco', value: 'Mac Demarco' },
      { label: 'Mac Miller', value: 'Mac Miller' },
      { label: 'Machine Gun Kelly', value: 'Machine Gun Kelly' },
      { label: 'Mack 10', value: 'Mack 10' },
      { label: 'Macklemore', value: 'Macklemore' },
      { label: 'Macklemore & Ryan Lewis', value: 'Macklemore & Ryan Lewis' },
      { label: 'Macy Gray', value: 'Macy Gray' },
      { label: 'Madcon', value: 'Madcon' },
      { label: 'Madeon', value: 'Madeon' },
      { label: 'Madison Beer', value: 'Madison Beer' },
      { label: 'Madlib', value: 'Madlib' },
      { label: 'Madman', value: 'Madman' },
      { label: 'Madonna', value: 'Madonna' },
      { label: 'Madvillain', value: 'Madvillain' },
      { label: 'Maelo Ruiz', value: 'Maelo Ruiz' },
      { label: 'Maggie Lindemann', value: 'Maggie Lindemann' },
      { label: 'Maggie Rogers', value: 'Maggie Rogers' },
      { label: 'Mago De Oz', value: 'Mago De Oz' },
      { label: 'Mahalia', value: 'Mahalia' },
      { label: 'Mahavishnu Orchestra', value: 'Mahavishnu Orchestra' },
      { label: 'Maher Zain', value: 'Maher Zain' },
      { label: 'Mainstreet', value: 'Mainstreet' },
      { label: 'Maisie Peters', value: 'Maisie Peters' },
      { label: 'Majid Jordan', value: 'Majid Jordan' },
      { label: 'Major Lazer', value: 'Major Lazer' },
      { label: 'Makaveli', value: 'Makaveli' },
      { label: 'Mako', value: 'Mako' },
      { label: 'Malik Montana', value: 'Malik Montana' },
      { label: 'Malu', value: 'Malu' },
      { label: 'Maluma', value: 'Maluma' },
      { label: 'Mamamoo', value: 'Mamamoo' },
      { label: 'Mambo Kingz', value: 'Mambo Kingz' },
      { label: 'Mana', value: 'Mana' },
      { label: 'Mandy Moore', value: 'Mandy Moore' },
      { label: 'Maneskin', value: 'Maneskin' },
      { label: 'Manhattan Transfer', value: 'Manhattan Transfer' },
      { label: 'Mannheim Steamroller', value: 'Mannheim Steamroller' },
      { label: 'Mantovani', value: 'Mantovani' },
      { label: 'Manu Chao', value: 'Manu Chao' },
      { label: 'Manuel Medrano', value: 'Manuel Medrano' },
      { label: 'Manuel Turizo', value: 'Manuel Turizo' },
      { label: 'Marc Anthony', value: 'Marc Anthony' },
      { label: 'Marc Cohn', value: 'Marc Cohn' },
      { label: 'Marc E. Bassy', value: 'Marc E. Bassy' },
      { label: 'Marco Antonio Solis', value: 'Marco Antonio Solis' },
      { label: 'Marco Borsato', value: 'Marco Borsato' },
      { label: 'Marco Mengoni', value: 'Marco Mengoni' },
      { label: 'Marcy Playground', value: 'Marcy Playground' },
      { label: 'Maren Morris', value: 'Maren Morris' },
      { label: 'Mari Fernandez', value: 'Mari Fernandez' },
      { label: 'Maria Becerra', value: 'Maria Becerra' },
      { label: 'Mariah Angeliq', value: 'Mariah Angeliq' },
      { label: 'Mariah Carey', value: 'Mariah Carey' },
      { label: 'Marilyn Manson', value: 'Marilyn Manson' },
      { label: 'Marina', value: 'Marina' },
      { label: 'Mario', value: 'Mario' },
      { label: 'Mario Lanza', value: 'Mario Lanza' },
      { label: 'Marisa Monte', value: 'Marisa Monte' },
      { label: 'Mark Chesnutt', value: 'Mark Chesnutt' },
      { label: 'Mark Forster', value: 'Mark Forster' },
      { label: 'Mark Knopfler', value: 'Mark Knopfler' },
      { label: 'Mark Morrison', value: 'Mark Morrison' },
      { label: 'Mark Ronson', value: 'Mark Ronson' },
      {
        label: 'Marky Mark & The Funky Bunch',
        value: 'Marky Mark & The Funky Bunch',
      },
      { label: 'Maroon 5', value: 'Maroon 5' },
      { label: 'Marracash', value: 'Marracash' },
      { label: 'Marshmello', value: 'Marshmello' },
      { label: 'Martin Garrix', value: 'Martin Garrix' },
      { label: 'Martin Jensen', value: 'Martin Jensen' },
      { label: 'Martin Solveig', value: 'Martin Solveig' },
      { label: 'Martina Mcbride', value: 'Martina Mcbride' },
      { label: 'Marty Robbins', value: 'Marty Robbins' },
      { label: 'Marty Stuart', value: 'Marty Stuart' },
      { label: 'Marvin Gaye', value: 'Marvin Gaye' },
      { label: 'Marvin Sapp', value: 'Marvin Sapp' },
      { label: 'Mary Chapin Carpenter', value: 'Mary Chapin Carpenter' },
      { label: 'Mary J. Blige', value: 'Mary J. Blige' },
      { label: 'Mary Mary', value: 'Mary Mary' },
      { label: 'Mase', value: 'Mase' },
      { label: 'Masego', value: 'Masego' },
      { label: 'Masked Wolf', value: 'Masked Wolf' },
      { label: 'Massimo Pericolo', value: 'Massimo Pericolo' },
      { label: 'Massive Attack', value: 'Massive Attack' },
      { label: 'Master P', value: 'Master P' },
      { label: 'Matchbox Twenty', value: 'Matchbox Twenty' },
      { label: 'Matisyahu', value: 'Matisyahu' },
      { label: 'Matt Maeson', value: 'Matt Maeson' },
      { label: 'Matt Maltese', value: 'Matt Maltese' },
      { label: 'Matt Simons', value: 'Matt Simons' },
      { label: 'Matthew Koma', value: 'Matthew Koma' },
      { label: 'Matthew Sweet', value: 'Matthew Sweet' },
      { label: 'Maverick City Music', value: 'Maverick City Music' },
      { label: 'Max', value: 'Max' },
      { label: 'Max Richter', value: 'Max Richter' },
      { label: 'Maxwell', value: 'Maxwell' },
      { label: 'Mayday', value: 'Mayday' },
      { label: 'Mayday Parade', value: 'Mayday Parade' },
      {
        label: 'Maze Feat. Frankie Beverly',
        value: 'Maze Feat. Frankie Beverly',
      },
      { label: 'Mazzy Star', value: 'Mazzy Star' },
      { label: 'Mc Davi', value: 'Mc Davi' },
      { label: 'Mc Don Juan', value: 'Mc Don Juan' },
      { label: 'Mc G15', value: 'Mc G15' },
      { label: 'Mc Gw', value: 'Mc Gw' },
      { label: 'Mc Ig', value: 'Mc Ig' },
      { label: 'Mc Marks', value: 'Mc Marks' },
      { label: 'Mc Wm', value: 'Mc Wm' },
      { label: 'Meat Loaf', value: 'Meat Loaf' },
      { label: 'Mecano', value: 'Mecano' },
      { label: 'Meco', value: 'Meco' },
      { label: 'Meduza', value: 'Meduza' },
      { label: 'Meek Mill', value: 'Meek Mill' },
      { label: 'Megadeth', value: 'Megadeth' },
      { label: 'Megan Thee Stallion', value: 'Megan Thee Stallion' },
      { label: 'Meghan Trainor', value: 'Meghan Trainor' },
      { label: 'Melanie', value: 'Melanie' },
      { label: 'Melanie Martinez', value: 'Melanie Martinez' },
      { label: 'Melendi', value: 'Melendi' },
      { label: 'Melissa Etheridge', value: 'Melissa Etheridge' },
      { label: 'Melissa Manchester', value: 'Melissa Manchester' },
      { label: 'Men At Work', value: 'Men At Work' },
      { label: 'Men I Trust', value: 'Men I Trust' },
      { label: 'Mercyme', value: 'Mercyme' },
      { label: 'Meredith Brooks', value: 'Meredith Brooks' },
      { label: 'Merle Haggard', value: 'Merle Haggard' },
      { label: 'Metallica', value: 'Metallica' },
      { label: 'Method Man', value: 'Method Man' },
      { label: 'Method Man And Redman', value: 'Method Man And Redman' },
      { label: 'Metro Boomin', value: 'Metro Boomin' },
      { label: 'Metronomy', value: 'Metronomy' },
      { label: 'Mf Doom', value: 'Mf Doom' },
      { label: 'Mgk', value: 'Mgk' },
      { label: 'Mgmt', value: 'Mgmt' },
      { label: 'Mhd', value: 'Mhd' },
      { label: 'Michael Bolton', value: 'Michael Bolton' },
      { label: 'Michael Buble', value: 'Michael Buble' },
      { label: 'Michael Crawford', value: 'Michael Crawford' },
      { label: 'Michael Franks', value: 'Michael Franks' },
      { label: 'Michael Giacchino', value: 'Michael Giacchino' },
      { label: 'Michael Jackson', value: 'Michael Jackson' },
      { label: 'Michael Kiwanuka', value: 'Michael Kiwanuka' },
      { label: 'Michael Martin Murphey', value: 'Michael Martin Murphey' },
      { label: 'Michael Mc Donald', value: 'Michael Mc Donald' },
      { label: 'Michael Schulte', value: 'Michael Schulte' },
      { label: 'Michael W. Smith', value: 'Michael W. Smith' },
      { label: 'Michelle Branch', value: 'Michelle Branch' },
      { label: 'Mick Jagger', value: 'Mick Jagger' },
      { label: 'Mickey Gilley', value: 'Mickey Gilley' },
      { label: 'Mickey Mouse', value: 'Mickey Mouse' },
      { label: 'Micro Tdh', value: 'Micro Tdh' },
      { label: 'Midnight Oil', value: 'Midnight Oil' },
      { label: 'Midnight Star', value: 'Midnight Star' },
      { label: 'Miel San Marcos', value: 'Miel San Marcos' },
      { label: 'Mighty Mighty Bosstones', value: 'Mighty Mighty Bosstones' },
      { label: 'Migos', value: 'Migos' },
      { label: 'Miguel', value: 'Miguel' },
      { label: 'Miguel Bose', value: 'Miguel Bose' },
      { label: 'Mika', value: 'Mika' },
      { label: 'Mike + The Mechanics', value: 'Mike + The Mechanics' },
      { label: 'Mike Bahia', value: 'Mike Bahia' },
      { label: 'Mike Jones', value: 'Mike Jones' },
      { label: 'Mike Perry', value: 'Mike Perry' },
      { label: 'Mike Posner', value: 'Mike Posner' },
      { label: 'Mike Will Made-It', value: 'Mike Will Made-It' },
      { label: 'Mike Williams', value: 'Mike Williams' },
      { label: 'Miky Woodz', value: 'Miky Woodz' },
      { label: 'Miles Davis', value: 'Miles Davis' },
      { label: 'Miley Cyrus', value: 'Miley Cyrus' },
      { label: 'Milky Chance', value: 'Milky Chance' },
      { label: 'Milli Vanilli', value: 'Milli Vanilli' },
      { label: 'Millie Jackson', value: 'Millie Jackson' },
      { label: 'Milo J', value: 'Milo J' },
      { label: 'Ministry', value: 'Ministry' },
      { label: 'Miranda Lambert', value: 'Miranda Lambert' },
      { label: 'Misfits', value: 'Misfits' },
      { label: 'Missy Elliott', value: 'Missy Elliott' },
      { label: 'Misterwives', value: 'Misterwives' },
      { label: 'Mitch Miller & The Gang', value: 'Mitch Miller & The Gang' },
      { label: 'Mitchell Tenpenny', value: 'Mitchell Tenpenny' },
      { label: 'Mk', value: 'Mk' },
      { label: 'Mkto', value: 'Mkto' },
      { label: 'Mo Thugs', value: 'Mo Thugs' },
      { label: 'Mobb Deep', value: 'Mobb Deep' },
      { label: 'Moby', value: 'Moby' },
      { label: 'Modern Talking', value: 'Modern Talking' },
      { label: 'Modest Mouse', value: 'Modest Mouse' },
      { label: 'Mohit Chauhan', value: 'Mohit Chauhan' },
      { label: 'Moira Dela Torre', value: 'Moira Dela Torre' },
      { label: 'Molly Hatchet', value: 'Molly Hatchet' },
      { label: 'Molotov', value: 'Molotov' },
      { label: 'Mon Laferte', value: 'Mon Laferte' },
      { label: 'Money Man', value: 'Money Man' },
      { label: 'Moneybagg Yo', value: 'Moneybagg Yo' },
      { label: 'Monica', value: 'Monica' },
      { label: 'Monsta X', value: 'Monsta X' },
      { label: 'Montell Fish', value: 'Montell Fish' },
      { label: 'Montell Jordan', value: 'Montell Jordan' },
      { label: 'Montgomery Gentry', value: 'Montgomery Gentry' },
      { label: 'Montrose', value: 'Montrose' },
      { label: 'Morat', value: 'Morat' },
      { label: 'Morgan Wallen', value: 'Morgan Wallen' },
      { label: 'Mormon Tabernacle Choir', value: 'Mormon Tabernacle Choir' },
      { label: 'Morrissey', value: 'Morrissey' },
      { label: 'Mother Mother', value: 'Mother Mother' },
      { label: 'Moti', value: 'Moti' },
      { label: 'Motionless In White', value: 'Motionless In White' },
      { label: 'Motive', value: 'Motive' },
      { label: 'Motley Crue', value: 'Motley Crue' },
      { label: 'Motorhead', value: 'Motorhead' },
      { label: 'Mountain', value: 'Mountain' },
      { label: 'Mr Eazi', value: 'Mr Eazi' },
      { label: 'Mr. Big', value: 'Mr. Big' },
      { label: 'Mr. Mister', value: 'Mr. Mister' },
      { label: 'Mr. Probz', value: 'Mr. Probz' },
      { label: 'Mr.Kitty', value: 'Mr.Kitty' },
      { label: 'Mrs. Green Apple', value: 'Mrs. Green Apple' },
      { label: 'Mudvayne', value: 'Mudvayne' },
      { label: 'Mumford & Sons', value: 'Mumford & Sons' },
      { label: 'Mumuzinho', value: 'Mumuzinho' },
      { label: 'Mura Masa', value: 'Mura Masa' },
      { label: 'Murda Beatz', value: 'Murda Beatz' },
      { label: 'Muse', value: 'Muse' },
      { label: 'Musiq', value: 'Musiq' },
      { label: 'My Chemical Romance', value: 'My Chemical Romance' },
      { label: 'Mya', value: 'Mya' },
      { label: 'Myke Towers', value: 'Myke Towers' },
      { label: 'N.W.A.', value: 'N.W.A.' },
      { label: 'Nacho', value: 'Nacho' },
      { label: 'Najee', value: 'Najee' },
      { label: 'Nappy Roots', value: 'Nappy Roots' },
      { label: 'Naps', value: 'Naps' },
      { label: 'Nardo Wick', value: 'Nardo Wick' },
      { label: 'Nas', value: 'Nas' },
      { label: 'Nat King Cole', value: 'Nat King Cole' },
      { label: 'Natalia Jimenez', value: 'Natalia Jimenez' },
      { label: 'Natalia Lafourcade', value: 'Natalia Lafourcade' },
      { label: 'Natalie Cole', value: 'Natalie Cole' },
      { label: 'Natalie Imbruglia', value: 'Natalie Imbruglia' },
      { label: 'Natalie Merchant', value: 'Natalie Merchant' },
      { label: 'Natasha Bedingfield', value: 'Natasha Bedingfield' },
      { label: 'Nate Dogg', value: 'Nate Dogg' },
      { label: 'Nathan Dawe', value: 'Nathan Dawe' },
      { label: 'Nathan Evans', value: 'Nathan Evans' },
      { label: 'Natiruts', value: 'Natiruts' },
      { label: 'Natti Natasha', value: 'Natti Natasha' },
      { label: 'Naughty Boy', value: 'Naughty Boy' },
      { label: 'Naughty By Nature', value: 'Naughty By Nature' },
      { label: 'Nav', value: 'Nav' },
      { label: 'Nazareth', value: 'Nazareth' },
      { label: 'Nct 127', value: 'Nct 127' },
      { label: 'Nct Dream', value: 'Nct Dream' },
      { label: 'Ne-Yo', value: 'Ne-Yo' },
      { label: 'Neck Deep', value: 'Neck Deep' },
      { label: 'Needtobreathe', value: 'Needtobreathe' },
      { label: 'Nego Do Borel', value: 'Nego Do Borel' },
      { label: 'Neil Diamond', value: 'Neil Diamond' },
      { label: 'Neil Sedaka', value: 'Neil Sedaka' },
      { label: 'Neil Young', value: 'Neil Young' },
      { label: 'Nelly', value: 'Nelly' },
      { label: 'Nelly Furtado', value: 'Nelly Furtado' },
      { label: 'Nengo Flow', value: 'Nengo Flow' },
      { label: 'Neo Pistea', value: 'Neo Pistea' },
      { label: 'Neon Trees', value: 'Neon Trees' },
      { label: 'Neoni', value: 'Neoni' },
      { label: 'Nessa Barrett', value: 'Nessa Barrett' },
      { label: 'Neville Mariner', value: 'Neville Mariner' },
      { label: 'New Edition', value: 'New Edition' },
      { label: 'New Found Glory', value: 'New Found Glory' },
      { label: 'New Kids On The Block', value: 'New Kids On The Block' },
      { label: 'New Order', value: 'New Order' },
      { label: 'New Radicals', value: 'New Radicals' },
      { label: 'New West', value: 'New West' },
      { label: 'Newjeans', value: 'Newjeans' },
      { label: 'Next', value: 'Next' },
      { label: 'Niall Horan', value: 'Niall Horan' },
      {
        label: 'Nick Cave & The Bad Seeds',
        value: 'Nick Cave & The Bad Seeds',
      },
      { label: 'Nick Jonas', value: 'Nick Jonas' },
      { label: 'Nickel Creek', value: 'Nickel Creek' },
      { label: 'Nickelback', value: 'Nickelback' },
      { label: 'Nicki Minaj', value: 'Nicki Minaj' },
      { label: 'Nicki Nicole', value: 'Nicki Nicole' },
      { label: 'Nicky Jam', value: 'Nicky Jam' },
      { label: 'Nicky Romero', value: 'Nicky Romero' },
      { label: 'Nicky Youre', value: 'Nicky Youre' },
      { label: 'Nico Santos', value: 'Nico Santos' },
      { label: 'Nicole Scherzinger', value: 'Nicole Scherzinger' },
      { label: 'Night Lovell', value: 'Night Lovell' },
      { label: 'Night Ranger', value: 'Night Ranger' },
      { label: 'Nightcrawlers', value: 'Nightcrawlers' },
      { label: 'Nightwish', value: 'Nightwish' },
      { label: 'Nikhita Gandhi', value: 'Nikhita Gandhi' },
      { label: 'Niki', value: 'Niki' },
      { label: 'Nile Rodgers', value: 'Nile Rodgers' },
      { label: 'Nils Frahm', value: 'Nils Frahm' },
      { label: 'Nilsson', value: 'Nilsson' },
      { label: 'Nimo', value: 'Nimo' },
      { label: 'Nina Nesbitt', value: 'Nina Nesbitt' },
      { label: 'Nina Simone', value: 'Nina Simone' },
      { label: 'Nine Inch Nails', value: 'Nine Inch Nails' },
      { label: 'Ninho', value: 'Ninho' },
      { label: 'Nino Rota', value: 'Nino Rota' },
      { label: 'Nio Garcia', value: 'Nio Garcia' },
      { label: 'Nipsey Hussle', value: 'Nipsey Hussle' },
      { label: 'Niro', value: 'Niro' },
      { label: 'Nirvana', value: 'Nirvana' },
      { label: 'Niska', value: 'Niska' },
      { label: 'Nitro', value: 'Nitro' },
      { label: 'Nitty Gritty Dirt Band', value: 'Nitty Gritty Dirt Band' },
      { label: 'Nle Choppa', value: 'Nle Choppa' },
      { label: 'No Doubt', value: 'No Doubt' },
      { label: 'No Te Va Gustar', value: 'No Te Va Gustar' },
      { label: 'Noah Cyrus', value: 'Noah Cyrus' },
      { label: 'Noah Kahan', value: 'Noah Kahan' },
      { label: 'Nofx', value: 'Nofx' },
      { label: 'Nora En Pure', value: 'Nora En Pure' },
      { label: 'Norah Jones', value: 'Norah Jones' },
      { label: 'Noreaga', value: 'Noreaga' },
      { label: 'Notd', value: 'Notd' },
      { label: 'Nothing But Thieves', value: 'Nothing But Thieves' },
      { label: 'Novo Amor', value: 'Novo Amor' },
      { label: 'Noyz Narcos', value: 'Noyz Narcos' },
      { label: 'Nusrat Fateh Ali Khan', value: 'Nusrat Fateh Ali Khan' },
      { label: 'O Rappa', value: 'O Rappa' },
      { label: "O'Jays", value: "O'Jays" },
      { label: 'Oak Ridge Boys', value: 'Oak Ridge Boys' },
      { label: 'Oasis', value: 'Oasis' },
      { label: 'Odesza', value: 'Odesza' },
      { label: 'Of Mice & Men', value: 'Of Mice & Men' },
      { label: 'Of Monsters And Men', value: 'Of Monsters And Men' },
      { label: 'Ofenbach', value: 'Ofenbach' },
      { label: 'Official Hige Dandism', value: 'Official Hige Dandism' },
      { label: 'Offset', value: 'Offset' },
      { label: 'Oh Wonder', value: 'Oh Wonder' },
      { label: "Ol' Dirty Bastard", value: "Ol' Dirty Bastard" },
      { label: 'Olafur Arnalds', value: 'Olafur Arnalds' },
      { label: 'Old Dominion', value: 'Old Dominion' },
      { label: 'Oliver Heldens', value: 'Oliver Heldens' },
      { label: 'Oliver Tree', value: 'Oliver Tree' },
      { label: 'Olivia Newton-John', value: 'Olivia Newton-John' },
      { label: 'Olivia Obrien', value: 'Olivia Obrien' },
      { label: 'Olivia Rodrigo', value: 'Olivia Rodrigo' },
      { label: 'Olly Murs', value: 'Olly Murs' },
      { label: 'Omar Montes', value: 'Omar Montes' },
      { label: 'Omarion', value: 'Omarion' },
      { label: 'Omi', value: 'Omi' },
      { label: 'One Direction', value: 'One Direction' },
      { label: 'One Ok Rock', value: 'One Ok Rock' },
      { label: 'Onerepublic', value: 'Onerepublic' },
      { label: 'Onyx', value: 'Onyx' },
      { label: 'Orelsan', value: 'Orelsan' },
      { label: 'Orgy', value: 'Orgy' },
      { label: 'Orochi', value: 'Orochi' },
      { label: 'Os Baroes Da Pisadinha', value: 'Os Baroes Da Pisadinha' },
      { label: 'Oscar Maydon', value: 'Oscar Maydon' },
      { label: 'Otis Redding', value: 'Otis Redding' },
      { label: 'Ottmar Liebert', value: 'Ottmar Liebert' },
      { label: 'Our Lady Peace', value: 'Our Lady Peace' },
      { label: 'Our Last Night', value: 'Our Last Night' },
      { label: 'Outkast', value: 'Outkast' },
      { label: 'Owl City', value: 'Owl City' },
      { label: 'Ozuna', value: 'Ozuna' },
      { label: 'Ozzy Osbourne', value: 'Ozzy Osbourne' },
      { label: 'P!Nk', value: 'P!Nk' },
      { label: 'P.M. Dawn', value: 'P.M. Dawn' },
      { label: 'P.O.D.', value: 'P.O.D.' },
      { label: 'Pa Sports', value: 'Pa Sports' },
      { label: 'Pablo Alboran', value: 'Pablo Alboran' },
      { label: 'Pablo Chill-E', value: 'Pablo Chill-E' },
      { label: 'Pablo Cruise', value: 'Pablo Cruise' },
      { label: 'Paloma Faith', value: 'Paloma Faith' },
      { label: 'Paloma Mami', value: 'Paloma Mami' },
      { label: 'Pam Tillis', value: 'Pam Tillis' },
      { label: 'Panic! At The Disco', value: 'Panic! At The Disco' },
      { label: 'Panteon Rococo', value: 'Panteon Rococo' },
      { label: 'Pantera', value: 'Pantera' },
      { label: 'Paolo Nutini', value: 'Paolo Nutini' },
      { label: 'Papa Roach', value: 'Papa Roach' },
      { label: 'Paramore', value: 'Paramore' },
      { label: 'Parker Mccollum', value: 'Parker Mccollum' },
      { label: 'Parkway Drive', value: 'Parkway Drive' },
      { label: 'Parliament', value: 'Parliament' },
      { label: 'Parokya Ni Edgar', value: 'Parokya Ni Edgar' },
      { label: 'Parov Stelar', value: 'Parov Stelar' },
      { label: 'Parson James', value: 'Parson James' },
      { label: 'Partridge Family', value: 'Partridge Family' },
      { label: 'Partynextdoor', value: 'Partynextdoor' },
      { label: 'Passenger', value: 'Passenger' },
      { label: 'Passion Pit', value: 'Passion Pit' },
      { label: 'Pat Benatar', value: 'Pat Benatar' },
      { label: 'Pat Metheny Group', value: 'Pat Metheny Group' },
      {
        label: 'Patricio Rey Y Sus Redonditos De Ricota',
        value: 'Patricio Rey Y Sus Redonditos De Ricota',
      },
      { label: 'Patrick Watson', value: 'Patrick Watson' },
      { label: 'Patsy Cline', value: 'Patsy Cline' },
      { label: 'Patti Labelle', value: 'Patti Labelle' },
      { label: 'Patty Loveless', value: 'Patty Loveless' },
      { label: 'Paty Cantu', value: 'Paty Cantu' },
      { label: 'Paul Anka', value: 'Paul Anka' },
      { label: 'Paul Kalkbrenner', value: 'Paul Kalkbrenner' },
      { label: 'Paul McCartney', value: 'Paul McCartney' },
      {
        label: 'Paul Revere & The Raiders',
        value: 'Paul Revere & The Raiders',
      },
      { label: 'Paul Simon', value: 'Paul Simon' },
      { label: 'Paul Stanley', value: 'Paul Stanley' },
      { label: 'Paul Wall', value: 'Paul Wall' },
      { label: 'Paula Abdul', value: 'Paula Abdul' },
      { label: 'Paula Cole', value: 'Paula Cole' },
      { label: 'Paula Fernandes', value: 'Paula Fernandes' },
      { label: 'Paulina Rubio', value: 'Paulina Rubio' },
      { label: 'Payal Dev', value: 'Payal Dev' },
      { label: 'Peabo Bryson', value: 'Peabo Bryson' },
      { label: 'Peaches & Herb', value: 'Peaches & Herb' },
      { label: 'Pearl Jam', value: 'Pearl Jam' },
      { label: 'Pebbles', value: 'Pebbles' },
      { label: 'Pedro Sampaio', value: 'Pedro Sampaio' },
      { label: 'Pendulum', value: 'Pendulum' },
      { label: 'Pentatonix', value: 'Pentatonix' },
      { label: 'Pepe Aguilar', value: 'Pepe Aguilar' },
      { label: 'Percy Faith', value: 'Percy Faith' },
      { label: 'Perry Como', value: 'Perry Como' },
      { label: 'Pesado', value: 'Pesado' },
      { label: 'Pet Shop Boys', value: 'Pet Shop Boys' },
      { label: 'Pete Townshend', value: 'Pete Townshend' },
      { label: 'Peter Cetera', value: 'Peter Cetera' },
      { label: 'Peter Criss', value: 'Peter Criss' },
      { label: 'Peter Frampton', value: 'Peter Frampton' },
      { label: 'Peter Gabriel', value: 'Peter Gabriel' },
      { label: 'Peter Tosh', value: 'Peter Tosh' },
      { label: 'Petey Pablo', value: 'Petey Pablo' },
      { label: 'Petit Biscuit', value: 'Petit Biscuit' },
      { label: 'Petra', value: 'Petra' },
      { label: 'Phantogram', value: 'Phantogram' },
      { label: 'Pharrell Williams', value: 'Pharrell Williams' },
      { label: 'Phil Collins', value: 'Phil Collins' },
      { label: 'Phish', value: 'Phish' },
      { label: 'Phoebe Bridgers', value: 'Phoebe Bridgers' },
      { label: 'Phoebe Snow', value: 'Phoebe Snow' },
      { label: 'Phoenix', value: 'Phoenix' },
      { label: "Pi'Erre Bourne", value: "Pi'Erre Bourne" },
      { label: 'Pia Mia', value: 'Pia Mia' },
      { label: 'Pierce The Veil', value: 'Pierce The Veil' },
      {
        label: 'Pinguini Tattici Nucleari',
        value: 'Pinguini Tattici Nucleari',
      },
      { label: 'Pink Floyd', value: 'Pink Floyd' },
      { label: 'Pink Sweat$', value: 'Pink Sweat$' },
      { label: 'Pinkfong', value: 'Pinkfong' },
      { label: 'Pinkpantheress', value: 'Pinkpantheress' },
      { label: 'Piso 21', value: 'Piso 21' },
      { label: 'Pitbull', value: 'Pitbull' },
      { label: 'Pixies', value: 'Pixies' },
      { label: 'Pixote', value: 'Pixote' },
      { label: 'Pk', value: 'Pk' },
      { label: 'Placebo', value: 'Placebo' },
      { label: 'Placido Domingo', value: 'Placido Domingo' },
      { label: "Plain White T's", value: "Plain White T's" },
      { label: 'Plan B', value: 'Plan B' },
      { label: 'Playboi Carti', value: 'Playboi Carti' },
      { label: 'Player', value: 'Player' },
      { label: 'Plies', value: 'Plies' },
      { label: 'Plk', value: 'Plk' },
      { label: 'Pnau', value: 'Pnau' },
      { label: 'Pnl', value: 'Pnl' },
      { label: 'Poco', value: 'Poco' },
      { label: 'Point Of Grace', value: 'Point Of Grace' },
      { label: 'Poison', value: 'Poison' },
      { label: 'Polo G', value: 'Polo G' },
      { label: 'Pooh Shiesty', value: 'Pooh Shiesty' },
      { label: 'Pop Smoke', value: 'Pop Smoke' },
      { label: 'Popcaan', value: 'Popcaan' },
      { label: 'Porter Robinson', value: 'Porter Robinson' },
      { label: 'Portugal. The Man', value: 'Portugal. The Man' },
      { label: 'Post Malone', value: 'Post Malone' },
      { label: 'Postal Service', value: 'Postal Service' },
      { label: 'Pouya', value: 'Pouya' },
      { label: 'Powerman 5000', value: 'Powerman 5000' },
      { label: 'Powfu', value: 'Powfu' },
      {
        label: 'Presidents Of The United States Of America',
        value: 'Presidents Of The United States Of America',
      },
      { label: 'Pretenders', value: 'Pretenders' },
      { label: 'Pretty Ricky', value: 'Pretty Ricky' },
      { label: 'Prettymuch', value: 'Prettymuch' },
      { label: 'Primus', value: 'Primus' },
      { label: 'Prince', value: 'Prince' },
      {
        label: 'Prince & The New Power Generation',
        value: 'Prince & The New Power Generation',
      },
      { label: 'Prince & The Revolution', value: 'Prince & The Revolution' },
      { label: 'Prince Royce', value: 'Prince Royce' },
      { label: 'Pritam', value: 'Pritam' },
      { label: 'Pro8L3M', value: 'Pro8L3M' },
      { label: 'Prodigy', value: 'Prodigy' },
      { label: 'Project Pat', value: 'Project Pat' },
      { label: 'Psy', value: 'Psy' },
      { label: 'Psychedelic Furs', value: 'Psychedelic Furs' },
      { label: 'Public Enemy', value: 'Public Enemy' },
      { label: 'Puddle Of Mudd', value: 'Puddle Of Mudd' },
      { label: 'Purple Disco Machine', value: 'Purple Disco Machine' },
      { label: 'Pusha T', value: 'Pusha T' },
      { label: 'Pvris', value: 'Pvris' },
      { label: 'Pyotr Ilyich Tchaikovsky', value: 'Pyotr Ilyich Tchaikovsky' },
      { label: "Quad City DJ's", value: "Quad City DJ's" },
      { label: 'Quality Control', value: 'Quality Control' },
      { label: 'Quando Rondo', value: 'Quando Rondo' },
      { label: 'Quarterflash', value: 'Quarterflash' },
      { label: 'Quavo', value: 'Quavo' },
      { label: 'Quebonafide', value: 'Quebonafide' },
      { label: 'Queen', value: 'Queen' },
      { label: 'Queen Latifah', value: 'Queen Latifah' },
      { label: 'Queen Naija', value: 'Queen Naija' },
      { label: 'Queens Of The Stone Age', value: 'Queens Of The Stone Age' },
      { label: 'Queensryche', value: 'Queensryche' },
      { label: 'Quevedo', value: 'Quevedo' },
      { label: 'Quiet Riot', value: 'Quiet Riot' },
      { label: 'Quincy Jones', value: 'Quincy Jones' },
      { label: 'Quinn Xcii', value: 'Quinn Xcii' },
      { label: 'Quintino', value: 'Quintino' },
      { label: 'R. Carlos Nakai', value: 'R. Carlos Nakai' },
      { label: 'R. City', value: 'R. City' },
      { label: 'R. Kelly', value: 'R. Kelly' },
      {
        label: 'R. Kelly & Public Announcement',
        value: 'R. Kelly & Public Announcement',
      },
      { label: 'R.E.M.', value: 'R.E.M.' },
      { label: 'R3Hab', value: 'R3Hab' },
      { label: 'Raca Negra', value: 'Raca Negra' },
      { label: 'Rachel Platten', value: 'Rachel Platten' },
      { label: 'Racionais Mcs', value: 'Racionais Mcs' },
      { label: 'Radiohead', value: 'Radiohead' },
      { label: 'Radwimps', value: 'Radwimps' },
      { label: 'Rae Sremmurd', value: 'Rae Sremmurd' },
      { label: 'Raekwon', value: 'Raekwon' },
      { label: 'Raf Camora', value: 'Raf Camora' },
      { label: 'Raffi', value: 'Raffi' },
      { label: 'Rage Against The Machine', value: 'Rage Against The Machine' },
      { label: 'Rahat Fateh Ali Khan', value: 'Rahat Fateh Ali Khan' },
      { label: 'Rainbow Kitten Surprise', value: 'Rainbow Kitten Surprise' },
      { label: 'Rakim', value: 'Rakim' },
      { label: 'Ramin Djawadi', value: 'Ramin Djawadi' },
      { label: 'Rammstein', value: 'Rammstein' },
      { label: 'Ramones', value: 'Ramones' },
      { label: 'Rancid', value: 'Rancid' },
      { label: 'Randy', value: 'Randy' },
      { label: 'Randy Travis', value: 'Randy Travis' },
      { label: 'Rani', value: 'Rani' },
      { label: 'Rare Earth', value: 'Rare Earth' },
      { label: 'Rascal Flatts', value: 'Rascal Flatts' },
      { label: 'Rascals', value: 'Rascals' },
      { label: 'Ratatat', value: 'Ratatat' },
      { label: 'Ratt', value: 'Ratt' },
      { label: 'Rauw Alejandro', value: 'Rauw Alejandro' },
      { label: 'Ray Boltz', value: 'Ray Boltz' },
      { label: 'Ray Charles', value: 'Ray Charles' },
      { label: 'Ray Conniff', value: 'Ray Conniff' },
      { label: 'Ray Lamontagne', value: 'Ray Lamontagne' },
      { label: 'Ray Lynch', value: 'Ray Lynch' },
      { label: 'Ray Price', value: 'Ray Price' },
      { label: 'Ray Stevens', value: 'Ray Stevens' },
      { label: 'Raye', value: 'Raye' },
      { label: 'Rbd', value: 'Rbd' },
      { label: 'Ready For The World', value: 'Ready For The World' },
      { label: 'Real Mc Coy', value: 'Real Mc Coy' },
      { label: 'Reba Mcentire', value: 'Reba Mcentire' },
      { label: 'Rebecca St. James', value: 'Rebecca St. James' },
      { label: 'Red Hot Chili Peppers', value: 'Red Hot Chili Peppers' },
      { label: 'Red Velvet', value: 'Red Velvet' },
      { label: 'Redman', value: 'Redman' },
      { label: 'Regina Belle', value: 'Regina Belle' },
      { label: 'Regina Spektor', value: 'Regina Spektor' },
      { label: 'Rei', value: 'Rei' },
      { label: 'Relient K', value: 'Relient K' },
      { label: 'Remy Ma', value: 'Remy Ma' },
      { label: 'Renee Elise Goldsberry', value: 'Renee Elise Goldsberry' },
      { label: 'REO Speedwagon', value: 'REO Speedwagon' },
      { label: 'Residente', value: 'Residente' },
      { label: 'Restless Heart', value: 'Restless Heart' },
      { label: 'Reto', value: 'Reto' },
      { label: 'Reyli Barba', value: 'Reyli Barba' },
      { label: 'Rhye', value: 'Rhye' },
      { label: 'Ricardo Arjona', value: 'Ricardo Arjona' },
      { label: 'Ricardo Montaner', value: 'Ricardo Montaner' },
      { label: 'Rich Homie Quan', value: 'Rich Homie Quan' },
      { label: 'Rich The Kid', value: 'Rich The Kid' },
      { label: 'Richard Marx', value: 'Richard Marx' },
      { label: 'Richard Pryor', value: 'Richard Pryor' },
      { label: 'Rick Astley', value: 'Rick Astley' },
      { label: 'Rick James', value: 'Rick James' },
      { label: 'Rick Ross', value: 'Rick Ross' },
      { label: 'Rick Springfield', value: 'Rick Springfield' },
      { label: 'Rick Wakeman', value: 'Rick Wakeman' },
      { label: 'Rickie Lee Jones', value: 'Rickie Lee Jones' },
      { label: 'Ricky Martin', value: 'Ricky Martin' },
      { label: 'Ricky Montgomery', value: 'Ricky Montgomery' },
      { label: 'Ricky Skaggs', value: 'Ricky Skaggs' },
      { label: 'Ricky Van Shelton', value: 'Ricky Van Shelton' },
      { label: 'Rico Nasty', value: 'Rico Nasty' },
      { label: 'Righteous Brothers', value: 'Righteous Brothers' },
      { label: 'Rihanna', value: 'Rihanna' },
      { label: 'Riley Green', value: 'Riley Green' },
      { label: 'Rin', value: 'Rin' },
      { label: 'Ringo Starr', value: 'Ringo Starr' },
      { label: 'Rio Roma', value: 'Rio Roma' },
      { label: 'Rise Against', value: 'Rise Against' },
      { label: 'Rita Coolidge', value: 'Rita Coolidge' },
      { label: 'Rita Ora', value: 'Rita Ora' },
      { label: 'Riton', value: 'Riton' },
      { label: 'Rixton', value: 'Rixton' },
      { label: 'Rk', value: 'Rk' },
      { label: 'Rl Grime', value: 'Rl Grime' },
      { label: 'Rm', value: 'Rm' },
      { label: 'Rob $Tone', value: 'Rob $Tone' },
      { label: 'Rob Base & DJ E-Z Rock', value: 'Rob Base & DJ E-Z Rock' },
      { label: 'Rob Thomas', value: 'Rob Thomas' },
      { label: 'Rob Zombie', value: 'Rob Zombie' },
      { label: 'Robbie Williams', value: 'Robbie Williams' },
      { label: 'Robert Cray', value: 'Robert Cray' },
      { label: 'Robert Johnson', value: 'Robert Johnson' },
      { label: 'Robert Palmer', value: 'Robert Palmer' },
      { label: 'Robert Plant', value: 'Robert Plant' },
      { label: 'Roberta Flack', value: 'Roberta Flack' },
      { label: 'Roberto Carlos', value: 'Roberto Carlos' },
      { label: 'Robin Schulz', value: 'Robin Schulz' },
      { label: 'Robin Thicke', value: 'Robin Thicke' },
      { label: 'Robin Trower', value: 'Robin Trower' },
      { label: 'Robyn', value: 'Robyn' },
      { label: 'Rocco Hunt', value: 'Rocco Hunt' },
      { label: 'Rocio Durcal', value: 'Rocio Durcal' },
      { label: 'Rod Stewart', value: 'Rod Stewart' },
      { label: 'Rod Wave', value: 'Rod Wave' },
      { label: 'Roddy Ricch', value: 'Roddy Ricch' },
      { label: 'Rodney Atkins', value: 'Rodney Atkins' },
      { label: 'Rodney Carrington', value: 'Rodney Carrington' },
      { label: 'Roger', value: 'Roger' },
      { label: 'Roger Miller', value: 'Roger Miller' },
      { label: 'Roger Whittaker', value: 'Roger Whittaker' },
      { label: 'Roger Williams', value: 'Roger Williams' },
      { label: 'Rome', value: 'Rome' },
      { label: 'Romeo Elvis', value: 'Romeo Elvis' },
      { label: 'Romeo Santos', value: 'Romeo Santos' },
      { label: 'Ronan Keating', value: 'Ronan Keating' },
      { label: 'Ronnie Milsap', value: 'Ronnie Milsap' },
      { label: 'Rosa Linn', value: 'Rosa Linn' },
      { label: 'Rosanne Cash', value: 'Rosanne Cash' },
      { label: 'Rose Royce', value: 'Rose Royce' },
      { label: 'Roxette', value: 'Roxette' },
      { label: 'Roxy Music', value: 'Roxy Music' },
      { label: 'Roy Orbison', value: 'Roy Orbison' },
      { label: 'Royal Blood', value: 'Royal Blood' },
      {
        label: 'Royal Philharmonic Orchestra',
        value: 'Royal Philharmonic Orchestra',
      },
      { label: 'Royce Da 59', value: 'Royce Da 59' },
      { label: 'Royksopp', value: 'Royksopp' },
      { label: 'Rudimental', value: 'Rudimental' },
      { label: 'Ruelle', value: 'Ruelle' },
      { label: 'Ruff Ryders', value: 'Ruff Ryders' },
      { label: 'Rufus', value: 'Rufus' },
      { label: 'Rufus Du Sol', value: 'Rufus Du Sol' },
      { label: 'Run The Jewels', value: 'Run The Jewels' },
      { label: 'Rush', value: 'Rush' },
      { label: 'Russ', value: 'Russ' },
      { label: 'Russell Dickerson', value: 'Russell Dickerson' },
      { label: 'Rusted Root', value: 'Rusted Root' },
      { label: 'Ruth B.', value: 'Ruth B.' },
      { label: 'Ryan Adams', value: 'Ryan Adams' },
      { label: 'Ryan Cabrera', value: 'Ryan Cabrera' },
      { label: 'Ryan Castro', value: 'Ryan Castro' },
      { label: 'Rza', value: 'Rza' },
      { label: 'S. P. Balasubrahmanyam', value: 'S. P. Balasubrahmanyam' },
      { label: 'S.O.S. Band', value: 'S.O.S. Band' },
      { label: 'Saba', value: 'Saba' },
      { label: 'Sabaton', value: 'Sabaton' },
      { label: 'Sabrina Carpenter', value: 'Sabrina Carpenter' },
      { label: 'Sabrina Claudio', value: 'Sabrina Claudio' },
      { label: 'Sachin-Jigar', value: 'Sachin-Jigar' },
      { label: 'Sade', value: 'Sade' },
      { label: 'Sage The Gemini', value: 'Sage The Gemini' },
      { label: 'Saiko', value: 'Saiko' },
      { label: 'Saint Jhn', value: 'Saint Jhn' },
      { label: 'Saint Motel', value: 'Saint Motel' },
      { label: 'Salem Ilese', value: 'Salem Ilese' },
      { label: 'Sales', value: 'Sales' },
      { label: 'Saliva', value: 'Saliva' },
      { label: 'Salmo', value: 'Salmo' },
      { label: "Salt 'N Pepa", value: "Salt 'N Pepa" },
      { label: 'Sam Cooke', value: 'Sam Cooke' },
      { label: 'Sam Feldt', value: 'Sam Feldt' },
      { label: 'Sam Fender', value: 'Sam Fender' },
      { label: 'Sam Hunt', value: 'Sam Hunt' },
      { label: 'Sam Martin', value: 'Sam Martin' },
      { label: 'Sam Smith', value: 'Sam Smith' },
      { label: 'Sam Tinnesz', value: 'Sam Tinnesz' },
      { label: 'Samantha Fox', value: 'Samantha Fox' },
      { label: 'Sammy Hagar', value: 'Sammy Hagar' },
      { label: 'Sammy Kershaw', value: 'Sammy Kershaw' },
      { label: 'Sampha', value: 'Sampha' },
      { label: 'San Holo', value: 'San Holo' },
      { label: 'Sanah', value: 'Sanah' },
      { label: 'Sandi Patti', value: 'Sandi Patti' },
      { label: 'Sandro Cavazza', value: 'Sandro Cavazza' },
      { label: 'Santa Fe Klan', value: 'Santa Fe Klan' },
      { label: 'Santana', value: 'Santana' },
      { label: 'Sara Bareilles', value: 'Sara Bareilles' },
      { label: 'Sara Evans', value: 'Sara Evans' },
      { label: 'Sarah Brightman', value: 'Sarah Brightman' },
      { label: 'Sarah McLachlan', value: 'Sarah McLachlan' },
      { label: 'Sasha Alex Sloan', value: 'Sasha Alex Sloan' },
      { label: 'Savage Garden', value: 'Savage Garden' },
      { label: 'Saving Abel', value: 'Saving Abel' },
      { label: 'Saweetie', value: 'Saweetie' },
      { label: 'Sawyer Brown', value: 'Sawyer Brown' },
      { label: 'Scandal', value: 'Scandal' },
      { label: 'Scarface', value: 'Scarface' },
      { label: 'Scarlxrd', value: 'Scarlxrd' },
      { label: 'Sch', value: 'Sch' },
      { label: 'Schoolboy Q', value: 'Schoolboy Q' },
      { label: 'Scooter', value: 'Scooter' },
      { label: 'Scorpions', value: 'Scorpions' },
      { label: 'Scott Stapp', value: 'Scott Stapp' },
      { label: 'Scotty Mccreery', value: 'Scotty Mccreery' },
      { label: 'Sdm', value: 'Sdm' },
      { label: 'Sdp', value: 'Sdp' },
      { label: 'Seal', value: 'Seal' },
      { label: 'Seals & Crofts', value: 'Seals & Crofts' },
      { label: 'Sean Kingston', value: 'Sean Kingston' },
      { label: 'Sean Paul', value: 'Sean Paul' },
      { label: 'Sebastian Ingrosso', value: 'Sebastian Ingrosso' },
      { label: 'Sebastian Yatra', value: 'Sebastian Yatra' },
      { label: 'Sech', value: 'Sech' },
      { label: 'Seeed', value: 'Seeed' },
      { label: 'Seether', value: 'Seether' },
      { label: 'Selah', value: 'Selah' },
      { label: 'Selena', value: 'Selena' },
      { label: 'Selena Gomez', value: 'Selena Gomez' },
      { label: 'Selena Gomez & The Scene', value: 'Selena Gomez & The Scene' },
      { label: 'Semisonic', value: 'Semisonic' },
      { label: 'Sepultura', value: 'Sepultura' },
      {
        label: "Sergio Mendes & Brasil '66",
        value: "Sergio Mendes & Brasil '66",
      },
      { label: 'Seu Jorge', value: 'Seu Jorge' },
      { label: 'Seven Lions', value: 'Seven Lions' },
      { label: 'Seven Mary Three', value: 'Seven Mary Three' },
      { label: 'Sevendust', value: 'Sevendust' },
      { label: 'Seventeen', value: 'Seventeen' },
      { label: 'Sevn Alias', value: 'Sevn Alias' },
      { label: 'Sex Pistols', value: 'Sex Pistols' },
      { label: 'Sg Lewis', value: 'Sg Lewis' },
      { label: 'Shaan', value: 'Shaan' },
      { label: 'Shabba Ranks', value: 'Shabba Ranks' },
      { label: 'Shaed', value: 'Shaed' },
      { label: 'Shaggy', value: 'Shaggy' },
      { label: 'Shakira', value: 'Shakira' },
      { label: 'Shalamar', value: 'Shalamar' },
      { label: 'Shania Twain', value: 'Shania Twain' },
      { label: 'Shankar Mahadevan', value: 'Shankar Mahadevan' },
      { label: "Shaquille O'Neal", value: "Shaquille O'Neal" },
      { label: 'Shaun Cassidy', value: 'Shaun Cassidy' },
      { label: 'Shawn Colvin', value: 'Shawn Colvin' },
      { label: 'Shawn Mendes', value: 'Shawn Mendes' },
      { label: 'Shawn Mullins', value: 'Shawn Mullins' },
      { label: 'Sheck Wes', value: 'Sheck Wes' },
      { label: 'Sheena Easton', value: 'Sheena Easton' },
      { label: 'Sheila E', value: 'Sheila E' },
      { label: 'Sheila On 7', value: 'Sheila On 7' },
      { label: 'Shenandoah', value: 'Shenandoah' },
      { label: 'Sheppard', value: 'Sheppard' },
      { label: 'Sheryl Crow', value: 'Sheryl Crow' },
      { label: 'Shiloh Dynasty', value: 'Shiloh Dynasty' },
      { label: 'Shinedown', value: 'Shinedown' },
      { label: 'Shinee', value: 'Shinee' },
      { label: 'Shirin David', value: 'Shirin David' },
      { label: 'Shiva', value: 'Shiva' },
      { label: 'Shoreline Mafia', value: 'Shoreline Mafia' },
      { label: 'Showtek', value: 'Showtek' },
      { label: 'Shy Glizzy', value: 'Shy Glizzy' },
      { label: 'Sia', value: 'Sia' },
      { label: 'Sid Sriram', value: 'Sid Sriram' },
      { label: 'Siddhartha', value: 'Siddhartha' },
      { label: 'Sidhu Moose Wala', value: 'Sidhu Moose Wala' },
      { label: 'Sido', value: 'Sido' },
      { label: 'Sigala', value: 'Sigala' },
      { label: 'Sigma', value: 'Sigma' },
      { label: 'Sigrid', value: 'Sigrid' },
      { label: 'Silent Sanctuary', value: 'Silent Sanctuary' },
      { label: 'Silk', value: 'Silk' },
      { label: 'Silk Sonic', value: 'Silk Sonic' },
      { label: 'Silkk The Shocker', value: 'Silkk The Shocker' },
      { label: 'Silverchair', value: 'Silverchair' },
      { label: 'Silvestre Dangond', value: 'Silvestre Dangond' },
      { label: 'Silvio Rodriguez', value: 'Silvio Rodriguez' },
      { label: 'Simon & Garfunkel', value: 'Simon & Garfunkel' },
      { label: 'Simone & Simaria', value: 'Simone & Simaria' },
      { label: 'Simone Sommerland', value: 'Simone Sommerland' },
      { label: 'Simple Minds', value: 'Simple Minds' },
      { label: 'Simple Plan', value: 'Simple Plan' },
      { label: 'Simply Red', value: 'Simply Red' },
      { label: 'Sin Bandera', value: 'Sin Bandera' },
      { label: "Sinead O'Connor", value: "Sinead O'Connor" },
      { label: 'Sir', value: 'Sir' },
      { label: 'Sir Mix-A-Lot', value: 'Sir Mix-A-Lot' },
      { label: 'Sisqo', value: 'Sisqo' },
      { label: 'Sister Hazel', value: 'Sister Hazel' },
      { label: 'Sister Sledge', value: 'Sister Sledge' },
      { label: 'Sixpence None The Richer', value: 'Sixpence None The Richer' },
      { label: 'Skank', value: 'Skank' },
      { label: 'Skepta', value: 'Skepta' },
      { label: 'Ski Mask The Slump God', value: 'Ski Mask The Slump God' },
      { label: 'Skid Row', value: 'Skid Row' },
      { label: 'Skillet', value: 'Skillet' },
      { label: 'Skip Marley', value: 'Skip Marley' },
      { label: 'Skrillex', value: 'Skrillex' },
      { label: 'Sky Rompiendo', value: 'Sky Rompiendo' },
      { label: 'Skylar Grey', value: 'Skylar Grey' },
      { label: 'Slaughter', value: 'Slaughter' },
      { label: 'Slave', value: 'Slave' },
      { label: 'Slayer', value: 'Slayer' },
      { label: 'Sleeping At Last', value: 'Sleeping At Last' },
      { label: 'Sleeping With Sirens', value: 'Sleeping With Sirens' },
      { label: 'Slick Rick', value: 'Slick Rick' },
      { label: 'Slimane', value: 'Slimane' },
      { label: 'Slipknot', value: 'Slipknot' },
      { label: 'Sly & The Family Stone', value: 'Sly & The Family Stone' },
      { label: 'Smash Mouth', value: 'Smash Mouth' },
      { label: 'Smino', value: 'Smino' },
      { label: 'Smokepurpp', value: 'Smokepurpp' },
      { label: 'Smokey Robinson', value: 'Smokey Robinson' },
      { label: 'Smokie Norful', value: 'Smokie Norful' },
      { label: 'Smothers Brothers', value: 'Smothers Brothers' },
      { label: 'Snakehips', value: 'Snakehips' },
      { label: 'Snoop Dogg', value: 'Snoop Dogg' },
      { label: 'Snow', value: 'Snow' },
      { label: 'Snow Patrol', value: 'Snow Patrol' },
      { label: 'Sobel', value: 'Sobel' },
      { label: 'Social Distortion', value: 'Social Distortion' },
      { label: 'Soda Stereo', value: 'Soda Stereo' },
      { label: 'Sofi Tukker', value: 'Sofi Tukker' },
      { label: 'Sofia Carson', value: 'Sofia Carson' },
      { label: 'Sofia Reyes', value: 'Sofia Reyes' },
      { label: 'Sofiane', value: 'Sofiane' },
      { label: 'Soft Cell', value: 'Soft Cell' },
      { label: 'Solange', value: 'Solange' },
      { label: 'Sonny & Cher', value: 'Sonny & Cher' },
      { label: 'Sonny Fodera', value: 'Sonny Fodera' },
      { label: 'Soolking', value: 'Soolking' },
      { label: 'Sophie B. Hawkins', value: 'Sophie B. Hawkins' },
      { label: 'Soprano', value: 'Soprano' },
      { label: 'Soul Asylum', value: 'Soul Asylum' },
      { label: 'Soul For Real', value: 'Soul For Real' },
      { label: 'Soul Ii Soul', value: 'Soul Ii Soul' },
      { label: 'Soulja Boy', value: 'Soulja Boy' },
      { label: 'Soundgarden', value: 'Soundgarden' },
      { label: 'Sounds Of Blackness', value: 'Sounds Of Blackness' },
      { label: 'Spice 1', value: 'Spice 1' },
      { label: 'Spice Girls', value: 'Spice Girls' },
      { label: 'Spin Doctors', value: 'Spin Doctors' },
      { label: 'Spinners', value: 'Spinners' },
      { label: 'Spyro Gyra', value: 'Spyro Gyra' },
      { label: 'Squeeze', value: 'Squeeze' },
      { label: 'Squirrel Nut Zippers', value: 'Squirrel Nut Zippers' },
      { label: 'St. Lunatics', value: 'St. Lunatics' },
      { label: 'Stabbing Westward', value: 'Stabbing Westward' },
      { label: 'Staind', value: 'Staind' },
      { label: 'Starship', value: 'Starship' },
      { label: 'Static-X', value: 'Static-X' },
      { label: 'Statler Brothers', value: 'Statler Brothers' },
      { label: 'Steel Pulse', value: 'Steel Pulse' },
      { label: 'Steely Dan', value: 'Steely Dan' },
      { label: 'Stephanie Beatriz', value: 'Stephanie Beatriz' },
      { label: 'Stephanie Mills', value: 'Stephanie Mills' },
      { label: 'Stephen Sanchez', value: 'Stephen Sanchez' },
      { label: 'Stephen Stills', value: 'Stephen Stills' },
      { label: 'Steppenwolf', value: 'Steppenwolf' },
      { label: 'Stereophonics', value: 'Stereophonics' },
      { label: 'Steve Aoki', value: 'Steve Aoki' },
      { label: 'Steve Earle', value: 'Steve Earle' },
      { label: 'Steve Lacy', value: 'Steve Lacy' },
      { label: 'Steve Miller Band', value: 'Steve Miller Band' },
      { label: 'Steve Perry', value: 'Steve Perry' },
      { label: 'Steve Wariner', value: 'Steve Wariner' },
      { label: 'Steve Winwood', value: 'Steve Winwood' },
      { label: 'Steven Curtis Chapman', value: 'Steven Curtis Chapman' },
      { label: 'Stevie B.', value: 'Stevie B.' },
      { label: 'Stevie Nicks', value: 'Stevie Nicks' },
      { label: 'Stevie Ray Vaughan', value: 'Stevie Ray Vaughan' },
      {
        label: 'Stevie Ray Vaughan And Double Trouble',
        value: 'Stevie Ray Vaughan And Double Trouble',
      },
      { label: 'Stevie Wonder', value: 'Stevie Wonder' },
      { label: 'Sticky Fingers', value: 'Sticky Fingers' },
      { label: 'Sting', value: 'Sting' },
      { label: 'Stone Sour', value: 'Stone Sour' },
      { label: 'Stone Temple Pilots', value: 'Stone Temple Pilots' },
      { label: 'Stormzy', value: 'Stormzy' },
      { label: 'Story Of The Year', value: 'Story Of The Year' },
      { label: 'Stray Cats', value: 'Stray Cats' },
      { label: 'Stray Kids', value: 'Stray Kids' },
      { label: 'Stromae', value: 'Stromae' },
      { label: 'Stryper', value: 'Stryper' },
      { label: 'Styles Of Beyond', value: 'Styles Of Beyond' },
      { label: 'Styx', value: 'Styx' },
      { label: 'Sub Urban', value: 'Sub Urban' },
      { label: 'Sublime', value: 'Sublime' },
      { label: 'Sufjan Stevens', value: 'Sufjan Stevens' },
      { label: 'Sugar Ray', value: 'Sugar Ray' },
      { label: 'Sugarland', value: 'Sugarland' },
      { label: 'Sukhwinder Singh', value: 'Sukhwinder Singh' },
      { label: 'Sum 41', value: 'Sum 41' },
      { label: 'Summer Cem', value: 'Summer Cem' },
      { label: 'Summer Walker', value: 'Summer Walker' },
      { label: 'Supertramp', value: 'Supertramp' },
      { label: 'Surf Curse', value: 'Surf Curse' },
      { label: 'Surface', value: 'Surface' },
      { label: 'Survivor', value: 'Survivor' },
      { label: 'Susan Boyle', value: 'Susan Boyle' },
      { label: 'Suzanne Vega', value: 'Suzanne Vega' },
      { label: 'Swae Lee', value: 'Swae Lee' },
      { label: 'Swedish House Mafia', value: 'Swedish House Mafia' },
      { label: 'Switchfoot', value: 'Switchfoot' },
      { label: 'Swizz Beatz', value: 'Swizz Beatz' },
      { label: 'Swv', value: 'Swv' },
      { label: 'Syd', value: 'Syd' },
      { label: 'Syml', value: 'Syml' },
      { label: 'System Of A Down', value: 'System Of A Down' },
      { label: 'Sza', value: 'Sza' },
      { label: 'T-Pain', value: 'T-Pain' },
      { label: 'T.A.T.U.', value: 'T.A.T.U.' },
      { label: 'T.I.', value: 'T.I.' },
      { label: 'Taco Hemingway', value: 'Taco Hemingway' },
      { label: 'Taeyang', value: 'Taeyang' },
      { label: 'Taeyeon', value: 'Taeyeon' },
      { label: 'Tai Verdes', value: 'Tai Verdes' },
      { label: 'Tainy', value: 'Tainy' },
      { label: 'Taio Cruz', value: 'Taio Cruz' },
      { label: 'Take 6', value: 'Take 6' },
      { label: 'Take That', value: 'Take That' },
      { label: 'Takeoff', value: 'Takeoff' },
      { label: 'Taking Back Sunday', value: 'Taking Back Sunday' },
      { label: 'Talking Heads', value: 'Talking Heads' },
      { label: 'Tame Impala', value: 'Tame Impala' },
      { label: 'Tammi Terrell', value: 'Tammi Terrell' },
      { label: 'Tammy Wynette', value: 'Tammy Wynette' },
      { label: 'Tanya Tucker', value: 'Tanya Tucker' },
      { label: 'Tarkan', value: 'Tarkan' },
      { label: 'Tash Sultana', value: 'Tash Sultana' },
      { label: 'Tate Mcrae', value: 'Tate Mcrae' },
      { label: 'Tayc', value: 'Tayc' },
      { label: 'Taylor Dayne', value: 'Taylor Dayne' },
      { label: 'Taylor Hicks', value: 'Taylor Hicks' },
      { label: 'Taylor Swift', value: 'Taylor Swift' },
      { label: 'Tears For Fears', value: 'Tears For Fears' },
      { label: 'Tech N9Ne', value: 'Tech N9Ne' },
      { label: 'Technotronic', value: 'Technotronic' },
      { label: 'Ted Nugent', value: 'Ted Nugent' },
      { label: 'Teddy Pendergrass', value: 'Teddy Pendergrass' },
      { label: 'Teddy Swims', value: 'Teddy Swims' },
      { label: 'Tee Grizzley', value: 'Tee Grizzley' },
      { label: 'Teena Marie', value: 'Teena Marie' },
      { label: 'Temple Of The Dog', value: 'Temple Of The Dog' },
      { label: 'Ten Years After', value: 'Ten Years After' },
      { label: 'Tenacious D', value: 'Tenacious D' },
      { label: "Terence Trent D'Arby", value: "Terence Trent D'Arby" },
      { label: 'Terri Clark', value: 'Terri Clark' },
      { label: 'Tesla', value: 'Tesla' },
      { label: 'Tevin Campbell', value: 'Tevin Campbell' },
      { label: 'Teyana Taylor', value: 'Teyana Taylor' },
      { label: 'Tha Dogg Pound', value: 'Tha Dogg Pound' },
      { label: 'Thalia', value: 'Thalia' },
      { label: 'The 1975', value: 'The 1975' },
      { label: 'The Alchemist', value: 'The Alchemist' },
      { label: 'The All-American Rejects', value: 'The All-American Rejects' },
      { label: 'The Animals', value: 'The Animals' },
      { label: 'The Association', value: 'The Association' },
      { label: 'The Avett Brothers', value: 'The Avett Brothers' },
      { label: 'The Band', value: 'The Band' },
      { label: 'The Band Perry', value: 'The Band Perry' },
      { label: 'The Bangles', value: 'The Bangles' },
      { label: 'The Beach Boys', value: 'The Beach Boys' },
      { label: 'The Beatles', value: 'The Beatles' },
      { label: 'The Bellamy Brothers', value: 'The Bellamy Brothers' },
      { label: 'The Black Crowes', value: 'The Black Crowes' },
      { label: 'The Black Keys', value: 'The Black Keys' },
      { label: 'The Boys', value: 'The Boys' },
      { label: 'The Breeders', value: 'The Breeders' },
      { label: 'The Byrds', value: 'The Byrds' },
      { label: 'The Cardigans', value: 'The Cardigans' },
      { label: 'The Carpenters', value: 'The Carpenters' },
      { label: 'The Cars', value: 'The Cars' },
      { label: 'The Chainsmokers', value: 'The Chainsmokers' },
      { label: 'The Chemical Brothers', value: 'The Chemical Brothers' },
      { label: 'The Chicks', value: 'The Chicks' },
      { label: 'The Chieftains', value: 'The Chieftains' },
      { label: 'The Cinematic Orchestra', value: 'The Cinematic Orchestra' },
      { label: 'The Civil Wars', value: 'The Civil Wars' },
      { label: 'The Clash', value: 'The Clash' },
      { label: 'The Commitments', value: 'The Commitments' },
      { label: 'The Corrs', value: 'The Corrs' },
      { label: 'The Cranberries', value: 'The Cranberries' },
      { label: 'The Crystal Method', value: 'The Crystal Method' },
      { label: 'The Cult', value: 'The Cult' },
      { label: 'The Cure', value: 'The Cure' },
      { label: 'The Doobie Brothers', value: 'The Doobie Brothers' },
      { label: 'The Doors', value: 'The Doors' },
      { label: 'The Drifters', value: 'The Drifters' },
      {
        label: 'The Fabulous Thunderbirds',
        value: 'The Fabulous Thunderbirds',
      },
      { label: 'The Fixx', value: 'The Fixx' },
      { label: 'The Floaters', value: 'The Floaters' },
      { label: 'The Fray', value: 'The Fray' },
      { label: 'The Game', value: 'The Game' },
      { label: 'The Glitch Mob', value: 'The Glitch Mob' },
      { label: 'The Goo Goo Dolls', value: 'The Goo Goo Dolls' },
      {
        label: 'The Greatest Showman Ensemble',
        value: 'The Greatest Showman Ensemble',
      },
      { label: 'The Head And The Heart', value: 'The Head And The Heart' },
      { label: 'The Highwaymen', value: 'The Highwaymen' },
      { label: 'The Hollies', value: 'The Hollies' },
      { label: 'The Honeydrippers', value: 'The Honeydrippers' },
      { label: 'The Hooters', value: 'The Hooters' },
      { label: 'The Internet', value: 'The Internet' },
      { label: 'The Isley Brothers', value: 'The Isley Brothers' },
      { label: 'The Jacksons', value: 'The Jacksons' },
      { label: 'The Jerky Boys', value: 'The Jerky Boys' },
      { label: 'The Jets', value: 'The Jets' },
      { label: 'The Jordanaires', value: 'The Jordanaires' },
      { label: 'The Judds', value: 'The Judds' },
      { label: 'The Kid Laroi', value: 'The Kid Laroi' },
      { label: 'The Killers', value: 'The Killers' },
      { label: 'The Kinks', value: 'The Kinks' },
      { label: 'The Knack', value: 'The Knack' },
      { label: 'The Knocks', value: 'The Knocks' },
      { label: 'The Kooks', value: 'The Kooks' },
      { label: 'The Lemonheads', value: 'The Lemonheads' },
      { label: 'The Lettermen', value: 'The Lettermen' },
      { label: 'The Living Tombstone', value: 'The Living Tombstone' },
      { label: 'The Lonely Island', value: 'The Lonely Island' },
      { label: 'The Lumineers', value: 'The Lumineers' },
      { label: 'The Magician', value: 'The Magician' },
      { label: 'The Mamas & The Papas', value: 'The Mamas & The Papas' },
      { label: 'The Manhattans', value: 'The Manhattans' },
      { label: 'The Marias', value: 'The Marias' },
      { label: 'The Marshall Tucker Band', value: 'The Marshall Tucker Band' },
      { label: 'The Mavericks', value: 'The Mavericks' },
      { label: 'The Monkees', value: 'The Monkees' },
      { label: 'The Moody Blues', value: 'The Moody Blues' },
      { label: 'The Motels', value: 'The Motels' },
      { label: 'The National', value: 'The National' },
      { label: 'The Neighbourhood', value: 'The Neighbourhood' },
      { label: 'The Notorious B.I.G.', value: 'The Notorious B.I.G.' },
      { label: 'The Offspring', value: 'The Offspring' },
      { label: 'The Osmonds', value: 'The Osmonds' },
      { label: 'The Outfield', value: 'The Outfield' },
      { label: 'The Outlaws', value: 'The Outlaws' },
      { label: 'The Paper Kites', value: 'The Paper Kites' },
      { label: 'The Piano Guys', value: 'The Piano Guys' },
      { label: 'The Platters', value: 'The Platters' },
      { label: 'The Pointer Sisters', value: 'The Pointer Sisters' },
      { label: 'The Police', value: 'The Police' },
      { label: 'The Power Station', value: 'The Power Station' },
      { label: 'The Proclaimers', value: 'The Proclaimers' },
      { label: 'The Prodigy', value: 'The Prodigy' },
      { label: 'The Pussycat Dolls', value: 'The Pussycat Dolls' },
      {
        label: 'The Red Jumpsuit Apparatus',
        value: 'The Red Jumpsuit Apparatus',
      },
      { label: 'The Rembrandts', value: 'The Rembrandts' },
      { label: 'The Rolling Stones', value: 'The Rolling Stones' },
      { label: 'The Ronettes', value: 'The Ronettes' },
      { label: 'The Roots', value: 'The Roots' },
      { label: 'The Score', value: 'The Score' },
      { label: 'The Script', value: 'The Script' },
      { label: 'The Shins', value: 'The Shins' },
      { label: 'The Simpsons', value: 'The Simpsons' },
      { label: 'The Smashing Pumpkins', value: 'The Smashing Pumpkins' },
      { label: 'The Smiths', value: 'The Smiths' },
      { label: 'The Strokes', value: 'The Strokes' },
      { label: 'The Stylistics', value: 'The Stylistics' },
      { label: 'The Sundays', value: 'The Sundays' },
      { label: 'The Supremes', value: 'The Supremes' },
      { label: 'The Temper Trap', value: 'The Temper Trap' },
      { label: 'The Temptations', value: 'The Temptations' },
      { label: 'The Three Tenors', value: 'The Three Tenors' },
      { label: 'The Time', value: 'The Time' },
      { label: 'The Tractors', value: 'The Tractors' },
      { label: 'The Traveling Wilburys', value: 'The Traveling Wilburys' },
      { label: 'The Used', value: 'The Used' },
      { label: 'The Vamps', value: 'The Vamps' },
      { label: 'The Velvet Underground', value: 'The Velvet Underground' },
      { label: 'The Ventures', value: 'The Ventures' },
      { label: 'The Verve', value: 'The Verve' },
      { label: 'The Verve Pipe', value: 'The Verve Pipe' },
      { label: 'The Wallflowers', value: 'The Wallflowers' },
      { label: 'The Wanted', value: 'The Wanted' },
      { label: 'The War On Drugs', value: 'The War On Drugs' },
      { label: 'The Weeknd', value: 'The Weeknd' },
      { label: 'The Whispers', value: 'The Whispers' },
      { label: 'The White Stripes', value: 'The White Stripes' },
      { label: 'The Who', value: 'The Who' },
      { label: 'The Wiggles', value: 'The Wiggles' },
      { label: 'The Wombats', value: 'The Wombats' },
      { label: 'The Xx', value: 'The Xx' },
      { label: 'The-Dream', value: 'The-Dream' },
      { label: 'Theory Of A Deadman', value: 'Theory Of A Deadman' },
      { label: 'They Might Be Giants', value: 'They Might Be Giants' },
      { label: 'Thin Lizzy', value: 'Thin Lizzy' },
      { label: 'Third Day', value: 'Third Day' },
      { label: 'Third Eye Blind', value: 'Third Eye Blind' },
      { label: 'Thirty Seconds To Mars', value: 'Thirty Seconds To Mars' },
      {
        label: 'Thirty-Eight Special (.38)',
        value: 'Thirty-Eight Special (.38)',
      },
      { label: 'Thomas Rhett', value: 'Thomas Rhett' },
      { label: 'Thompson Twins', value: 'Thompson Twins' },
      { label: 'Thousand Foot Krutch', value: 'Thousand Foot Krutch' },
      { label: 'Three 6 Mafia', value: 'Three 6 Mafia' },
      { label: 'Three Days Grace', value: 'Three Days Grace' },
      { label: 'Three Dog Night', value: 'Three Dog Night' },
      { label: 'Thundercat', value: 'Thundercat' },
      { label: 'Tiago Pzk', value: 'Tiago Pzk' },
      { label: 'Tiesto', value: 'Tiesto' },
      { label: 'Tiffany', value: 'Tiffany' },
      { label: 'Til Tuesday', value: 'Til Tuesday' },
      { label: 'Tim Mcgraw', value: 'Tim Mcgraw' },
      { label: 'Timbaland', value: 'Timbaland' },
      { label: 'Timbaland And Magoo', value: 'Timbaland And Magoo' },
      { label: 'Timeflies', value: 'Timeflies' },
      { label: 'Timmy Trumpet', value: 'Timmy Trumpet' },
      { label: 'Tina Turner', value: 'Tina Turner' },
      { label: 'Tinashe', value: 'Tinashe' },
      { label: 'Tini', value: 'Tini' },
      { label: 'Tinie Tempah', value: 'Tinie Tempah' },
      { label: 'Tion Wayne', value: 'Tion Wayne' },
      { label: 'Tito El Bambino', value: 'Tito El Bambino' },
      { label: 'Tiziano Ferro', value: 'Tiziano Ferro' },
      { label: 'Tlc', value: 'Tlc' },
      { label: 'Toad The Wet Sprocket', value: 'Toad The Wet Sprocket' },
      { label: 'Toadies', value: 'Toadies' },
      { label: 'Toby Keith', value: 'Toby Keith' },
      { label: 'Tobymac', value: 'Tobymac' },
      { label: 'Tom Grennan', value: 'Tom Grennan' },
      { label: 'Tom Jones', value: 'Tom Jones' },
      { label: 'Tom Macdonald', value: 'Tom Macdonald' },
      { label: 'Tom Misch', value: 'Tom Misch' },
      { label: 'Tom Odell', value: 'Tom Odell' },
      { label: 'Tom Petty', value: 'Tom Petty' },
      {
        label: 'Tom Petty And The Heartbreakers',
        value: 'Tom Petty And The Heartbreakers',
      },
      { label: 'Tom Rosenthal', value: 'Tom Rosenthal' },
      { label: 'Tom Tom Club', value: 'Tom Tom Club' },
      { label: 'Tom Waits', value: 'Tom Waits' },
      { label: 'Tom Walker', value: 'Tom Walker' },
      { label: 'Tommee Profitt', value: 'Tommee Profitt' },
      { label: 'Tomorrow X Together', value: 'Tomorrow X Together' },
      { label: 'Tone Loc', value: 'Tone Loc' },
      { label: 'Tones And I', value: 'Tones And I' },
      { label: 'Toni Braxton', value: 'Toni Braxton' },
      { label: 'Tony Bennett', value: 'Tony Bennett' },
      { label: 'Tony Orlando & Dawn', value: 'Tony Orlando & Dawn' },
      { label: 'Tony Rich Project', value: 'Tony Rich Project' },
      { label: 'Tony! Toni! Tone!', value: 'Tony! Toni! Tone!' },
      { label: 'Too $Hort', value: 'Too $Hort' },
      { label: 'Tool', value: 'Tool' },
      { label: 'Toosii', value: 'Toosii' },
      { label: 'Topic', value: 'Topic' },
      { label: 'Toploader', value: 'Toploader' },
      { label: 'Tori Amos', value: 'Tori Amos' },
      { label: 'Tori Kelly', value: 'Tori Kelly' },
      { label: 'Toro Y Moi', value: 'Toro Y Moi' },
      { label: 'Tory Lanez', value: 'Tory Lanez' },
      { label: 'Total', value: 'Total' },
      { label: 'Toto', value: 'Toto' },
      { label: 'Tove Lo', value: 'Tove Lo' },
      { label: 'Trace Adkins', value: 'Trace Adkins' },
      { label: 'Tracy Byrd', value: 'Tracy Byrd' },
      { label: 'Tracy Chapman', value: 'Tracy Chapman' },
      { label: 'Tracy Lawrence', value: 'Tracy Lawrence' },
      { label: 'Traffic', value: 'Traffic' },
      { label: 'Train', value: 'Train' },
      { label: 'Trans-Siberian Orchestra', value: 'Trans-Siberian Orchestra' },
      { label: 'Trapt', value: 'Trapt' },
      { label: 'Travie Mccoy', value: 'Travie Mccoy' },
      { label: 'Travis Barker', value: 'Travis Barker' },
      { label: 'Travis Scott', value: 'Travis Scott' },
      { label: 'Travis Tritt', value: 'Travis Tritt' },
      { label: 'Treasure', value: 'Treasure' },
      { label: 'Trevor Daniel', value: 'Trevor Daniel' },
      { label: 'Trey Songz', value: 'Trey Songz' },
      { label: 'Tribo Da Periferia', value: 'Tribo Da Periferia' },
      { label: 'Trippie Redd', value: 'Trippie Redd' },
      { label: 'Trisha Yearwood', value: 'Trisha Yearwood' },
      { label: 'Tritonal', value: 'Tritonal' },
      { label: 'Triumph', value: 'Triumph' },
      { label: 'Trivium', value: 'Trivium' },
      { label: 'Troye Sivan', value: 'Troye Sivan' },
      { label: 'Tru', value: 'Tru' },
      { label: 'Turma Do Pagode', value: 'Turma Do Pagode' },
      { label: 'Tv Girl', value: 'Tv Girl' },
      { label: 'Twenty One Pilots', value: 'Twenty One Pilots' },
      { label: 'Twice', value: 'Twice' },
      { label: 'Twista', value: 'Twista' },
      { label: 'Twisted Sister', value: 'Twisted Sister' },
      { label: 'Two Door Cinema Club', value: 'Two Door Cinema Club' },
      { label: 'Two Feet', value: 'Two Feet' },
      { label: 'Ty Dolla $Ign', value: 'Ty Dolla $Ign' },
      { label: 'Ty Herndon', value: 'Ty Herndon' },
      { label: 'Tyga', value: 'Tyga' },
      { label: 'Tyler Childers', value: 'Tyler Childers' },
      { label: 'Type O Negative', value: 'Type O Negative' },
      { label: 'Tyrese', value: 'Tyrese' },
      { label: 'U2', value: 'U2' },
      { label: 'UB40', value: 'UB40' },
      { label: 'Udit Narayan', value: 'Udit Narayan' },
      { label: 'Ugly Kid Joe', value: 'Ugly Kid Joe' },
      { label: 'Ultimo', value: 'Ultimo' },
      { label: 'Uncle Kracker', value: 'Uncle Kracker' },
      { label: 'Underoath', value: 'Underoath' },
      { label: 'Unknown Mortal Orchestra', value: 'Unknown Mortal Orchestra' },
      { label: 'Unlike Pluto', value: 'Unlike Pluto' },
      { label: 'Upchurch', value: 'Upchurch' },
      { label: 'Uriah Heep', value: 'Uriah Heep' },
      { label: 'Us3', value: 'Us3' },
      { label: 'Usa For Africa', value: 'Usa For Africa' },
      { label: 'Usher', value: 'Usher' },
      { label: 'Vacations', value: 'Vacations' },
      { label: 'Vald', value: 'Vald' },
      { label: 'Valentin Elizalde', value: 'Valentin Elizalde' },
      { label: 'Vampire Weekend', value: 'Vampire Weekend' },
      { label: 'Van Cliburn', value: 'Van Cliburn' },
      { label: 'Van Halen', value: 'Van Halen' },
      { label: 'Van Morrison', value: 'Van Morrison' },
      { label: 'Vance Joy', value: 'Vance Joy' },
      { label: 'Vanesa Martin', value: 'Vanesa Martin' },
      { label: 'Vanessa Carlton', value: 'Vanessa Carlton' },
      { label: 'Vanessa Williams', value: 'Vanessa Williams' },
      { label: 'Vangelis', value: 'Vangelis' },
      { label: 'Vanilla Ice', value: 'Vanilla Ice' },
      { label: 'Vasco Rossi', value: 'Vasco Rossi' },
      { label: 'Vassy', value: 'Vassy' },
      { label: 'Vaughan Brothers', value: 'Vaughan Brothers' },
      { label: 'Velvet Revolver', value: 'Velvet Revolver' },
      { label: 'Vengaboys', value: 'Vengaboys' },
      { label: 'Vern Gosdin', value: 'Vern Gosdin' },
      { label: 'Veronica Maggio', value: 'Veronica Maggio' },
      { label: 'Vertical Horizon', value: 'Vertical Horizon' },
      { label: 'Veysel', value: 'Veysel' },
      { label: 'Vianney', value: 'Vianney' },
      { label: 'Vic Mensa', value: 'Vic Mensa' },
      { label: 'Vicente Fernandez', value: 'Vicente Fernandez' },
      { label: 'Vicetone', value: 'Vicetone' },
      { label: 'Victor Manuelle', value: 'Victor Manuelle' },
      { label: 'Victoria Monet', value: 'Victoria Monet' },
      { label: 'Village People', value: 'Village People' },
      { label: 'Vinai', value: 'Vinai' },
      { label: 'Vince Gill', value: 'Vince Gill' },
      { label: 'Vince Guaraldi Trio', value: 'Vince Guaraldi Trio' },
      { label: 'Vince Staples', value: 'Vince Staples' },
      { label: 'Vintage Culture', value: 'Vintage Culture' },
      { label: 'Violent Femmes', value: 'Violent Femmes' },
      { label: 'Vitamin C', value: 'Vitamin C' },
      { label: 'Volbeat', value: 'Volbeat' },
      { label: 'Vybz Kartel', value: 'Vybz Kartel' },
      { label: 'W.A.S.P.', value: 'W.A.S.P.' },
      { label: 'W&W', value: 'W&W' },
      { label: 'Waka Flocka Flame', value: 'Waka Flocka Flame' },
      { label: 'Wale', value: 'Wale' },
      { label: 'Walk Off The Earth', value: 'Walk Off The Earth' },
      { label: 'Walk The Moon', value: 'Walk The Moon' },
      { label: 'Walker Hayes', value: 'Walker Hayes' },
      { label: 'Wallows', value: 'Wallows' },
      { label: 'War', value: 'War' },
      { label: 'Warrant', value: 'Warrant' },
      { label: 'Warren G', value: 'Warren G' },
      { label: 'Warren Zevon', value: 'Warren Zevon' },
      { label: 'Waylon Jennings', value: 'Waylon Jennings' },
      { label: 'We The Kings', value: 'We The Kings' },
      { label: 'Weather Report', value: 'Weather Report' },
      { label: 'Weezer', value: 'Weezer' },
      { label: 'Wendy Carlos', value: 'Wendy Carlos' },
      { label: 'Werenoi', value: 'Werenoi' },
      { label: 'Wesley Safadao', value: 'Wesley Safadao' },
      { label: 'Westlife', value: 'Westlife' },
      { label: 'Westside Connection', value: 'Westside Connection' },
      { label: 'Wham!', value: 'Wham!' },
      { label: 'White 2115', value: 'White 2115' },
      { label: 'White Lion', value: 'White Lion' },
      { label: 'White Noise Baby Sleep', value: 'White Noise Baby Sleep' },
      { label: 'White Zombie', value: 'White Zombie' },
      { label: 'Whitesnake', value: 'Whitesnake' },
      { label: 'Whitney Houston', value: 'Whitney Houston' },
      { label: 'Whodini', value: 'Whodini' },
      { label: 'Why Dont We', value: 'Why Dont We' },
      { label: 'Wilbur Soot', value: 'Wilbur Soot' },
      { label: 'Wild Cherry', value: 'Wild Cherry' },
      { label: 'Will Smith', value: 'Will Smith' },
      { label: 'Will.I.Am', value: 'Will.I.Am' },
      { label: 'William Singe', value: 'William Singe' },
      { label: 'Willie Colon', value: 'Willie Colon' },
      { label: 'Willie Nelson', value: 'Willie Nelson' },
      { label: 'Willy William', value: 'Willy William' },
      { label: 'Wilson Phillips', value: 'Wilson Phillips' },
      { label: 'Winger', value: 'Winger' },
      { label: 'Wings', value: 'Wings' },
      { label: 'Wisin', value: 'Wisin' },
      { label: 'Wisin & Yandel', value: 'Wisin & Yandel' },
      { label: 'Within Temptation', value: 'Within Temptation' },
      { label: 'Wiz Khalifa', value: 'Wiz Khalifa' },
      { label: 'Wizkid', value: 'Wizkid' },
      { label: 'Wolfgang Amadeus Mozart', value: 'Wolfgang Amadeus Mozart' },
      { label: "Wreckx 'N' Effect", value: "Wreckx 'N' Effect" },
      { label: 'Wu-Tang Clan', value: 'Wu-Tang Clan' },
      { label: 'Wwe', value: 'Wwe' },
      { label: 'Wyclef Jean', value: 'Wyclef Jean' },
      { label: 'Wynonna Judd', value: 'Wynonna Judd' },
      { label: 'Wynton Marsalis', value: 'Wynton Marsalis' },
      { label: 'X Ambassadors', value: 'X Ambassadors' },
      { label: 'Xama', value: 'Xama' },
      { label: 'Xavier Wulf', value: 'Xavier Wulf' },
      { label: 'Ximena Sarinana', value: 'Ximena Sarinana' },
      { label: 'Xscape', value: 'Xscape' },
      { label: 'Xxxtentacion', value: 'Xxxtentacion' },
      { label: 'Xzibit', value: 'Xzibit' },
      { label: 'Yandel', value: 'Yandel' },
      { label: 'Yann Tiersen', value: 'Yann Tiersen' },
      { label: 'Yanni', value: 'Yanni' },
      { label: 'Yeah Yeah Yeahs', value: 'Yeah Yeah Yeahs' },
      { label: 'Yebba', value: 'Yebba' },
      { label: 'Yelawolf', value: 'Yelawolf' },
      { label: 'Yellow Claw', value: 'Yellow Claw' },
      { label: 'Yellowcard', value: 'Yellowcard' },
      { label: 'Yes', value: 'Yes' },
      { label: 'Yfn Lucci', value: 'Yfn Lucci' },
      { label: 'Yg', value: 'Yg' },
      { label: 'Ying Yang Twins', value: 'Ying Yang Twins' },
      { label: 'Yiruma', value: 'Yiruma' },
      { label: 'Yo Gotti', value: 'Yo Gotti' },
      { label: 'Yo Yo Honey Singh', value: 'Yo Yo Honey Singh' },
      { label: 'Yo-Yo Ma', value: 'Yo-Yo Ma' },
      { label: 'Yoasobi', value: 'Yoasobi' },
      { label: 'Yolanda Adams', value: 'Yolanda Adams' },
      { label: 'Yorushika', value: 'Yorushika' },
      { label: 'Yot Club', value: 'Yot Club' },
      { label: 'Young Buck', value: 'Young Buck' },
      { label: 'Young Dolph', value: 'Young Dolph' },
      { label: 'Young Igi', value: 'Young Igi' },
      { label: 'Young Jeezy', value: 'Young Jeezy' },
      { label: 'Young M.A', value: 'Young M.A' },
      { label: 'Young M.C.', value: 'Young M.C.' },
      { label: 'Young Miko', value: 'Young Miko' },
      { label: 'Young Money', value: 'Young Money' },
      { label: 'Young Nudy', value: 'Young Nudy' },
      { label: 'Young The Giant', value: 'Young The Giant' },
      { label: 'Young Thug', value: 'Young Thug' },
      {
        label: 'Youngboy Never Broke Again',
        value: 'Youngboy Never Broke Again',
      },
      { label: 'Yung Bleu', value: 'Yung Bleu' },
      { label: 'Yung Hurn', value: 'Yung Hurn' },
      { label: 'Yung Joc', value: 'Yung Joc' },
      { label: 'Yung Lean', value: 'Yung Lean' },
      { label: 'Yungblud', value: 'Yungblud' },
      { label: 'Yuri', value: 'Yuri' },
      { label: 'Yuuri', value: 'Yuuri' },
      { label: 'Yuvan Shankar Raja', value: 'Yuvan Shankar Raja' },
      { label: 'Zac Brown Band', value: 'Zac Brown Band' },
      { label: 'Zach Bryan', value: 'Zach Bryan' },
      { label: 'Zapp', value: 'Zapp' },
      { label: 'Zapp & Roger', value: 'Zapp & Roger' },
      { label: 'Zara Larsson', value: 'Zara Larsson' },
      { label: 'Zayn', value: 'Zayn' },
      { label: 'Zaz', value: 'Zaz' },
      { label: 'Ze Neto & Cristiano', value: 'Ze Neto & Cristiano' },
      { label: 'Ze Ramalho', value: 'Ze Ramalho' },
      { label: 'Zedd', value: 'Zedd' },
      { label: 'Zendaya', value: 'Zendaya' },
      {
        label: 'Zeze Di Camargo & Luciano',
        value: 'Zeze Di Camargo & Luciano',
      },
      { label: 'Zhu', value: 'Zhu' },
      {
        label: 'Ziggy Marley & Melody Makers',
        value: 'Ziggy Marley & Melody Makers',
      },
      { label: 'Zion', value: 'Zion' },
      { label: 'Zoe', value: 'Zoe' },
      { label: 'Zoe Wees', value: 'Zoe Wees' },
      { label: 'Zola', value: 'Zola' },
      { label: 'ZZ Top', value: 'ZZ Top' },
    ],
  },
};
