import { render, staticRenderFns } from "./DemographicSelections.vue?vue&type=template&id=4e2ec3d1&scoped=true&"
import script from "./DemographicSelections.vue?vue&type=script&lang=js&"
export * from "./DemographicSelections.vue?vue&type=script&lang=js&"
import style0 from "./DemographicSelections.vue?vue&type=style&index=0&id=4e2ec3d1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2ec3d1",
  null
  
)

export default component.exports