exports = module.exports = [
  {
    title: 'Gender',
    children: [
      {
        label: 'Male',
        value: 'gender=\'M\'',
      },
      {
        label: 'Female',
        value: 'gender=\'F\'',
      },
    ],
  },
  {
    title: 'Age',
    children: [
      {
        label: '18-24',
        value: 'estimated_age_code=\'A\'',
      },
      {
        label: '25-29',
        value: 'estimated_age_code=\'B\'',
      },
      {
        label: '30-34',
        value: 'estimated_age_code=\'C\'',
      },
      {
        label: '35-39',
        value: 'estimated_age_code=\'D\'',
      },
      {
        label: '40-44',
        value: 'estimated_age_code=\'E\'',
      },
      {
        label: '45-49',
        value: 'estimated_age_code=\'F\'',
      },
      {
        label: '50-54',
        value: 'estimated_age_code=\'G\'',
      },
      {
        label: '55-59',
        value: 'estimated_age_code=\'H\'',
      },
      {
        label: '60-64',
        value: 'estimated_age_code=\'I\'',
      },
      {
        label: '65-69',
        value: 'estimated_age_code=\'J\'',
      },
      {
        label: '70+',
        value: 'estimated_age_code=\'K\'',
      },
    ],
  },
  {
    title: 'Children in Household',
    children: [
      {
        label: 'One or more children',
        value: 'presence_of_children=true',
      },
      {
        label: 'No children',
        value: 'presence_of_children=false',
      },
    ],
  },
  {
    title: 'Home Owner',
    children: [
      {
        label: 'Home owner',
        value: 'home_owner=true',
      },
      {
        label: 'Not a home owner',
        value: 'home_owner=false',
      },
    ],
  },
  {
    title: 'Dwelling Type',
    children: [
      {
        label: 'Multi-Family Dwelling', //Apartment
        value: 'dwelling_type=\'A\'',
      },
      // {
      //   label:`BLDG`,
      //    value:`dwelling_type='B'`,
      //  },
      {
        label: 'Single Family Household', //SFDU
        value: 'dwelling_type=\'C\'',
      },
      // ,
      //{
      //  label:`RR or PO Box`,
      //   value:`dwelling_type='D'`,
      // },
      //{
      //  label:`Lot`,
      //   value:`dwelling_type='E'`,
      // },
      //{
      //  label:`Hanger`,
      //   value:`dwelling_type='F'`,
      // },
      //{
      //  label:`Pier`,
      //   value:`dwelling_type='G'`,
      // },
      //{
      //  label:`Suites`,
      //   value:`dwelling_type='H'`,
      // },
      //{
      //  label:`Trailer`,
      //   value:`dwelling_type='I'`,
      // },
      //{
      //  label:`Unit`,
      //   value:`dwelling_type='J'`,
      // },
      //  {
      //  label:`Wing`,
      //   value:`dwelling_type='K'`,
      // },
      //{
      //  label:`Stop`,
      //   value:`dwelling_type='L'`,
      // }
    ],
  },
  {
    title: 'Presence Of',
    children: [
      //  {
      //  label:'Presence of a satellite dish at individual's household',
      //  value:`presence_of_sat_dish=true`,
      //  },
      {
        label: 'Presence of single adult in household of individual',
        value: 'presence_of_single_adult=true',
      },
      //{
      //  label:`Presence of young adult in individual's household`,
      //  value:`presence_of_young_adult='1'`,
      //  },
      {
        label: 'Presence of senior adult in individual\'s household',
        value: 'presence_senior_adult=true',
      },
      {
        label: 'Presence of a veteran in individual\'s household',
        value: 'presence_veteran=true',
      },
    ],
  },
  {
    title: 'Education',
    children: [
      {
        label: 'Completed High School',
        value: 'education=1',
      },
      {
        label: 'Some College',
        value: 'education=2',
      },
      {
        label: 'Completed College',
        value: 'education=3',
      },
      {
        label: 'Completed Graduate School',
        value: 'education=4',
      },
      {
        label: 'Attended Vocational/Technical',
        value: 'education=5',
      },
    ],
  },

  {
    title: 'Married',
    children: [
      {
        label: 'Married',
        value: 'married=true',
      },
      {
        label: 'Not married',
        value: 'married=false',
      },
    ],
  },

  {
    title: 'Ethnicity',
    children: [
      {
        label: 'African-American',
        value: 'ethnicity=\'I\'',
      },
      {
        label: 'Arabic',
        value: 'ethnicity=\'A\'',
      },
      {
        label: 'Caucasian',
        value: 'ethnicity=\'J\'',
      },
      {
        label: 'Chinese',
        value: 'ethnicity=\'B\'',
      },
      {
        label: 'Hindu',
        value: 'ethnicity=\'C\'',
      },
      {
        label: 'Hispanic',
        value: 'ethnicity=\'D\'',
      },
      {
        label: 'Japanese',
        value: 'ethnicity=\'E\'',
      },
      {
        label: 'Korean',
        value: 'ethnicity=\'F\'',
      },
      {
        label: 'Russian',
        value: 'ethnicity=\'G\'',
      },
      {
        label: 'Vietnamese',
        value: 'ethnicity=\'H\'',
      },
    ],
  },

  {
    title: 'Household Size',
    children: [
      {
        label: '1',
        value: 'household_size=1',
      },
      {
        label: '2',
        value: 'household_size=2',
      },
      {
        label: '3',
        value: 'household_size=3',
      },
      {
        label: '4',
        value: 'household_size=4',
      },
      {
        label: '5',
        value: 'household_size=5',
      },
      {
        label: '6+',
        value: 'household_size=6',
      },
    ],
  },

  {
    title: 'Occupation',
    children: [
      {
        label: 'Accountant',
        value: 'occup_biz_owner=\'A\'',
      },
      {
        label: 'Business Owner',
        value: 'occup_biz_owner=\'B\'',
      },
      {
        label: 'Contractor',
        value: 'occup_biz_owner=\'C\'',
      },
      {
        label: 'Doctor',
        value: 'occupation=\'49\'',
      },
      {
        label: 'Farming/Agriculture',
        value: 'occupation=\'15\'',
      },
      {
        label: 'Financial Services',
        value: 'occupation=\'18\'',
      },
      {
        label: 'Middle Management',
        value: 'occupation=\'4\'',
      },
      {
        label: 'Office Clerical',
        value: 'occupation=\'6\'',
      },
      {
        label: 'Professional/Technical',
        value: 'occupation=\'2\'',
      },
      {
        label: 'Retired',
        value: 'occupation=\'8\'',
      },
      {
        label: 'Sales/Marketing',
        value: 'occupation=\'5\'',
      },
      {
        label: 'Self-Employed',
        value: 'occup_biz_owner=\'J\'',
      },
      {
        label: 'Skilled Trade',
        value: 'occupation=\'7\'',
      },
      {
        label: 'Student',
        value: 'occupation=\'55\'',
      },
      {
        label: 'Teacher/Educator',
        value: 'occupation=\'21\'',
      },
    ],
  },
];
