import charities from './charities';
import demographicSegments from './demographic-segments';
import entertainment from './entertainment';
import ethnicities from './ethnicity';
import financials from './financials';
import interests from './interests';
import msa from './msa';
import states from './states';
import political from './political';
import politicalDistricts from './political-districts';
import purchaseIntenders from './purchase-intenders';

export default {
  charities,
  demographicSegments,
  entertainment,
  ethnicities,
  financials,
  interests,
  msa,
  states,
  political,
  politicalDistricts,
  purchaseIntenders,
};
