exports = module.exports = [
  {
    title: 'Party Affiliation Probability',
    tooltip: 'This is a sliding scale based on Aristotle data. People sometimes change the party of their vote so it’s best to use the scale to capture voters that are likely to be associated with a certain party. The independent category will capture unaffiliated voters and can be paired with conservative or liberal leaning options if building an audience of unaffiliated voters who may lean one way or the other. If you need both a left leaning and a right leaning audience, create two separate audiences.',
    children: [
      {
        label: 'Conservative',
        value: 'mod_prty_aff_con_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Conservative',
      },
      {
        label: 'Democrat',
        value: 'mod_prty_aff_dem_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Democrat',
      },
      {
        label: 'Green',
        value: 'mod_prty_aff_grn_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Green',
      },
      {
        label: 'Independent',
        value: 'mod_prty_aff_ind_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Independent',
      },
      {
        label: 'Libertarian',
        value: 'mod_prty_aff_lbt_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Libertarian',
      },
      {
        label: 'Liberal',
        value: 'mod_prty_aff_lib_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Liberal',
      },
      {
        label: 'Republican',
        value: 'mod_prty_aff_rep_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Affiliation Probability: Republican',
      },
    ],
  },
  {
    title: ' Political Donations',
    tooltip: 'This data is modeled from public donation records reported to the FEC. This data is based on individual voters, not corporate or mega-donors.',
    children: [
      {
        label: 'State Election Donation Probability',
        value: 'mod_state_don_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'State Donation Probability',
      },
      {
        label: 'State Election Donation Amount Percentile',
        value: 'mod_state_don_amt_pct_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'State Donation Percentile',
      },
      {
        label: 'State Election Donation Amount Prediction',
        value: 'mod_state_don_amt_pred_mb',
        range: [0, 10000],
        step: 50,
        sign: '$',
        alt: 'State Donation Amount',
      },
      {
        label: 'Federal Election Donation Probability',
        value: 'mod_fed_don_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Federal Donation Probability',
      },
      {
        label: 'Federal Election Donation Amount Percentile',
        value: 'mod_fed_don_amt_pct_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Federal Donation Percentile',
      },
      {
        label: 'Federal Election Donation Amount Prediction',
        value: 'mod_fed_don_amt_pred_mb',
        range: [0, 35500],
        step: 50,
        sign: '$',
        alt: 'Federal Donation Amount',
      },
    ],
  },
  {
    title: 'Turnout Probability',
    tooltip: 'The sliding scale for turnout probability is modeled data based on historical voting trends. If trying to capture voters who tend to turnout for certain elections, choose the higher end of the scale. If trying to capture voters that don’t tend to turn out for certain elections, select the lower end of the scale.',
    children: [
      {
        label: 'Midterm Primary',
        value: 'mod_to_mtpri_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Turnout: Midterm Primary',
      },
      {
        label: 'Midterm General',
        value: 'mod_to_mtgen_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Turnout: Midterm General',
      },
      {
        label: 'Presidential Primary',
        value: 'mod_to_ppri_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Turnout: Presidential Primary',
      },
      {
        label: 'Presidential General',
        value: 'mod_to_pgen_prb_mb',
        range: [0, 100],
        step: 1,
        sign: '%',
        alt: 'Turnout: Presidential General',
      },
    ],
  },
];
