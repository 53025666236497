exports = module.exports = [
  {
    title: 'Has Credit Card',
    children: [
      {
        label: 'Yes',
        alt: 'Has Credit Card',
        value: 'cc_credit=true',
      },
      {
        label: 'no',
        alt: 'No Credit Card',
        value: 'cc-credit=false',
      },
    ],
  },
  {
    title: 'Credit Card Type',
    children: [
      {
        label: 'Gas',
        alt: 'Credit: Gas',
        value: 'cc_combined_gas=true',
      },
      {
        label: 'High Limit',
        alt: 'Credit: High Limit',
        value: 'cc_combined_premium=true',
      },
      {
        label: 'Standard',
        alt: 'Credit: Standard',
        value: 'cc_combined_credit=true',
      },
      {
        label: 'Rewards',
        alt: 'Credit: Rewards',
        value: 'cc_combined_travel=true',
      },
    ],
  },
  {
    title: 'Household Income',
    children: [
      {
        label: 'Under $15,000',
        alt: 'Income: Under $15,000',
        value: 'household_income_code=\'A\'',
      },
      {
        label: '$15,000 - $24,999',
        alt: 'Income: $15,000 - $24,999',
        value: 'household_income_code=\'B\'',
      },
      {
        label: '$25,000 - $34,999',
        alt: 'Income: $25,000 - $34,999',
        value: 'household_income_code=\'C\'',
      },
      {
        label: '$35,000 - $49,999',
        alt: 'Income: $35,000 - $49,999',
        value: 'household_income_code=\'D\'',
      },
      {
        label: '$50,000 - $74,999',
        alt: 'Income: $50,000 - $74,999',
        value: 'household_income_code=\'E\'',
      },
      {
        label: '$75,000 - $99,999',
        alt: 'Income: $75,000 - $99,999',
        value: 'household_income_code=\'F\'',
      },
      {
        label: '$100,000 - $149,999',
        alt: 'Income: $100,000 - $149,999',
        value: 'household_income_code=\'G\'',
      },
      {
        label: '$150,000 - $199,999',
        alt: 'Income: $150,000 - $199,999',
        value: 'household_income_code=\'H\'',
      },
      {
        label: '$200,000 - $249,999',
        alt: 'Income: $200,000 - $249,999',
        value: 'household_income_code=\'K\'',
      },
      {
        label: '$250,000+',
        alt: 'Income: $250,000+',
        value: 'household_income_code=\'L\'',
      },
    ],
  },
  {
    title: 'Net Worth',
    children: [
      {
        label: 'Less Than $50,000',
        alt: 'Net Worth: Less Than $50,000',
        value: 'networth_code=\'A\'',
      },
      {
        label: '$50,000 - $99,999',
        alt: 'Net Worth: $50,000 - $99,999',
        value: 'networth_code=\'B\'',
      },
      {
        label: '$100,000 - $249,999',
        alt: 'Net Worth: $100,000 - $249,999',
        value: 'networth_code=\'C\'',
      },
      {
        label: '$250,000 - $499,999',
        alt: 'Net Worth: $250,000 - $499,999',
        value: 'networth_code=\'D\'',
      },
      {
        label: '$500,000+',
        alt: 'Net Worth: $500,000+',
        value: 'networth_code=\'E\'',
      },
    ],
  },
  {
    title: 'Home Value',
    children: [
      {
        label: 'Under $50,000',
        alt: 'Value: Under $50,000',
        value: 'home_value_code=\'A\'',
      },
      {
        label: '$50,000 - $99,999',
        alt: 'Value: $50,000 - $99,999',
        value: 'home_value_code=\'B\'',
      },
      {
        label: '$100,000 - $149,999',
        alt: 'Value: $100,000 - $149,999',
        value: 'home_value_code=\'C\'',
      },
      {
        label: '$150,000 - $199,999',
        alt: 'Value: $150,000 - $199,999',
        value: 'home_value_code=\'D\'',
      },
      {
        label: '$200,000 - $249,999',
        alt: 'Value: $200,000 - $249,999',
        value: 'home_value_code=\'E\'',
      },
      {
        label: '$250,000 - $299,999',
        alt: 'Value: $250,000 - $299,999',
        value: 'home_value_code=\'F\'',
      },
      {
        label: '$300,000 - $349,999',
        alt: 'Value: $330,000 - $349,999',
        value: 'home_value_code=\'G\'',
      },
      {
        label: '$350,000 - $399,999',
        alt: 'Value: $350,000 - $399,999',
        value: 'home_value_code=\'H\'',
      },
      {
        label: '$400,000 - $449,999',
        alt: 'Value: $400,000 - $449,999',
        value: 'home_value_code=\'I\'',
      },
      {
        label: '$450,000 - $499,999',
        alt: 'Value: $450,000 - $499,999',
        value: 'home_value_code=\'J\'',
      },
      {
        label: '$500,000 - $749,999',
        alt: 'Value: $500,000 - $749,999',
        value: 'home_value_code=\'K\'',
      },
      {
        label: '$750,000 - $999,999',
        alt: 'Value: $750,000 - $999,999',
        value: 'home_value_code=\'L\'',
      },
      {
        label: '$1 Million+',
        alt: 'Value: $1 Million+',
        value: 'home_value_code=\'M\'',
      },
    ],
  },
  {
    title: 'Mortgage Type',
    children: [
      {
        label: 'Community Development Authority',
        alt: 'Mortgage: Community Development Authority',
        value: 'most_recent_mortgage_type=\'A\'',
      },
      {
        label: 'Conventional',
        alt: 'Mortgage: Conventional',
        value: 'most_recent_mortgage_type=\'B\'',
      },
      {
        label: 'FHA',
        alt: 'Mortgage: FHA',
        value: 'most_recent_mortgage_type=\'C\'',
      },
      {
        label: 'Private Party Leader',
        alt: 'Mortgage: Private Party Leader',
        value: 'most_recent_mortgage_type=\'D\'',
      },
      {
        label: 'Small Business Administration',
        alt: 'Mortgage: Small Business Administration',
        value: 'most_recent_mortgage_type=\'E\'',
      },
      {
        label: 'VA',
        alt: 'Mortgage: VA',
        value: 'most_recent_mortgage_type=\'F\'',
      },
      {
        label: 'Wrap-Around Mortgage',
        alt: 'Mortgage: Wrap-Around',
        value: 'most_recent_mortgage_type=\'G\'',
      },
    ],
  },
];
