exports = module.exports = [
  {
    title: 'African',
    children: [
      {
        label: 'Algerian',
        value: 'M',
      },
      {
        label: 'Angolan',
        value: 'O',
      },
      {
        label: 'Black African',
        value: 'AC',
      },
      {
        label: 'Botswanian',
        value: 'AE',
      },
      {
        label: 'Burkina Faso',
        value: 'AG',
      },
      {
        label: 'Cameroonian',
        value: 'AI',
      },
      {
        label: 'Central African Republic',
        value: 'AK',
      },
      {
        label: 'Chadian',
        value: 'AL',
      },
      {
        label: 'Congolese',
        value: 'AO',
      },
      {
        label: 'Ethiopian',
        value: 'AY',
      },
      {
        label: 'Gabon',
        value: 'BC',
      },
      {
        label: 'Gambian',
        value: 'BD',
      },
      {
        label: 'Ghanaian',
        value: 'BG',
      },
      {
        label: 'Guinean',
        value: 'BI',
      },
      {
        label: 'Hausa',
        value: 'BL',
      },
      {
        label: 'Ivorian',
        value: 'BT',
      },
      {
        label: 'Kenyan',
        value: 'BX',
      },
      {
        label: 'Liberian',
        value: 'CF',
      },
      {
        label: 'Libyan',
        value: 'CG',
      },
      {
        label: 'Madagascan',
        value: 'CL',
      },
      {
        label: 'Mozambican',
        value: 'CW',
      },
      {
        label: 'Moroccan',
        value: 'CV',
      },
      {
        label: 'Nigerian',
        value: 'DD',
      },
      {
        label: 'Rwandan',
        value: 'DQ',
      },
      {
        label: 'Senegalese',
        value: 'DT',
      },
      {
        label: 'Sierre Leone',
        value: 'DW',
      },
      {
        label: 'Somalian',
        value: 'EA',
      },
      {
        label: 'Sudanese',
        value: 'EC',
      },
      {
        label: 'Swaziland',
        value: 'EE',
      },
      {
        label: 'Ugandan',
        value: 'ET',
      },
      {
        label: 'Zairean',
        value: 'FB',
      },
      {
        label: 'Zambian',
        value: 'FC',
      },
      {
        label: 'Zimbabwean',
        value: 'FD',
      },
      {
        label: 'Zulu',
        value: 'FE',
      },
    ],
  },
  {
    title: 'African American',
    children: [
      {
        label: 'African-American',
        value: 'J',
      },
      {
        label: 'Caribbean African American',
        value: 'AJ',
      },
    ],
  },
  {
    title: 'American Indian',
    children: [
      {
        label: 'American Indian',
        value: 'N',
      },
    ],
  },
  {
    title: 'Asian',
    children: [
      {
        label: 'Bangladesh',
        value: 'V',
      },
      {
        label: 'Chinese',
        value: 'B',
      },
      {
        label: 'Korean',
        value: 'F',
      },
      {
        label: 'Japanese',
        value: 'E',
      },
      {
        label: 'Myanmar (Burmese)',
        value: 'CY',
      },
      {
        label: 'Pakistani',
        value: 'DH',
      },
      {
        label: 'Philippine',
        value: 'DK',
      },
      {
        label: 'Thai',
        value: 'EM',
      },
      {
        label: 'Tibetan',
        value: 'EN',
      },
      {
        label: 'Vietnamese',
        value: 'H',
      },
      {
        label: 'Other Asian',
        value: 'DG',
      },
    ],
  },
  {
    title: 'European',
    children: [
      {
        label: 'Albanian',
        value: 'K',
      },
      {
        label: 'Austrian',
        value: 'S',
      },
      {
        label: 'Basque',
        value: 'X',
      },
      {
        label: 'Belgian',
        value: 'Y',
      },
      {
        label: 'Belorussian',
        value: 'Z',
      },
      {
        label: 'Bulgarian',
        value: 'AF',
      },
      {
        label: 'Croatian',
        value: 'AP',
      },
      {
        label: 'Czech',
        value: 'AQ',
      },
      {
        label: 'Danish',
        value: 'AR',
      },
      {
        label: 'Dutch',
        value: 'AT',
      },
      {
        label: 'Estonian',
        value: 'AX',
      },
      {
        label: 'Finnish',
        value: 'BA',
      },
      {
        label: 'French',
        value: 'BB',
      },
      {
        label: 'Georgian',
        value: 'BE',
      },
      {
        label: 'German',
        value: 'BF',
      },
      {
        label: 'Greek',
        value: 'BH',
      },
      {
        label: 'Hungarian',
        value: 'BN',
      },
      {
        label: 'Italian',
        value: 'BS',
      },
      {
        label: 'Latvian',
        value: 'CD',
      },
      {
        label: 'Lithuanian',
        value: 'CI',
      },
      {
        label: 'Luxembourgian',
        value: 'CJ',
      },
      {
        label: 'Polish',
        value: 'DM',
      },
      {
        label: 'Portuguese',
        value: 'DN',
      },
      {
        label: 'Romanian',
        value: 'DP',
      },
      {
        label: 'Russian',
        value: 'G',
      },
      {
        label: 'Serbian',
        value: 'DU',
      },
      {
        label: 'Swedish',
        value: 'EF',
      },
      {
        label: 'Swiss',
        value: 'EG',
      },
      {
        label: 'Urkrainian',
        value: 'EU',
      },
      {
        label: 'Maltese',
        value: 'CQ',
      },
    ],
  },
  {
    title: 'European English Speaking',
    children: [
      {
        label: 'English',
        value: 'AV',
      },
      {
        label: 'Irish',
        value: 'BR',
      },
      {
        label: 'Welsh',
        value: 'EX',
      },
      {
        label: 'Scottish',
        value: 'DS',
      },
    ],
  },
  {
    title: 'Hispanic',
    children: [
      {
        label: 'Hispanic',
        value: 'D',
      },
    ],
  },
  {
    title: 'Middle Eastern',
    children: [
      {
        label: 'Arabic',
        value: 'A',
      },
      {
        label: 'Armenian',
        value: 'P',
      },
      {
        label: 'Afghan',
        value: 'I',
      },
      {
        label: 'Azerbaijani',
        value: 'T',
      },
      {
        label: 'Egyptian',
        value: 'AU',
      },
      {
        label: 'Iraqi',
        value: 'BQ',
      },
      {
        label: 'Kazakhstan',
        value: 'BW',
      },
      {
        label: 'Kirghizia',
        value: 'BY',
      },
      {
        label: 'Kurdish',
        value: 'BZ',
      },
      {
        label: 'Kuwaiti',
        value: 'CA',
      },
      {
        label: 'Persian',
        value: 'DJ',
      },
      {
        label: 'Qatari',
        value: 'DO',
      },
      {
        label: 'Saudi',
        value: 'DR',
      },
      {
        label: 'Turkish',
        value: 'ER',
      },
    ],
  },
  {
    title: 'Religion',
    children: [
      {
        label: 'Bosnian Muslim',
        value: 'AD',
      },
      {
        label: 'Hindu',
        value: 'C',
      },
      {
        label: 'Jewish',
        value: 'BU',
      },
    ],
  },
  {
    title: 'South Pacific',
    children: [
      {
        label: 'Australian',
        value: 'R',
      },
      {
        label: 'Hawaiian',
        value: 'BM',
      },
      {
        label: 'Indonesian',
        value: 'BP',
      },
      {
        label: 'Maldivian Tongan',
        value: 'CO',
      },
      {
        label: 'New Zealand',
        value: 'DC',
      },
      {
        label: 'Papua New Guinean',
        value: 'DI',
      },
      {
        label: 'Seychelles',
        value: 'DV',
      },
      {
        label: 'Sinhalese Sri Lankan',
        value: 'DX',
      },
      {
        label: 'Western Samoan',
        value: 'EY',
      },
    ],
  },
];
