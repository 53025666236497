exports = module.exports = [
  {
    label: 'Abilene, TX',
    value: 40,
  },
  {
    label: 'Albany, GA',
    value: 120,
  },
  {
    label: 'Albany-Schenectady-Troy, NY',
    value: 160,
  },
  {
    label: 'Albuquerque, NM',
    value: 200,
  },
  {
    label: 'Alexandria, LA',
    value: 220,
  },
  {
    label: 'Allentown-Bethlehem-Easton',
    value: 240,
  },
  {
    label: 'Altoona, PA',
    value: 280,
  },
  {
    label: 'Amarillo, TX',
    value: 320,
  },
  {
    label: 'Anchorage, AK',
    value: 380,
  },
  {
    label: 'Anniston, AL',
    value: 450,
  },
  {
    label: 'Appleton-Oshkosh-Neenah, WI',
    value: 460,
  },
  {
    label: 'Asheville, NC',
    value: 480,
  },
  {
    label: 'Athens, GA',
    value: 500,
  },
  {
    label: 'Atlanta, GA',
    value: 520,
  },
  {
    label: 'Auburn-Opelika, AL',
    value: 580,
  },
  {
    label: 'Augusta-Aiken, GA-SC',
    value: 600,
  },
  {
    label: 'Austin-San Marcos, TX',
    value: 640,
  },
  {
    label: 'Bakersfield, CA',
    value: 680,
  },
  {
    label: 'Bangor, ME',
    value: 730,
  },
  {
    label: 'Barnstable-Yarmouth, MA',
    value: 740,
  },
  {
    label: 'Baton Rouge, LA',
    value: 760,
  },
  {
    label: 'Beaumont-Port Arthur, TX',
    value: 840,
  },
  {
    label: 'Bellingham, WA',
    value: 860,
  },
  {
    label: 'Benton Harbor, MI',
    value: 870,
  },
  {
    label: 'Billings, MT',
    value: 880,
  },
  {
    label: 'Biloxi-Gulfport',
    value: 920,
  },
  {
    label: 'Binghamton, NY',
    value: 960,
  },
  {
    label: 'Birmingham, AL',
    value: 1000,
  },
  {
    label: 'Bismarck, ND',
    value: 1010,
  },
  {
    label: 'Bloomington, IN',
    value: 1020,
  },
  {
    label: 'Bloomington-Normal, IL',
    value: 1040,
  },
  {
    label: 'Boise City, ID',
    value: 1080,
  },
  {
    label: 'Boston, MA-NH',
    value: 1122,
  },
  {
    label: 'Brownsville-Harlingen',
    value: 1240,
  },
  {
    label: 'Bryan-College Station, TX',
    value: 1260,
  },
  {
    label: 'Buffalo-Niagara Falls, NY',
    value: 1280,
  },
  {
    label: 'Burlington, VT',
    value: 1305,
  },
  {
    label: 'Canton-Massillon, OH',
    value: 1320,
  },
  {
    label: 'Casper, WY',
    value: 1350,
  },
  {
    label: 'Cedar Rapids, IA',
    value: 1360,
  },
  {
    label: 'Champaign-Urbana, IL',
    value: 1400,
  },
  {
    label: 'Charleston-North Charleston, SC',
    value: 1440,
  },
  {
    label: 'Charleston, WV',
    value: 1480,
  },
  {
    label: 'Charlotte-Gastonia-Rock Hill',
    value: 1520,
  },
  {
    label: 'Charlottesville, VA',
    value: 1540,
  },
  {
    label: 'Chattanooga, TN-GA',
    value: 1560,
  },
  {
    label: 'Cheyenne, WY',
    value: 1580,
  },
  {
    label: 'Chicago-Gary-Kenosha, IL-IN-WI',
    value: 1602,
  },
  {
    label: 'Chico-Paradise, CA',
    value: 1620,
  },
  {
    label: 'Cincinnati-Hamilton, OH-KY-IN',
    value: 1642,
  },
  {
    label: 'Clarksville-Hopkinsville',
    value: 1660,
  },
  {
    label: 'Cleveland-Akron, OH',
    value: 1692,
  },
  {
    label: 'Colorado Springs, CO',
    value: 1720,
  },
  {
    label: 'Columbia, MO',
    value: 1740,
  },
  {
    label: 'Columbia, SC',
    value: 1760,
  },
  {
    label: 'Columbus, GA-AL',
    value: 1800,
  },
  {
    label: 'Columbus, OH',
    value: 1840,
  },
  {
    label: 'Corpus Christi, TX',
    value: 1880,
  },
  {
    label: 'Corvallis, OR',
    value: 1890,
  },
  {
    label: 'Cumberland, MD-WV',
    value: 1900,
  },
  {
    label: 'Dallas-Fort Worth, TX',
    value: 1922,
  },
  {
    label: 'Danville, VA',
    value: 1950,
  },
  {
    label: 'Davenport-Moline',
    value: 1960,
  },
  {
    label: 'Dayton-Springfield, OH',
    value: 2000,
  },
  {
    label: 'Daytona Beach, FL',
    value: 2020,
  },
  {
    label: 'Decatur, AL',
    value: 2030,
  },
  {
    label: 'Decatur, IL',
    value: 2040,
  },
  {
    label: 'Denver, CO',
    value: 2082,
  },
  {
    label: 'Des Moines, IA',
    value: 2120,
  },
  {
    label: 'Detroit, MI',
    value: 2162,
  },
  {
    label: 'Dothan, AL',
    value: 2180,
  },
  {
    label: 'Dover, DE',
    value: 2190,
  },
  {
    label: 'Dubuque, IA',
    value: 2200,
  },
  {
    label: 'Duluth-Superior, MN-WI',
    value: 2240,
  },
  {
    label: 'Eau Claire, WI',
    value: 2290,
  },
  {
    label: 'El Paso, TX',
    value: 2320,
  },
  {
    label: 'Elkhart-Goshen, IN',
    value: 2330,
  },
  {
    label: 'Elmira, NY',
    value: 2335,
  },
  {
    label: 'Enid, OK',
    value: 2340,
  },
  {
    label: 'Erie, PA',
    value: 2360,
  },
  {
    label: 'Eugene-Springfield, OR',
    value: 2400,
  },
  {
    label: 'Evansville-Henderson, IN-KY',
    value: 2440,
  },
  {
    label: 'Fargo-Moorhead, ND-MN',
    value: 2520,
  },
  {
    label: 'Fayetteville, NC',
    value: 2560,
  },
  {
    label: 'Fayetteville-Springdale',
    value: 2580,
  },
  {
    label: 'Flagstaff, AZ-UT',
    value: 2620,
  },
  {
    label: 'Florence, AL',
    value: 2650,
  },
  {
    label: 'Florence, SC',
    value: 2655,
  },
  {
    label: 'Fort Collins-Loveland, CO',
    value: 2670,
  },
  {
    label: 'Fort Myers-Cape Coral, FL',
    value: 2700,
  },
  {
    label: 'Fort Pierce-Port St. Lucie,',
    value: 2710,
  },
  {
    label: 'Fort Smith, AR-OK',
    value: 2720,
  },
  {
    label: 'Fort Walton Beach, FL',
    value: 2750,
  },
  {
    label: 'Fort Wayne, IN',
    value: 2760,
  },
  {
    label: 'Fresno, CA',
    value: 2840,
  },
  {
    label: 'Gadsden, AL',
    value: 2880,
  },
  {
    label: 'Gainesville, FL',
    value: 2900,
  },
  {
    label: 'Glens Falls, NY',
    value: 2975,
  },
  {
    label: 'Goldsboro, NC',
    value: 2980,
  },
  {
    label: 'Grand Forks, ND-MN',
    value: 2985,
  },
  {
    label: 'Grand Junction, CO',
    value: 2995,
  },
  {
    label: 'Grand Rapids-Muskegon',
    value: 3000,
  },
  {
    label: 'Great Falls, MT',
    value: 3040,
  },
  {
    label: 'Green Bay, WI',
    value: 3080,
  },
  {
    label: 'Greensboro-Winston-Salem',
    value: 3120,
  },
  {
    label: 'Greenville, NC',
    value: 3150,
  },
  {
    label: 'Greenville-Spartanburg',
    value: 3160,
  },
  {
    label: 'Harrisburg-Lebanon',
    value: 3240,
  },
  {
    label: 'Hartford, CT',
    value: 3280,
  },
  {
    label: 'Hattiesburg, MS',
    value: 3285,
  },
  {
    label: 'Hickory-Morganton',
    value: 3290,
  },
  {
    label: 'Honolulu, HI',
    value: 3320,
  },
  {
    label: 'Houma, LA',
    value: 3350,
  },
  {
    label: 'Houston-Galveston-Brazoria, TX',
    value: 3362,
  },
  {
    label: 'Huntington-Ashland, WV-KY-OH',
    value: 3400,
  },
  {
    label: 'Huntsville, AL',
    value: 3440,
  },
  {
    label: 'Indianapolis, IN',
    value: 3480,
  },
  {
    label: 'Iowa City, IA',
    value: 3500,
  },
  {
    label: 'Jackson, MI',
    value: 3520,
  },
  {
    label: 'Jackson, MS',
    value: 3560,
  },
  {
    label: 'Jackson, TN',
    value: 3580,
  },
  {
    label: 'Jacksonville, FL',
    value: 3600,
  },
  {
    label: 'Jacksonville, NC',
    value: 3605,
  },
  {
    label: 'Jamestown, NY',
    value: 3610,
  },
  {
    label: 'Janesville-Beloit, WI',
    value: 3620,
  },
  {
    label: 'Johnson City-Kingsport',
    value: 3660,
  },
  {
    label: 'Johnstown, PA',
    value: 3680,
  },
  {
    label: 'Jonesboro, AR',
    value: 3700,
  },
  {
    label: 'Joplin, MO',
    value: 3710,
  },
  {
    label: 'Kalamazoo-Battle Creek, MI',
    value: 3720,
  },
  {
    label: 'Kansas City, MO-KS',
    value: 3760,
  },
  {
    label: 'Killeen-Temple, TX',
    value: 3810,
  },
  {
    label: 'Knoxville, TN',
    value: 3840,
  },
  {
    label: 'Kokomo, IN',
    value: 3850,
  },
  {
    label: 'La Crosse, WI-MN',
    value: 3870,
  },
  {
    label: 'Lafayette, LA',
    value: 3880,
  },
  {
    label: 'Lafayette, IN',
    value: 3920,
  },
  {
    label: 'Lake Charles, LA',
    value: 3960,
  },
  {
    label: 'Lakeland-Winter Haven, FL',
    value: 3980,
  },
  {
    label: 'Lancaster, PA',
    value: 4000,
  },
  {
    label: 'Lansing-East Lansing, MI',
    value: 4040,
  },
  {
    label: 'Laredo, TX',
    value: 4080,
  },
  {
    label: 'Las Cruces, NM',
    value: 4100,
  },
  {
    label: 'Las Vegas, NV-AZ',
    value: 4120,
  },
  {
    label: 'Lawrence, KS',
    value: 4150,
  },
  {
    label: 'Lawton, OK',
    value: 4200,
  },
  {
    label: 'Lewiston-Auburn, ME',
    value: 4240,
  },
  {
    label: 'Lexington, KY',
    value: 4280,
  },
  {
    label: 'Lima, OH',
    value: 4320,
  },
  {
    label: 'Lincoln, NE',
    value: 4360,
  },
  {
    label: 'Little Rock-North Little',
    value: 4400,
  },
  {
    label: 'Longview-Marshall, TX',
    value: 4420,
  },
  {
    label: 'Los Angeles-Riverside-Orange County, CA',
    value: 4472,
  },
  {
    label: 'Louisville, KY-IN',
    value: 4520,
  },
  {
    label: 'Lubbock, TX',
    value: 4600,
  },
  {
    label: 'Lynchburg, VA',
    value: 4640,
  },
  {
    label: 'Macon, GA',
    value: 4680,
  },
  {
    label: 'Madison, WI',
    value: 4720,
  },
  {
    label: 'Mansfield, OH',
    value: 4800,
  },
  {
    label: 'McAllen-Edinburg-Mission, TX',
    value: 4880,
  },
  {
    label: 'Medford-Ashland, OR',
    value: 4890,
  },
  {
    label: 'Melbourne-Titusville',
    value: 4900,
  },
  {
    label: 'Memphis, TN-AR-MS',
    value: 4920,
  },
  {
    label: 'Merced, CA',
    value: 4940,
  },
  {
    label: 'Miami-Fort Lauderdale, FL',
    value: 4992,
  },
  {
    label: 'Milwaukee-Racine, WI',
    value: 5082,
  },
  {
    label: 'Minneapolis-St. Paul, MN-WI',
    value: 5120,
  },
  {
    label: 'Missoula, MT',
    value: 5140,
  },
  {
    label: 'Mobile, AL',
    value: 5160,
  },
  {
    label: 'Modesto, CA',
    value: 5170,
  },
  {
    label: 'Monroe, LA',
    value: 5200,
  },
  {
    label: 'Montgomery, AL',
    value: 5240,
  },
  {
    label: 'Muncie, IN',
    value: 5280,
  },
  {
    label: 'Myrtle Beach, SC',
    value: 5330,
  },
  {
    label: 'Naples, FL',
    value: 5345,
  },
  {
    label: 'Nashville, TN',
    value: 5360,
  },
  {
    label: 'New London-Norwich, CT-RI',
    value: 5520,
  },
  {
    label: 'New Orleans, LA',
    value: 5560,
  },
  {
    label: 'New York-Northern New Jersey-Long Island, NY-NJ-CT',
    value: 5602,
  },
  {
    label: 'Norfolk-Virginia Beach-Newport',
    value: 5720,
  },
  {
    label: 'Ocala, FL',
    value: 5790,
  },
  {
    label: 'Odessa-Midland, TX',
    value: 5800,
  },
  {
    label: 'Oklahoma City, OK',
    value: 5880,
  },
  {
    label: 'Omaha, NE-IA',
    value: 5920,
  },
  {
    label: 'Orlando, FL',
    value: 5960,
  },
  {
    label: 'Owensboro, KY',
    value: 5990,
  },
  {
    label: 'Panama City, FL',
    value: 6015,
  },
  {
    label: 'Parkersurg-Marietta, WV-OH',
    value: 6020,
  },
  {
    label: 'Pensacola, FL',
    value: 6080,
  },
  {
    label: 'Peoria-Pekin, IL',
    value: 6120,
  },
  {
    label: 'Philadelphia-Wilmington-Atlantic City, PA-NJ',
    value: 6162,
  },
  {
    label: 'Phoenix-Mesa, AZ',
    value: 6200,
  },
  {
    label: 'Pine Bluff, AR',
    value: 6240,
  },
  {
    label: 'Pittsburgh, PA',
    value: 6280,
  },
  {
    label: 'Pittsfield, MA',
    value: 6320,
  },
  {
    label: 'Pocatello, ID',
    value: 6340,
  },
  {
    label: 'Portland, ME',
    value: 6400,
  },
  {
    label: 'Portland-Salem, OR-WA',
    value: 6442,
  },
  {
    label: 'Providence-Fall River',
    value: 6480,
  },
  {
    label: 'Provo-Orem, UT',
    value: 6520,
  },
  {
    label: 'Pueblo, CO',
    value: 6560,
  },
  {
    label: 'Punta Gorda, FL',
    value: 6580,
  },
  {
    label: 'Raleigh-Durham',
    value: 6640,
  },
  {
    label: 'Rapid City, SD',
    value: 6660,
  },
  {
    label: 'Reading, PA',
    value: 6680,
  },
  {
    label: 'Redding, CA',
    value: 6690,
  },
  {
    label: 'Reno, NV',
    value: 6720,
  },
  {
    label: 'Richland-Kennewick-Pasco, WA',
    value: 6740,
  },
  {
    label: 'Richmond-Petersburg, VA',
    value: 6760,
  },
  {
    label: 'Roanoke, VA',
    value: 6800,
  },
  {
    label: 'Rochester, MN',
    value: 6820,
  },
  {
    label: 'Rochester, NY',
    value: 6840,
  },
  {
    label: 'Rockford, IL',
    value: 6880,
  },
  {
    label: 'Rocky Mount, NC',
    value: 6895,
  },
  {
    label: 'Sacramento-Yolo, CA',
    value: 6922,
  },
  {
    label: 'Saginaw-Bay City-Midland, MI',
    value: 6960,
  },
  {
    label: 'St. Cloud, MN',
    value: 6980,
  },
  {
    label: 'St. Joseph, MO',
    value: 7000,
  },
  {
    label: 'St. Louis, MO-IL',
    value: 7040,
  },
  {
    label: 'Salinas, CA',
    value: 7120,
  },
  {
    label: 'Salt Lake City-Ogden, UT',
    value: 7160,
  },
  {
    label: 'San Angelo, TX',
    value: 7200,
  },
  {
    label: 'San Antonio, TX',
    value: 7240,
  },
  {
    label: 'San Diego, CA',
    value: 7320,
  },
  {
    label: 'San Francisco-Oakland-San Jose, CA',
    value: 7362,
  },
  {
    label: 'San Luis Obispo-Atascadero',
    value: 7460,
  },
  {
    label: 'Santa Barbara-Santa Maria',
    value: 7480,
  },
  {
    label: 'Santa Fe, NM',
    value: 7490,
  },
  {
    label: 'Sarasota-Bradenton, FL',
    value: 7510,
  },
  {
    label: 'Savannah, GA',
    value: 7520,
  },
  {
    label: 'Scranton-Wilkes-Barre',
    value: 7560,
  },
  {
    label: 'Seattle-Tacoma-Bremerton, WA',
    value: 7602,
  },
  {
    label: 'Sharon, PA',
    value: 7610,
  },
  {
    label: 'Sheboygan, WI',
    value: 7620,
  },
  {
    label: 'Sherman-Denison, TX',
    value: 7640,
  },
  {
    label: 'Shreveport-Bossier City, LA',
    value: 7680,
  },
  {
    label: 'Sioux City, IA-NE',
    value: 7720,
  },
  {
    label: 'Sioux Falls, SD',
    value: 7760,
  },
  {
    label: 'South Bend, IN',
    value: 7800,
  },
  {
    label: 'Spokane, WA',
    value: 7840,
  },
  {
    label: 'Springfield, IL',
    value: 7880,
  },
  {
    label: 'Springfield, MO',
    value: 7920,
  },
  {
    label: 'Springfield, MA',
    value: 8000,
  },
  {
    label: 'State College, PA',
    value: 8050,
  },
  {
    label: 'Steubenville-Weirton, OH-WV',
    value: 8080,
  },
  {
    label: 'Stockton-Lodi, CA',
    value: 8120,
  },
  {
    label: 'Sumter, SC',
    value: 8140,
  },
  {
    label: 'Syracuse, NY',
    value: 8160,
  },
  {
    label: 'Tallahassee, FL',
    value: 8240,
  },
  {
    label: 'Tampa-St. Petersburg',
    value: 8280,
  },
  {
    label: 'Terre Haute, IN',
    value: 8320,
  },
  {
    label: 'Texarkana, TX-AR',
    value: 8360,
  },
  {
    label: 'Toledo, OH',
    value: 8400,
  },
  {
    label: 'Topeka, KS',
    value: 8440,
  },
  {
    label: 'Tucson, AZ',
    value: 8520,
  },
  {
    label: 'Tulsa, OK',
    value: 8560,
  },
  {
    label: 'Tuscaloosa, AL',
    value: 8600,
  },
  {
    label: 'Tyler, TX',
    value: 8640,
  },
  {
    label: 'Utica-Rome, NY',
    value: 8680,
  },
  {
    label: 'Victoria, TX',
    value: 8750,
  },
  {
    label: 'Visalia-Tulare-Porterville, CA',
    value: 8780,
  },
  {
    label: 'Waco, TX',
    value: 8800,
  },
  {
    label: 'Washington-Baltimore, DC-MD-VA-WV',
    value: 8872,
  },
  {
    label: 'Waterloo-Cedar Falls, IA',
    value: 8920,
  },
  {
    label: 'Wausau, WI',
    value: 8940,
  },
  {
    label: 'West Palm Beach-Boca Raton,',
    value: 8960,
  },
  {
    label: 'Wheeling, WV-OH',
    value: 9000,
  },
  {
    label: 'Wichita, KS',
    value: 9040,
  },
  {
    label: 'Wichita Falls, TX',
    value: 9080,
  },
  {
    label: 'Williamsport, PA',
    value: 9140,
  },
  {
    label: 'Wilmington, NC',
    value: 9200,
  },
  {
    label: 'Yakima, WA',
    value: 9260,
  },
  {
    label: 'York, PA',
    value: 9280,
  },
  {
    label: 'Youngstown-Warren, OH',
    value: 9320,
  },
  {
    label: 'Yuba City, CA',
    value: 9340,
  },
  {
    label: 'Yuma, AZ',
    value: 9360,
  },
];
