exports = module.exports = [

  {
    title: 'Animals Interests',
    children: [
      {
        label: 'Cats',
        value: 'interest_cat=true',
      },
      {
        label: 'Dogs',
        value: 'interest_dog=true',
      },
      {
        label: 'Equestrian',
        value: 'interest_equestrian=true',
      },
      {
        label: 'Pets - General',
        value: 'interest_combined_pets=true',
      },
    ],
  },
  {
    title: 'Arts & Crafts Interests',
    children: [
      {
        label: 'Arts',
        value: 'interest_arts=true',
      },
      {
        label: 'Crafts',
        value: 'interest_crafts=true',
      },
      {
        label: 'Photography',
        value: 'interest_photography=true',
      },
      {
        label: 'Woodworking',
        value: 'interest_woodworking=true',
      },
    ],
  },
  {
    title: 'Childrens Interests',
    children: [
      {
        label: 'Board Games',
        value: 'interest_board_games=true',
      },
      {
        label: 'Children',
        value: 'interest_children=true',
      },
      {
        label: 'Education',
        value: 'interest_education=true',
      },
      {
        label: 'Grand Children',
        value: 'interest_grandchildren=true',
      },
      {
        label: 'Parenting',
        value: 'interest_parenting=true',
      },
    ],
  },
  {
    title: 'Collectables Interests',
    children: [
      {
        label: 'Art Collectibles',
        value: 'interest_art_collectibles=true',
      },
      {
        label: 'Arts & Antiques',
        value: 'interest_combined_arts_and_antiques=true',
      },
      {
        label: 'Coins',
        value: 'interest_coins=true',
      },
      {
        label: 'Collectibles - General',
        value: 'interest_combined_collector=true',
      },
      {
        label: 'Stamps',
        value: 'interest_stamps=true',
      },
    ],
  },
  {
    title: 'Food & Drink Interests',
    children: [
      {
        label: 'Cooking',
        value: 'interest_combined_cooking=true',
      },
      {
        label: 'Food & Wine',
        value: 'interest_food_wines=true',
      },
      {
        label: 'Gourmet Cooking',
        value: 'interest_gourmet_cooking=true',
      },
      {
        label: 'Natural Foods',
        value: 'interest_natural_foods=true',
      },
    ],
  },
  {
    title: 'Home Improvement Interests',
    children: [
      {
        label: 'Home Furnishings',
        value: 'interest_home_furnishings=true',
      },
      {
        label: 'Home Gardening',
        value: 'interest_home_gardening=true',
      },
      {
        label: 'Home Improvement',
        value: 'interest_home_inprovement=true',
      },
    ],
  },
  {
    title: 'Reading',
    children: [
      {
        label: 'Audio Books',
        value: 'reading_audio_books=true',
      },
      {
        label: 'Current Affairs',
        value: 'reading_current_affairs=true',
      },
      {
        label: 'Financial News',
        value: 'reading_financial=true',
      },
      {
        label: 'History',
        value: 'reading_history=true',
      },
      {
        label: 'Magazines',
        value: 'reading_magazines=true',
      },
      {
        label: 'Reading - General',
        value: 'reading_general=true',
      },
      {
        label: 'Religious',
        value: 'reading_religious=true',
      },
      {
        label: 'Science/Space',
        value: 'reading_space=true',
      },
      {
        label: 'Science Fiction',
        value: 'reading_science_fiction=true',
      },
      {
        label: 'Religious Material',
        value: 'reading_combined_religious=true',
      },
    ],
  },
  {
    title: 'Sports & Entertainment Interests',
    children: [
      {
        label: 'Aerobic',
        value: 'interest_aerobic=true',
      },
      {
        label: 'Baseball',
        value: 'spectator_baseball=true',
      },
      {
        label: 'Basketball',
        value: 'spectator_basketball=true',
      },
      {
        label: 'Camping',
        value: 'interest_camping=true',
      },
      {
        label: 'Fishing',
        value: 'interest_fishing=true',
      },
      {
        label: 'Football',
        value: 'spectator_football=true',
      },
      {
        label: 'Gambling',
        value: 'interest_gambling=true',
      },
      {
        label: 'Golf',
        value: 'interest_golf=true',
      },
      {
        label: 'Hockey',
        value: 'spectator_hockey=true',
      },
      {
        label: 'Hunting',
        value: 'interest_hunting=true',
      },
      {
        label: 'Motorcycling',
        value: 'interest_motorcycling=true',
      },
      {
        label: 'Music',
        value: 'entertain_music=true',
      },
      {
        label: 'Musical Instruments',
        value: 'interest_musical_instruments=true',
      },
      {
        label: 'Nascar',
        value: 'interest_nascar=true',
      },
      {
        label: 'Running',
        value: 'interest_running=true',
      },
      {
        label: 'Sailing',
        value: 'interest_sailling=true',
      },
      {
        label: 'Scuba Diving',
        value: 'interest_scuba_diving=true',
      },
      {
        label: 'Snow Skiing',
        value: 'interest_snow_skiing=true',
      },
      {
        label: 'Soccer',
        value: 'spectator_soccer=true',
      },
      {
        label: 'Sports - General',
        value: 'interest_combined_sports=true',
      },
      {
        label: 'Tennis',
        value: 'interest_tennis=true',
      },
      {
        label: 'Theater',
        value: 'entertain_theater=true',
      },
      {
        label: 'Walking',
        value: 'interest_walking=true',
      },
    ],
  },
  {
    title: 'Travel Interests',
    children: [
      {
        label: 'Cruises',
        value: 'interest_cruise=true',
      },
      {
        label: 'Domestic Travel',
        value: 'interest_domestic_travel=true',
      },
      {
        label: 'International Travel',
        value: 'interest_international=true',
      },
      {
        label: 'Travel - General',
        value: 'interest_combined_travel=true',
      },
    ],
  },
  {
    title: 'Other Interests',
    children: [
      {
        label: 'Automobiles',
        value: 'interest_combined_auto=true',
      },
      {
        label: 'Diet',
        value: 'interest_diet=true',
      },
      {
        label: 'Health & Medical',
        value: 'interest_health_medical=true',
      },
      {
        label: 'Military',
        value: 'interest_military=true',
      },
      {
        label: 'Self Improvement',
        value: 'interest_self_improvement=true',
      },
    ],
  },
];
