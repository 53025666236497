exports = module.exports = [
  {
    label: 'Animal Charities',
    value: 'donor_animal=true',
    omit: false,
  },
  {
    label: 'Arts & Culture',
    value: 'donor_arts=true',
    omit: false,
  },
  {
    label: 'Charitable Donation - General',
    value: 'donor_combined_contribution=true',
    omit: false,
  },
  {
    label: "Children's Charities",
    value: 'donor_children=true',
    omit: false,
  },
  {
    label: 'Community Charities',
    value: 'donor_community=true',
    omit: false,
  },
  {
    label: 'Environmental Charities',
    value: 'donor_environment=true',
    omit: false,
  },
  {
    label: 'Health',
    value: 'donor_health=true',
    omit: false,
  },
  {
    label: 'International Aid',
    value: 'donor_international_aid=true',
    omit: false,
  },
  {
    label: 'Religious Charities',
    value: 'donor_religious=true',
    omit: false,
  },
  {
    label: "Veteran's charities",
    value: 'donor_veteran=true',
    omit: false,
  },
  {
    label: 'Wildlife Charities',
    value: 'donor_wildlife=true',
    omit: true,
  },
];
