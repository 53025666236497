exports = module.exports = [
  {
    title: 'Children',
    children: [
      {
        label: 'Children\'s Products',
        value: 'buyer_children_products=true',
      },
    ],
  },
  {
    title: 'General',
    children: [
      {
        label: 'Audio Books & Music',
        value: 'buyer_audio_books_and_music=true',
      },
      {
        label: 'Automotive',
        value: 'buyer_auto=true',
      },
      {
        label: 'Books & Magazines',
        value: 'buyer_books_and_magazines=true',
      },
      {
        label: 'Gardening Prodcuts',
        value: 'buyer_gardening_products=true',
      },
      {
        label: 'Hunting and Shooting',
        value: 'buyer_hunting_and_shooting=true',
      },
      {
        label: 'Photography',
        value: 'buyer_photography=true',
      },
      {
        label: 'Sports',
        value: 'buyer_sports=true',
      },
    ],
  },
  {
    title: 'Health & Beauty',
    children: [
      {
        label: 'Cosmetics',
        value: 'buyer_cosmetic_products=true',
      },
      {
        label: 'Health & Beauty Products',
        value: 'buyer_health_and_beauty_products=true',
      },
      {
        label: 'Jewelry',
        value: 'buyer_jewerly=true',
      },
    ],
  },
  {
    title: 'Men',
    children: [
      {
        label: 'Big and Tall Men\'s Apparel',
        value: 'buyer_big_and_tall_men_apparel=true',
      },
      {
        label: 'Men\'s Apparel',
        value: 'buyer_men_apparel=true',
      },
    ],
  },
  {
    title: 'Women',
    children: [
      {
        label: 'Petite Woman\'s Apparel',
        value: 'buyer_petite_women_apparel=true',
      },
      {
        label: 'Plus Size Woman\'s Apparel',
        value: 'buyer_plus_women_apparel=true',
      },
      {
        label: 'Woman\'s Apparel',
        value: 'buyer_women_apparel=true',
      },
      {
        label: 'Young Woman\'s Apparel',
        value: 'buyer_young_women_apparel=true',
      },
    ],
  },
];
